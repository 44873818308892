import React from "react";
import { useMediaQuery } from "react-responsive";
import styled, { css } from "styled-components";
import text from "../../../../../font";
import useReponsive from "../../../../../useReponsive";
import { ILengendData } from "../../types";
import { cssUtil } from "./Legend.cssUtil";

interface ILenged {
  legendData: ILengendData;
}

const Legend = ({ legendData }: ILenged): JSX.Element => {
  const isIframe = useReponsive();
  return (
    <LegendWrappperItem isIFrame={isIframe}>
      <Left>
        <LegendColor color={legendData.color} isIframe={isIframe} />
        <LegendItemWrapper>
          <LegendItem isIframe={isIframe}>{legendData.name}</LegendItem>
        </LegendItemWrapper>
      </Left>
      <Right>
        <LegendFigures>
          {legendData.percent}% ({legendData.N}명)
        </LegendFigures>
      </Right>
    </LegendWrappperItem>
  );
};

const LegendWrappperItem = styled.div<{
  isIFrame: boolean;
}>`
  display: flex;
  ${(props) =>
    props.isIFrame
      ? text({
          size: 11,
          color: "gray04",
          weight: "regular",
        })
      : text({
          size: 14,
          color: "gray04",
          weight: "regular",
        })}

  letter-spacing: -0.5px;
  justify-content: space-between;
  align-items: center;
`;

const Left = styled.div`
  display: flex;
  gap: 12px;
`;
const Right = styled.div`
  display: flex;
`;

const LegendColor = styled.div`
  ${cssUtil.getLengendColorCss}
  border-radius: 3px;
`;

const LegendItem = styled.p`
  margin-top: 2px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: ${cssUtil.getLengedItemWidth};
`;

const LegendFigures = styled.p`
  margin-top: 2px;
`;

const LegendItemWrapper = styled.div``;

export default Legend;
