import React from 'react';
import styled, { css } from 'styled-components';
import text from '../../../../font';


interface IHRDashboardTitleProps {
  children: React.ReactNode;
  marginBottom?: number;
  tooltip?: {};
}

const Title = ({ children, marginBottom, tooltip }: IHRDashboardTitleProps) => {
  return (
    <TitleBox marginBottom={marginBottom}>
      <TitleText>{children}</TitleText>
      {tooltip !== undefined && <TitleIcon />}
    </TitleBox>
  );
};

export default Title;

const TitleBox = styled.div<{
  marginBottom: IHRDashboardTitleProps['marginBottom'];
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;

  ${(props) =>
    props.marginBottom &&
    css`
      margin-bottom: ${props.marginBottom}px;
    `}
`;

export const TitleText = styled.h1`
  margin: 0;
  line-height: 100%;
  letter-spacing: -0.5px;
  margin-right: 5px;
  ${text({
    weight: 'bold',
    size: 20,
    color: 'gray05',
  })}
`;

export const TitleIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 0.25C7.26942 0.25 5.57769 0.763179 4.13876 1.72464C2.69983 2.6861 1.57832 4.05267 0.916058 5.65152C0.253791 7.25037 0.080512 9.00971 0.418133 10.707C0.755753 12.4044 1.58911 13.9635 2.81282 15.1872C4.03653 16.4109 5.59563 17.2442 7.29296 17.5819C8.9903 17.9195 10.7496 17.7462 12.3485 17.0839C13.9473 16.4217 15.3139 15.3002 16.2754 13.8612C17.2368 12.4223 17.75 10.7306 17.75 9C17.75 6.67936 16.8281 4.45376 15.1872 2.81282C13.5462 1.17187 11.3206 0.25 9 0.25ZM8.81875 14.2688C8.56944 14.28 8.32247 14.2163 8.10964 14.086C7.89682 13.9556 7.72788 13.7646 7.62459 13.5374C7.5213 13.3102 7.48838 13.0573 7.53007 12.8112C7.57176 12.5651 7.68615 12.3372 7.85852 12.1567C8.03088 11.9762 8.25333 11.8515 8.49721 11.7985C8.74109 11.7455 8.99525 11.7668 9.22695 11.8595C9.45865 11.9522 9.65729 12.1122 9.7973 12.3188C9.9373 12.5254 10.0123 12.7692 10.0125 13.0188C10.0201 13.1802 9.99488 13.3415 9.9383 13.4929C9.88173 13.6443 9.79501 13.7826 9.68339 13.8995C9.57177 14.0163 9.43758 14.1093 9.28896 14.1728C9.14033 14.2363 8.98037 14.2689 8.81875 14.2688ZM9.625 10.8188H7.9875C7.675 8.73125 9.8625 7.9 9.8625 6.65C9.86929 6.50489 9.84497 6.36001 9.79117 6.22507C9.73736 6.09014 9.65533 5.96827 9.55056 5.86764C9.44579 5.76701 9.32071 5.68996 9.18371 5.64164C9.04672 5.59332 8.90097 5.57486 8.75625 5.5875C8.47957 5.60252 8.209 5.6747 7.96159 5.79948C7.71418 5.92426 7.49531 6.09894 7.31875 6.3125L6.26875 5.35625C6.6016 4.95342 7.01856 4.62831 7.49039 4.40374C7.96222 4.17916 8.47748 4.06056 9 4.05625C10.5563 4.05625 11.7 4.8625 11.7 6.525C11.7125 8.375 9.45 9 9.625 10.8188Z"
      fill="#C9C8C7"
    />
  </svg>
);

export const TitleIconSmall = () => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 0.25C7.26942 0.25 5.57769 0.763179 4.13876 1.72464C2.69983 2.6861 1.57832 4.05267 0.916058 5.65152C0.253791 7.25037 0.080512 9.00971 0.418133 10.707C0.755753 12.4044 1.58911 13.9635 2.81282 15.1872C4.03653 16.4109 5.59563 17.2442 7.29296 17.5819C8.9903 17.9195 10.7496 17.7462 12.3485 17.0839C13.9473 16.4217 15.3139 15.3002 16.2754 13.8612C17.2368 12.4223 17.75 10.7306 17.75 9C17.75 6.67936 16.8281 4.45376 15.1872 2.81282C13.5462 1.17187 11.3206 0.25 9 0.25ZM8.81875 14.2688C8.56944 14.28 8.32247 14.2163 8.10964 14.086C7.89682 13.9556 7.72788 13.7646 7.62459 13.5374C7.5213 13.3102 7.48838 13.0573 7.53007 12.8112C7.57176 12.5651 7.68615 12.3372 7.85852 12.1567C8.03088 11.9762 8.25333 11.8515 8.49721 11.7985C8.74109 11.7455 8.99525 11.7668 9.22695 11.8595C9.45865 11.9522 9.65729 12.1122 9.7973 12.3188C9.9373 12.5254 10.0123 12.7692 10.0125 13.0188C10.0201 13.1802 9.99488 13.3415 9.9383 13.4929C9.88173 13.6443 9.79501 13.7826 9.68339 13.8995C9.57177 14.0163 9.43758 14.1093 9.28896 14.1728C9.14033 14.2363 8.98037 14.2689 8.81875 14.2688ZM9.625 10.8188H7.9875C7.675 8.73125 9.8625 7.9 9.8625 6.65C9.86929 6.50489 9.84497 6.36001 9.79117 6.22507C9.73736 6.09014 9.65533 5.96827 9.55056 5.86764C9.44579 5.76701 9.32071 5.68996 9.18371 5.64164C9.04672 5.59332 8.90097 5.57486 8.75625 5.5875C8.47957 5.60252 8.209 5.6747 7.96159 5.79948C7.71418 5.92426 7.49531 6.09894 7.31875 6.3125L6.26875 5.35625C6.6016 4.95342 7.01856 4.62831 7.49039 4.40374C7.96222 4.17916 8.47748 4.06056 9 4.05625C10.5563 4.05625 11.7 4.8625 11.7 6.525C11.7125 8.375 9.45 9 9.625 10.8188Z"
      fill="#C9C8C7"
    />
  </svg>
);

