import React from "react";

import AnswerTable from "../../VCModel/components/ShorttextTable/TableElements/AnswerTable";

import { TrendReportTableData } from "./TrendReportTable.utils";

import { TrendReportTextTable } from "../types/types";

interface Props {
  data: TrendReportTextTable[];
}

const TrendReportTable = ({ data }: Props) => {
  const tableData = new TrendReportTableData(data);

  return (
    <div className="flex flex-col mb-[24px] border-solid border-[#F0F0F0] border-[1px] rounded-[3px]">
      <div className="text-[#2B2E33] text-[18px] leading-[150%] font-[700] px-[32px] py-[16px]">
        {tableData.title.replaceAll("_", " ")}
      </div>
      <AnswerTable dates={tableData.dates} contents={tableData.contents} />
    </div>
  );
};

export default TrendReportTable;
