import React from "react";
import styled from "styled-components";
import Callout from "./Callout/Callout";

import MultiCardChart from "../Graphs/MultiCardChart/MultiCardChart";

import HRDashboardLayout from "../../styles";
import withAsyncError from "../WithAsyncError/WithAsyncError";

import useMultiCardChartData from "../../../EpisodeReport/CardGraph/useMultiCardChartData";
import { IQuickPulseFetchData } from "../../../EpisodeReport/types";
import { useMediaQuery } from "react-responsive";

const QuickPulse = () => {
  const multiCardChartData = useMultiCardChartData() as IQuickPulseFetchData;

  return (
    <HRDashboardLayout.BlockWrapper>
      <HRDashboardLayout.Title>퀵 펄스</HRDashboardLayout.Title>
      <Callout question={multiCardChartData.question} />
      <ChartBoxWrapper>
        {multiCardChartData.data.map((chardData, index) => (
          <MultiCardChart chartData={chardData} key={index + 1} />
        ))}
      </ChartBoxWrapper>
    </HRDashboardLayout.BlockWrapper>
  );
};

export default withAsyncError(QuickPulse);

const ChartBoxWrapper = styled.div`
  display: grid;
  gap: 32px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  margin-top: 32px;
`;
