import { Row } from "react-table";

export interface IFactorPreference {
  rank: number;
  label: string;
  preference: number;
}

export interface IIPAData {
  label: string;
  table: {
    importance: number;
    satisfaction: number;
    isPositive: boolean;
  };
  graph: {
    importance: number;
    satisfaction: number;
  };
}

export const enum factorTagType {
  postive,
  negative,
}

export const enum LabelType {
  "중점 개선 영역",
  "현상 유지 영역",
  "점진적 개선 영역",
  "유지 강화 영역",
}

export type TableDataType = [string, factorTagType, number, number, LabelType];

export interface IPieChart {
  label: string;
  percent: string;
  count: number;
}

export interface IReportData {
  title: string;
  graph: {
    score: number;
    data: IPieChart[];
  };
  table: {
    rank: number;
    label: string;
    preference: number;
  }[];
  IPA: {
    label: string;
    table: {
      importance: number;
      satisfaction: number;
      isPositive: boolean;
    };
    graph: {
      importance: number;
      satisfaction: number;
    };
  }[];
  total: number;
}

export interface IEpisodeReportData {
  engagement: {
    graph: {
      score: number;
      data: { label: string; percent: string; count: number }[];
    };
    table: { rank: number; label: string; preference: number }[];
    IPA: {
      label: string;
      table: {
        importance: number;
        satisfaction: number;
        isPositive: boolean;
      };
      graph: {
        importance: number;
        satisfaction: number;
      };
    }[];
  };
}

export type Combine<T, K> = T & Omit<K, keyof T>;

export interface ILengendData {
  color: string;
  name: string;
  percent: string | null;
  N: number | null;
}

export type ColumnFilterPropertiesType = {
  column: {
    id: string;
    canFilter: boolean;
    setFilter: (filterValue: any) => void;
    filterValue: any;
    preFilteredRows: Row[];
    filteredRows: Row[];
  };
  setfilterIsVisible: any;
};

export interface IQuadrantChartData {
  name: string;
  value: [number, number];
  label: LabelType;
}

export type LabelColor = "#f37165" | "#818282" | "#70c33c" | "#f2ab28";

export interface IQuadrantChartDataWithColor extends IQuadrantChartData {
  itemStyle: {
    color: LabelColor;
  };
}

export interface IQuadrantChartDataWithTooltip
  extends IQuadrantChartDataWithColor {
  tooltip: {
    formatter: tooltipType;
    backgroundColor: "rgba(0,0,0,0)";
    borderColor: "#fff";
    padding: 0;
  };
}

export type 중점개선영역type =
  '<div\nstyle="\n  border: 1px solid #f6e4ef;\n  background-color: #fcf6f4;\n  border-radius: 3px;\n  box-sizing: border-box;\n  padding: 6px 8px;\n  font-size: 12px;\n  letter-spacing: -0.5px;\n  color: #f37165;\n  width: 85px;\n  height: 24px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-family:"Spoqa Han Sans Neo Medium"\n"\n>\n<p style="margin: 0">중점 개선 영역</p>\n</div>';
export type 유지강화영역type =
  '\n<div\nstyle="\n  border: 1px solid #ffdca7;\n  background-color: #fff9f0;\n  border-radius: 3px;\n  box-sizing: border-box;\n  padding: 6px 8px;\n  font-size: 12px;\n  letter-spacing: -0.5px;\n  color: #f2ab28;\n  width: 85px;\n  height: 24px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-family:"Spoqa Han Sans Neo Medium"\n"\n>\n<p style="margin: 0">유지 강화 영역</p>\n</div>\n';
export type 현상유지영역type =
  '\n<div\n      style="\n        border: 1px solid #e1e1e1;\n        background-color: #f4f4f4;\n        border-radius: 3px;\n        box-sizing: border-box;\n        padding: 6px 8px;\n        font-size: 12px;\n        letter-spacing: -0.5px;\n        color: #818282;\n        width: 85px;\n        height: 24px;\n        display: flex;\n        justify-content: center;\n        align-items: center;\n        font-family:"Spoqa Han Sans Neo Medium"\n      "\n    >\n      <p style="margin: 0">현상 유지 영역</p>\n    </div>\n';
export type 점전적개선영역type =
  '\n<div\nstyle="\n  border: 1px solid #c6eb9b;\n  background-color: #f7fced;\n  border-radius: 3px;\n  box-sizing: border-box;\n  padding: 6px 8px;\n  font-size: 12px;\n  letter-spacing: -0.5px;\n  color: #70c33c;\n  width: 95px;\n  height: 24px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-family:"Spoqa Han Sans Neo Medium"\n"\n>\n<p style="margin: 0">중점 개선 영역</p>\n</div>\n';

export type tooltipType =
  | 중점개선영역type
  | 현상유지영역type
  | 점전적개선영역type
  | 유지강화영역type;

export interface IMultiChartCard {
  type: "multi_pie";
  series: IMultiCardChartSeries[];
}

export interface IMultiCardChartSeries {
  type: "card";
  title: string;
  averageScore: number;
  data: IMultiPieChartsData[];
}

export interface IMultiPieChartsData {
  name: string;
  value: number;
  ratio: string;
  N: number;
}
export interface IDataWithColor {
  type: "card";
  title: string;
  averageScore: number;
  data: {
    name: string;
    value: number;
    ratio: string;
    N: number;
    color: string;
  }[];
}

export type QuickPulseDataType = {
  question: string;
  selections: string[][];
  graphs: {
    [key in "전체 점수 분포" | "팀리더 점수 분포" | "팀원 점수 분포"]: {
      avg: number;
      count: number[];
    };
  };
};

export interface IQuickPulseFetchData {
  question: string;
  data: IQuickPulsedata[];
}

export interface IQuickPulsedata {
  title: string;
  averageScore: number;
  legendData: ILegendData[];
}

export interface ILegendData {
  percent: number;
  N: number;
  name: string;
}
