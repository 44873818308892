import styled from "styled-components";

import KeyWordTable from "../KeyWordTable";
import TableStyle from "../TableStyle";

const KeywordNetwork = () => {
  return (
    <Container>
      <TableStyle.Title>평가 요소 키워드 네트워크</TableStyle.Title>
      <KeyWordTable />
    </Container>
  );
};

export default KeywordNetwork;

const Container = styled.div`
  margin-top: 24px;
  padding: 32px;
  background-color: #ffffff;
  border: 1px solid #f0f0f0;
  border-radius: 3px;
`;
