import React, { useState, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useResizeDetector } from 'react-resize-detector';
import Ellipsis from 'react-ellipsis-component';

interface ICellTextProps {
  children: React.ReactNode;
}

const CellText = ({ children }: ICellTextProps): JSX.Element => {
  const [isEllipsis, setIsEllipsis] = useState<boolean>(true);

  if (isReactNodeCell(children)) {
    const text = children.props.value;
    return (
      <CellTextWrapper>
        <Ellipsis
          text={text}
          ellipsis={isEllipsis}
          maxLine={isEllipsis ? 4 : 9999}
          ellipsisNode={
            <MoreText
              onClick={() => {
                setIsEllipsis((v) => !v);
              }}
            >
              ...더보기
            </MoreText>
          }
        />
        {isEllipsis === false && (
          <MoreText
            style={{
              display: 'inline',
            }}
            onClick={() => {
              setIsEllipsis((v) => !v);
            }}
          >
            접기
          </MoreText>
        )}
      </CellTextWrapper>
    );
  }

  return <>{children}</>;
};

const MoreText = styled.span`
  color: #f2ab28;
  display: inline;
  cursor: pointer;
  margin-left: 3px;
`;

const CellTextWrapper = styled.div`
  .__react-ellipsis {
    display: inline;
  }
  .__react-ellipsis-js {
    display: inline;
  }
`;

export default CellText;

const isReactNodeCell = (
  v: React.ReactNode,
): v is {
  props: {
    value: string;
  };
} => {
  try {
    //@ts-ignore
    const value = v.props.value;
    return typeof value === 'string';
  } catch (err) {
    return false;
  }
};
