import React from "react";
import styled from "styled-components";
import Header from "../../../Components/Header";
import Column from "../../../Components/Table/Column";
import Rows from "../../../Components/Table/Rows";

const OrganizationDiagnosisReport = () => {
  const column = ["보고서 제목"];
  const rows = [
    ["조직 응집 정도 보고서", "보고서 확인"],
    ["Myers-Briggs Type Indicator 보고서", "보고서 확인"],
    ["업무 몰입도 평가 3분기", "보고서 확인"],
    ["업무 몰입도 평가 4분기", "보고서 확인"],
    ["업무 몰입도 평가 종합", "보고서 확인"],
  ];
  return (
    <OrganizationDiagnosisRepornWrapper>
      <Header title="얼리슬로스의 조직 진단 보고서" />
      <div>
        {/* <Column column={column} type="oneLine" /> */}
        <Rows rows={rows} type="twoColumn" />
      </div>
    </OrganizationDiagnosisRepornWrapper>
  );
};

const OrganizationDiagnosisRepornWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px 32px 64px 32px;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.gray01};
  border-radius: 3px;
`;

export default OrganizationDiagnosisReport;
