export const QUERY_KEYS = {
  trendReportSheetList: "GET/TrendReportSheetList",
  trendReportData: "GET/TrendReportData",
  trendReportSuggestion: "GET/TrendReportSuggestion",
} as const;

export const CHART_TYPES = {
  line: "line",
  lineSmooth: "line_smooth",
  bar: "bar",
  area: "area",
  areaSmooth: "area_smooth",
} as const;
