import EChartsReact from "echarts-for-react";
import React, { useState } from "react";
import styled, { css } from "styled-components";
import { option, SUBTITLE_INFO } from "./RatingCompare.contants";

const RatingCompare = () => {
  const [selectValue, setSelectValue] = useState<string>(option.series[0].name);

  return (
    <RatingCompareWrapper>
      <TitleWrapper>
        <Title>경쟁사 평점 비교</Title>
      </TitleWrapper>
      <SelectWrapper>
        {option.series
          .map((item) => item.name)
          .map((value) => (
            <Selector
              key={value}
              isSelect={value === selectValue}
              onClick={() => setSelectValue(value)}
            >
              <p>{value}</p>
            </Selector>
          ))}
      </SelectWrapper>
      <GraphTitleWrapper>
        <GraphTitle>{selectValue}</GraphTitle>
        <GraphSubTitleWrapper>
          <SubTitleInfo>최근 4주 평점</SubTitleInfo>
          <SubTitleValue>{SUBTITLE_INFO.get(selectValue)}/100.0</SubTitleValue>
        </GraphSubTitleWrapper>
      </GraphTitleWrapper>
      <ChartBox>
        <EChartsReact
          option={option}
          style={{ width: "100%", height: "100%" }}
          notMerge={true}
        />
      </ChartBox>
    </RatingCompareWrapper>
  );
};

export default RatingCompare;

const RatingCompareWrapper = styled.div`
  width: 1200px;
  padding: 32px;
  border: 1px solid #f0f0f0;
  background-color: #ffffff;
`;

const TitleWrapper = styled.div`
  margin-bottom: 24px;
`;
const Title = styled.p`
  font-family: "Spoqa Han Sans Neo Medium";
  font-size: 18px;
  line-height: 150%;
  letter-spacing: -0.5px;
`;
const SelectWrapper = styled.div`
  padding: 20px 24px;
  display: flex;
  gap: 16px;
  background-color: #fbfaf8;
  margin-bottom: 40px;
  border-radius: 6px;
`;
const Selector = styled.div<{ isSelect: boolean }>`
  padding: 9px 16px;
  border: 1px solid #dfdedd;
  font-family: "Spoqa Han Sans Neo Medium";
  font-size: 14px;
  line-height: 100%;
  letter-spacing: -0.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  cursor: pointer;

  ${({ isSelect }) =>
    isSelect
      ? css`
          background-color: #f2ab28;
          color: #fff;
        `
      : css`
          background-color: #fff;
          color: #2b2e33;
        `}
`;
const GraphTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
  margin-bottom: 20px;
`;
const GraphTitle = styled.p`
  font-family: "Spoqa Han Sans Neo Bold";
  font-size: 20px;
  line-height: 150%;
  letter-spacing: -0.5px;
`;
const GraphSubTitleWrapper = styled.p`
  display: flex;
  align-items: center;
  gap: 8px;
`;
const SubTitleInfo = styled.p`
  font-family: "Spoqa Han Sans Neo Medium";
  font-size: 16px;
  line-height: 100%;
  letter-spacing: -0.5px;
  color: #818282;
`;
const SubTitleValue = styled.p`
  font-family: "Spoqa Han Sans Neo Bold";
  font-size: 20px;
  line-height: 150%;
  letter-spacing: -0.5px;
`;

const ChartBox = styled.div`
  height: 340px;
`;
