
import React from 'react';
import styled from 'styled-components';
import text from '../../../../font';

import { LABEL_TAG_NAME } from '../../../constants';
import { LabelType } from '../../../types';
import { cssUtil } from './util';

interface ILabelTag {
  label: LabelType;
}

const LabelTag = ({ label }: ILabelTag): JSX.Element => {
  return (
    <LabelTagWrapper label={label}>
      <LabelName>{LABEL_TAG_NAME.get(label)}</LabelName>
    </LabelTagWrapper>
  );
};

export default LabelTag;

const LabelTagWrapper = styled.div<{ label: LabelType }>`
  border: 1px solid ${cssUtil.getBorderColor};
  background-color: ${cssUtil.getBackgroundColor};
  border-radius: 3px;
  box-sizing: border-box;
  padding: 6px 8px;
  font-size: 12px;
  letter-spacing: -0.5px;
  color: ${cssUtil.getTagColor};

  width: ${cssUtil.getTagWidth};

  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${text({
    size: 12,
    weight: 'regular',
  })}
`;

const LabelName = styled.p``;
