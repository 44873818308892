import { css } from "styled-components";
import { ColorType } from "../../../../../EpisodeReport/EpisodeReportChartOptions/ChartUtil";


export const cssUtil = {
  getLengedItemWidth: ({ isIframe }: { isIframe: boolean }) =>
    isIframe ? "80px" : "none",
  getLengendColorCss: ({
    isIframe,
    color,
  }: {
    isIframe: boolean;
    color: ColorType;
  }) =>
    isIframe
      ? css`
          width: 20px;
          height: 20px;
          background-color: ${color};
        `
      : css`
          width: 24px;
          height: 24px;
          background-color: ${color};
        `,
};
