import { z } from "zod";

import { CHART_TYPES } from "./constants";

export const TrendReportSheetListSchema = z.array(z.array(z.string()));

export const TrendReportInnerDataSchema = z.record(
  z.string(),
  z.union([
    z.object({
      label: z.string(),
      type: z.literal("mixed"),
      data: z.record(
        z.string(),
        z.object({
          value: z.string(),
          chart: z.union([
            z.literal(CHART_TYPES.line),
            z.literal(CHART_TYPES.lineSmooth),
            z.literal(CHART_TYPES.bar),
            z.literal(CHART_TYPES.area),
            z.literal(CHART_TYPES.areaSmooth),
          ]),
        })
      ),
    }),
    z.object({
      label: z.string(),
      type: z.literal("text"),
      data: z.string(),
    }),
  ])
);

export const TrendReportDataSchema = z.array(
  z.array(
    z.object({
      id: z.string(),
      color: z.array(z.string()),
      date: z.string(),
      data: TrendReportInnerDataSchema,
    })
  )
);

export const TrendReportSuggestionsSchema = z.array(
  z.object({
    id: z.string(),
    label: z.string(),
    content: z.string(),
    position: z.string(),
  })
);
