import React from 'react';
import Title from './Title';
import BlockWrapper from './blockWrapper';
import styled, { FlattenSimpleInterpolation, css } from 'styled-components';
import text from '../../../../font';


const Divider = styled.hr<{
  isOverflow?: boolean;
  css?: FlattenSimpleInterpolation;
}>`
  width: 100%;
  height: 1px;
  background: rgb(240, 240, 240);
  outline: none;
  border: none;
  margin-top: 16px;
  margin-bottom: 24px;

  ${(props) =>
    props.isOverflow &&
    css`
      margin-left: -30px;
      margin-right: 0px;
      width: calc(100% + 60px);
    `}

  ${(props) => props.css}
`;

const MiddleTitle = styled.p<{ css?: FlattenSimpleInterpolation }>`
  ${text({
    weight: 'medium',
    size: 16,
    color: 'gray05',
  })}
  letter-spacing: -0.5px;
  ${(props) => props.css}
`;

const HRDashboardLayout = {
  Title,
  BlockWrapper,
  Divider,
  MiddleTitle,
};

export default HRDashboardLayout;
