import React from 'react';
import styled from 'styled-components';
import { IFactorPreference } from '../../types';

import { HEADER_VALUES } from './constants';
import Header from './Header/Header';
import Rows from './Rows/Rows';

interface ITableProps {
  tableData: IFactorPreference[];
}

const Table = ({ tableData }: ITableProps): JSX.Element => {
  return (
    <TableWrapper>
      <Header header={HEADER_VALUES} />
      <Rows rowsData={tableData} />
    </TableWrapper>
  );
};

export default Table;

const TableWrapper = styled.div``;
