import create from "zustand";
import { combine } from "zustand/middleware";
import { QuestionType, TableTooltipData } from "./useTooltipData";

interface ITableTooltipState {
  rawData: TableTooltipData | null;
  position: { x: number; y: number } | null;
  selected: (number | string)[][];
  row: string;
}

const initState: ITableTooltipState = {
  rawData: null,
  position: null,
  selected: [],
  row: "",
};

const useTableTooltip = create(
  combine(initState, (set, get) => ({
    initRawData: (payload: TableTooltipData) => {
      set((state) => {
        state.rawData = payload;
      });
    },
    resetPosition: () => {
      set((state) => {
        state.position = null;
      });
    },
    handleHoverTableCell: (
      position: { x: number; y: number },
      index: number,
      _row: string,
      filterInfo: {
        parent: string;
        child: string;
      } | null,
      id?: string
    ) => {
      const { rawData } = get();
      if (!id || !rawData) return;
      const filterID = id as "1" | "2" | "3";
      const row = _row as QuestionType;
      const selectedFilter = filterInfo?.child ?? "전체";
      //@ts-ignore
      const selected = rawData[filterID][selectedFilter][row] as [
        number[],
        string[]
      ];

      set((state) => {
        state.position = position;
        state.selected = selected;
        state.row = row;
      });
    },
  }))
);

export default useTableTooltip;
