import zip from "lodash/zip";

import {
  ReorderedTrendReportDataItem,
  TrendReportDataItemType,
  TrendReportDataType,
  TrendReportMixedChart,
  TrendReportSuggestion,
  TrendReportTextTable,
  UnArray,
} from "../types/types";
import interleaveSuggestions from "./interleaveSuggestions";

const insertDate = (data: TrendReportDataItemType) => {
  return {
    ...data,
    data: Object.entries(data.data).reduce((acc, [key, value]) => {
      return { ...acc, [key]: { ...value, date: data.date } };
    }, {}),
  };
};

export const reorderDataByChart = (
  reportData: UnArray<TrendReportDataType>,
  suggestionData: TrendReportSuggestion[]
): ReorderedTrendReportDataItem => {
  const dateAddedData = reportData
    .map((item) => {
      return insertDate(item);
    })
    .map((item) =>
      Object.entries(item.data)
        .sort((a, b) => {
          if (a[0] > b[0]) {
            return 1;
          }
          if (a[0] < b[0]) {
            return -1;
          }
          return 0;
        })
        .map((item) => item[1])
    );

  const reorderedData = zip(...dateAddedData) as (
    | TrendReportMixedChart[]
    | TrendReportTextTable[]
  )[];

  const finalResult = interleaveSuggestions(reorderedData, suggestionData);

  return {
    id: reportData[0].id,
    colors: reportData[0].color,
    reorderedData: finalResult,
  };
};
