import React from "react";
import styled from "styled-components";
import ReportTitle from "../../Components/ReportTitle";
import DianosisGrouph from "./DiagnosisGraph";
import FinishReport from "./FinishReport";
import PropensityDiagnosis from "./PropensityDiagnosis";

const PersonalReport = () => {
  return (
    <Container>
      <DiagnosisReportWrapper>
        <ReportTitle
          firstTitle="개인 보고서"
          secondTitle="나무늘보"
          thirdTitle="소속:개발A팀"
        />
        <DianosisGrouph />
        <FinishReport />
        <PropensityDiagnosis />
      </DiagnosisReportWrapper>
    </Container>
  );
};

const Container = styled.div`
  ${({ theme }) => theme.flexSets.flexColumn}
  align-items: center;
  width: 100vw;
  padding-top: 92px;
  padding-bottom: 92px;
`;

const DiagnosisReportWrapper = styled.div`
  width: 720px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export default PersonalReport;
