import { Icon } from '@earlysloth/pocketsurvey-ui-components';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { cssUtil } from './util';

interface IRows {
  header: string[];
}

const Header = ({ header }: IRows) => {

  const isIframe = useMediaQuery({
    query: "(max-width:1100px)",
  });
  return (
    <RowsWrapper>
      {header.map((cell) => (
        <RowsCell cell={cell} key={cell} isIframe={isIframe}>
          <RowsValueWrapper >
            <RowsValue>{cell}</RowsValue>
            <Icon
              className="down-arrow"
              color="#818282"
              icon="sort"
              width={12}
              useCursor
            />
          </RowsValueWrapper>
          <Icon
            icon="moreDetail"
            width={12}
            color="#818282"
            rotate={90}
            useCursor
          />
        </RowsCell>
      ))}
    </RowsWrapper>
  );
};

export default Header;

const RowsWrapper = styled.div`
  display: flex;
`;

const RowsCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: ${cssUtil.getCellWidth};
  height: 44px;
  background-color: #f9fafb;
  padding: 16px;
  box-sizing: border-box;
  font-size: 12px;
`;

const RowsValueWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
const RowsValue = styled.p``;
