import React, { useState } from "react";
import styled from "styled-components";
import { zip, cloneDeep } from "lodash";

import AnswerTD from "./AnswerTD";
import { useWindowSize } from "react-use";

interface IAnswerTableProps {
  dates: string[];
  contents: string[];
}

const AnswerTable = ({ dates, contents }: IAnswerTableProps) => {
  const [isReverse, setIsReverse] = useState<boolean>(false);

  const { width } = useWindowSize();

  const data = zip(dates, contents);

  return (
    <Table className="w-full mb-[37px]">
      <thead>
        <tr className="bg-[#F8F8F8] text-[12px] font-[500] leading-[100%]">
          <th
            className="flex items-center gap-[7px] cursor-pointer h-[40px]  px-[32px] py-0"
            onClick={() => {
              setIsReverse((v) => !v);
            }}
          >
            날짜
            <SortICON />
          </th>
          <th className="h-[40px] p-[0] pl-[16px] text-left">답변내용</th>
        </tr>
      </thead>

      {contents.length === 0 && (
        <tbody>
          <tr>
            <AnswerTD className="text-gray-400">
              데이터가 비어있습니다.
            </AnswerTD>
          </tr>
        </tbody>
      )}

      <tbody>
        {(isReverse ? cloneDeep(data).reverse() : data).map(
          ([date, content], index) => {
            const isOdd = index % 2 === 1;

            return (
              <tr
                key={index}
                className={`${
                  isOdd ? "bg-[#F9FAFB]" : ""
                } text-[14px] leading-[120%] border-solid border-[#F0F0F0] border-t-[1px] `}
              >
                <td
                  className="w-[162px] py-[16px] pl-[32px] font-[500] h-[50px]"
                  style={{ width: width > 500 ? 162 : 130 }}
                >
                  {date}
                </td>
                <td className="font-[400] p-[16px] min-h-[50px]">{content}</td>
              </tr>
            );
          }
        )}
      </tbody>
    </Table>
  );
};

export default AnswerTable;

const Table = styled.table`
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
`;

const SortICON = (props: React.ComponentPropsWithoutRef<"svg">) => (
  <svg
    width="6"
    height="12"
    viewBox="0 0 6 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.666618 4.36663H5.33328C5.58995 4.36663 5.79995 4.15663 5.79995 3.89997C5.79995 3.78797 5.75328 3.6853 5.68795 3.60597L5.69262 3.6013L3.35928 0.801301L3.35462 0.805967C3.26595 0.703301 3.14462 0.633301 2.99995 0.633301C2.85528 0.633301 2.73395 0.703301 2.64528 0.805967L2.64062 0.801301L0.307285 3.6013L0.311951 3.60597C0.246618 3.6853 0.199951 3.78797 0.199951 3.89997C0.199951 4.15663 0.409951 4.36663 0.666618 4.36663ZM5.33328 7.6333H0.666618C0.409951 7.6333 0.199951 7.8433 0.199951 8.09997C0.199951 8.21197 0.246618 8.31463 0.311951 8.39397L0.307285 8.39863L2.64062 11.1986L2.64528 11.194C2.73395 11.2966 2.85528 11.3666 2.99995 11.3666C3.14462 11.3666 3.26595 11.2966 3.35462 11.194L3.35928 11.1986L5.69262 8.39863L5.68795 8.39397C5.75328 8.31463 5.79995 8.21197 5.79995 8.09997C5.79995 7.8433 5.58995 7.6333 5.33328 7.6333Z"
      fill="#818282"
    />
  </svg>
);
