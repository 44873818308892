import { factorTagType } from "../../../types";


const name = new Map([
  [factorTagType.postive, '긍정 영향'],
  [factorTagType.negative, '부정 영향'],
]);

const fontColor = new Map([
  [factorTagType.postive, '#57ADF2'],
  [factorTagType.negative, '#f37165'],
]);

const borderColor = new Map([
  [factorTagType.postive, '#dfe9f6'],
  [factorTagType.negative, '#f6e4df'],
]);

const backgroundColor = new Map([
  [factorTagType.postive, '#f4f8fc'],
  [factorTagType.negative, '#fcf6f4'],
]);

export const FACTOR_TAG = {
  name,
  fontColor,
  borderColor,
  backgroundColor,
};
