import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import DashBoard from "./pages/DashBoard";

import DiagnosisReport from "./pages/DiagnosisReport";
import ReportRouter from "./pages/EpisodeReport/ReportRouter";
import HRDashBoard from "./pages/HRDashboard/HRDashBoard";
import PersonalReport from "./pages/PersonalReport";
import Summary from "./pages/Summary";
import Trend from "./pages/Trend";
import VCModel from "./pages/VCModel/VCModel";
import TrendReport from "./pages/TrendReport/TrendReport";

import ShillaReport from "./pages/ShillaReport/ShillaReport";

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<DiagnosisReport />} />
        <Route path="/personal" element={<PersonalReport />} />
        <Route path="/dashBoard" element={<DashBoard />} />
        <Route path="/summary" element={<Summary />} />
        <Route path="/episode" element={<Trend />} />
        <Route
          path="/episodeReport/:projectID/:ID"
          element={<ReportRouter />}
        />
        <Route path="HRDashboard" element={<HRDashBoard />} />
        <Route path="/vc/:company" element={<VCModel />} />
        <Route
          path="/trendreport/:clientLabel/:companyLabel"
          element={<TrendReport />}
        />
        <Route path="report-sample/*" element={<ShillaReport />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
