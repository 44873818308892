import React, { useEffect, useRef } from "react";
import ReactECharts from "echarts-for-react";
import { useWindowSize } from "react-use";

import { TrendReportMixedChart } from "../types/types";
import { TrendReportChartData } from "./TrendReportChart.utils";

interface Props {
  chartData: TrendReportMixedChart[];
  colors: string[];
}

const TrendReportChart = ({ chartData: _chartData, colors }: Props) => {
  const chartData = new TrendReportChartData(_chartData, colors);

  const echartRef = useRef<ReactECharts>(null);

  const { width } = useWindowSize();

  useEffect(() => {
    if (echartRef.current === null) return;

    echartRef.current.getEchartsInstance().resize();
    echartRef.current.getEchartsInstance().setOption({
      dataZoom: {
        // @ts-ignore
        maxValueSpan: getMaxValueSpan(width),
        startValue: Infinity,
      },
      yAxis: {
        axisLabel: {
          show: width > 400,
        },
      },
    });
  }, [width]);

  return (
    <div
      className="px-[32px] py-[22px] mb-[24px] rounded-[3px] border-[#F0F0F0] border-solid border-[1px]"
      style={{
        width: width > 1200 ? 1200 : width,
      }}
    >
      <div className="text-[18px] leading-[150%] font-[700] mb-[23px]">
        {chartData.title.replaceAll("_", " ")}
      </div>
      <div>
        <ReactECharts
          ref={echartRef}
          option={chartData.option}
          style={{
            height: 400,
          }}
        />
      </div>
    </div>
  );
};

export default TrendReportChart;

const getMaxValueSpan = (width: number): number => {
  if (width > 1200) return 11;
  if (width < 400) return 2;
  return Math.floor(width / 100);
};
