import { useQuery } from "react-query";
import axios from "axios";
import { EpisodeReportType } from "./useRawData";
import useEpisodeReportData from "./useEpisodeReportData";

const useTotalData = (id: number) => {
  const { data } = useQuery(["hr", "total", id], () => getTotalData(id), {
    suspense: true,
    staleTime: 1000 * 60 * 60 * 24, //한번만 데이터를 받아옴
    onSuccess: (res) => {
      useEpisodeReportData.getState().setTotalRawData(res.data);
    },
  });

  return data?.data;
};

export default useTotalData;

const getTotalData = (id: number) =>
  axios.get<EpisodeReportType>(
    `https://s3.ap-northeast-2.amazonaws.com/pocketsurvey.demo.pulsesurvey-report-viewer/2a97516c/${id}_total_result.json`
  );
