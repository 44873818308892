import axios from "axios";

import convertCSVToJson from "../utils/convertCSVToJson";
import {
  TrendReportDataSchema,
  TrendReportSheetListSchema,
  TrendReportSuggestionsSchema,
} from "../utils/trendReportDataSchema";

import { TrendReportDataType, TrendReportSuggestion } from "../types/types";
import Papa from "papaparse";

const trendReportServices = {
  getTrendReportSheetList: async (): Promise<string[][]> => {
    const { data: csvData } = await axios.get(
      "https://docs.google.com/spreadsheets/d/1ylvfrmlnEiGMW6KRbj3TkQUTUICdr8uI4Reuruddmc4/export?format=csv"
    );
    const jsonData = await convertCSVToJson(csvData);
    return TrendReportSheetListSchema.parse(jsonData);
  },
  getTrendReportData: async (url?: string): Promise<TrendReportDataType> => {
    if (!url) throw new Error("url 이 없습니다.");

    const { data: csvData } = await axios.get(url);
    const jsonData = (((await convertCSVToJson(csvData)) as any[]) ?? []).slice(
      1
    );

    const parseResult = TrendReportDataSchema.safeParse(jsonData);
    if (parseResult.success) return parseResult.data;

    console.error(parseResult.error);
    throw new Error(`데이터 형식이 올바르지 않음`);
  },
  getTrendReportSuggestions: async (
    url: string,
    companyLabel: string
  ): Promise<TrendReportSuggestion[]> => {
    const res = await axios.get<string>(url);

    const data = await new Promise((resolve, reject) => {
      Papa.parse(res.data, {
        encoding: "euc-kr",
        complete: (res) => {
          const header = res.data[0] as string[];

          const data = (res.data.slice(1) as string[][]).map((item) => {
            return header.reduce(
              (acc: { [key: string]: string }, curr, index) => {
                acc[header[index]] = item[index];
                return acc;
              },
              {}
            );
          });

          resolve(data);
        },
        error: () => {
          reject();
        },
      });
    });

    const parseResult = TrendReportSuggestionsSchema.safeParse(data);
    if (parseResult.success) return parseResult.data;

    throw new Error(`데이터 형식이 올바르지 않음`);
  },
};

export default trendReportServices;
