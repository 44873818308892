
import React from 'react';
import styled from 'styled-components';
import text from '../../font';

interface IHeaderProps {
  title: string;
}

const Header = ({ title }: IHeaderProps): JSX.Element => {
  return (
    <HeaderWrapper>
      <HeaderTitle>조직원 {title}</HeaderTitle>
    </HeaderWrapper>
  );
};

export default Header;

const HeaderWrapper = styled.div`
  ${text({
    size: 16,
    color: 'gray05',
    weight: 'bold',
  })}
  letter-spacing: -0.5px;
  margin-bottom: 24px;
  page-break-before: always;

  @media print {
    margin-top: 15px;
  }
`;

const HeaderTitle = styled.p``;
