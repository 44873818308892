import React from "react";
import styled, { css } from "styled-components";

interface ITagName {
  name: string;
}

const Tag = ({ name }: ITagName) => {
  return <TagWrapper name={name}>{name}</TagWrapper>;
};

export default Tag;

export const TagWrapper = styled.div<{ name: string }>`
  font-family: "Spoqa Han Sans Neo";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  /* identical to box height, or 12px */

  display: flex;
  align-items: center;
  letter-spacing: -0.5px;
  background-color: #f4f8fc;
  color: #57adf2;
  border: 1px solid #57adf2;

  /* Bold-Blue */

  /* "중점 개선 영역"|"유지 강화 영역"|"점진적 개선 영역"|"현상 유지 영역" */
  ${(props) =>
    props.name === "중점 개선 영역"
      ? css`
          color: #f37165;
          background-color: rgba(243, 113, 101, 0.2);
          border: 1px solid #f37165;
        `
      : css``}
  ${(props) =>
    props.name === "유지 강화 영역"
      ? css`
          color: rgba(112, 212, 115, 1);
          background-color: rgba(112, 212, 115, 0.2);
          border: 1px solid rgba(112, 212, 115, 1);
        `
      : css``}

${(props) =>
    props.name === "점진적 개선 영역"
      ? css`
          color: rgba(242, 171, 40, 1);
          background-color: rgba(242, 171, 40, 0.2);
          border: 1px solid rgba(242, 171, 40, 1);
        `
      : css``}

${(props) =>
    props.name === "현상 유지 영역"
      ? css`
          color: rgba(129, 130, 130, 1);
          background-color: rgba(129, 130, 130, 0.2);
          border: 1px solid rgba(129, 130, 130, 1);
        `
      : css``}

  box-sizing: border-box;
  padding: 6px 8px;
  border-radius: 3px;
`;
