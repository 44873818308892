import React, { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

interface IWithAsyncError {
  children: React.ReactNode;
  FallbackUI?: JSX.Element;
}

const ErrorFallback = ({
  error,
  resetErrorBoundary,
}: {
  error: Error;
  resetErrorBoundary: () => void;
}) => {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
};

const DEFAULT_SUSPENSE_FALLBACK = <p>로딩중...</p>;

const AsyncError = ({
  children,
  FallbackUI = DEFAULT_SUSPENSE_FALLBACK,
}: IWithAsyncError): JSX.Element => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Suspense fallback={FallbackUI}>{children}</Suspense>
    </ErrorBoundary>
  );
};

const withAsyncError = <T extends object>(
  Component: React.ComponentType<T>,
  FallbackUI?: JSX.Element,
) => (props: T) => (
  <AsyncError FallbackUI={FallbackUI}>
    <Component {...props} />
  </AsyncError>
);

export default withAsyncError;
