import React from "react";
import styled from "styled-components";
import Header from "../../../Components/Header";
import PersonalLineChart from "../../../Components/PersonalLineChart";

const DianosisGrouph = () => {
  return (
    <DianosisGrouphWrapper>
      <Header
        title="나무늘보의 행복 트렌드"
        subTitle="조직과 구성원의 행복도 추이를 함께 살펴본 후 개선 방향을 도출해보세요."
      />
      <ChartWrapper>
        <PersonalLineChart />
      </ChartWrapper>
    </DianosisGrouphWrapper>
  );
};

export default DianosisGrouph;

const DianosisGrouphWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.gray01};
  border-radius: 3px;
`;

const ChartWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
