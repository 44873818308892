import { Button2, Icon } from "@earlysloth/pocketsurvey-ui-components";
import React from "react";
import { useParams } from "react-router-dom";
import styled, {
  createGlobalStyle,
  css,
  FlattenSimpleInterpolation,
} from "styled-components";
import text from "../font";
import ComponentLoading from "./components/ComponentLoading";
import EmptyPage from "./components/EmptyPage";
import FilterDropdown from "./components/FilterDropdown";
import RectTooltip from "./components/RectTooltip";
import withAsyncError from "./components/WithAsyncError/WithAsyncError";
import { cssUtil } from "./EposodeReport.cssUtil";
import { useReportData } from "./hooks/useEpisodeReportData";
import useRawData from "./hooks/useRawData";
import useTotalData from "./hooks/useTotalData";
import TableTooltip from "./IPA/Table/Tooltip/TableTooltip";
import ResultReport from "./ResultReport";
import ShorttextAnswer from "./ShorttextAnswer/ShorttextAnswer";
import useResponsive from "../../pages/useReponsive";

interface IEpisodeReportProps {
  id: number;
  projectID: number;
}

const EpisodeReport = ({ id, projectID }: IEpisodeReportProps) => {
  const rawData = useRawData(id);
  const totalData = useTotalData(id);

  const { data: reportData, filterInfo } = useReportData();
  const isIframe = useResponsive();
  return (
    <>
      <Global />
      <RectTooltip />
      <ReportWrapper>
        <TableTooltip />
        <EpisodeReportLayout.Wrapper isIframe={isIframe}>
          <PrintDisplayNone>
            <EpisodeReportLayout.Title>
              {id}회차 보고서
            </EpisodeReportLayout.Title>
            <Header>
              <FilterDropdown rawData={rawData} filterInfo={filterInfo} />
            </Header>
            <EpisodeReportLayout.Divider
              css={css`
                margin-top: 70px;
              `}
            />
          </PrintDisplayNone>

          {reportData === null ? (
            <EmptyPage />
          ) : (
            <ResultReport reportData={reportData} />
          )}
        </EpisodeReportLayout.Wrapper>

        {reportData === null ? null : <ShorttextAnswer />}
      </ReportWrapper>
    </>
  );
};

const Header = styled.div`
  position: absolute;
  display: flex;
  width: 1200px;
  justify-content: space-between;
  align-content: center;
  z-index: 100000000000000000;
`;

const ReportWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
`;

export default withAsyncError(
  EpisodeReport,
  <ComponentLoading width="100%" height="920px" backgroundColor="#ffffff" />
);

export const PrintSection = styled.div`
  @media print {
    @page {
      size: A4 portrait;
      break-after: always;
    }
    break-after: always;
  }
`;

const Global = createGlobalStyle` 
  @media print {
  .dashBoard2 {
      display: initial;
      justify-content: initial;
      width: initial;
      min-width: initial;
      min-height: initial;
      background-color: initial;
    }

    .scrolltop-image {
      display:none;
    }

    text-align: initial;
  }
`;

export const PrintDisplayNone = styled.div`
  @media print {
    display: none;
  }
`;

const EpisodeReportLayout = {
  Wrapper: styled.div`
    width: 1200px;
    padding: ${cssUtil.getWrapperPadding};
    min-height: 100vh;
    background-color: #ffffff;
    margin-top: 60px;

    @media print {
      margin-top: 0px;
    }
  `,
  Title: styled.h1`
    ${text({
      weight: "bold",
      size: 24,
      color: "gray05",
    })}

    margin-bottom: 25px;
  `,
  Divider: styled.hr<{
    isOverflow?: boolean;
    css?: FlattenSimpleInterpolation;
  }>`
    background-color: #f0f0f0;
    width: 100%;
    height: 1px;
    border: none;

    ${(props) =>
      props.css &&
      css`
        ${props.css}
      `}
  `,
};
