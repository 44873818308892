import { EChartsOption } from "echarts";
import { IQuadrantChartData } from "../../types";

import QuadrantChartUtil from "./QuadrantChartUtil";

class QuadrantChartOption extends QuadrantChartUtil {
  constructor(protected readonly graphData: IQuadrantChartData[]) {
    super(graphData);
  }
  private readonly options: EChartsOption = {};
  getOption(isIframe = false): EChartsOption {
    this.options.grid = this.getGrid(isIframe);
    this.options.xAxis = this.getXAxis();
    this.options.yAxis = this.getYAxis();
    this.options.axisLine = this.getAxisLine();
    this.options.tooltip = this.getTooltip();
    this.options.series = this.getSeries();
    this.options.legend = this.getLegend();

    return {
      ...this.options,
    };
  }

  private getGrid(isIframe = false): EChartsOption["grid"] {
    return {
      show: true,
      top: "8%",
      width: isIframe ? 880 : 1088,
      left: isIframe ? "0.15%" : "2%",
      borderColor: "#f0f0f0",
    };
  }

  private getXAxis(): EChartsOption["xAxis"] {
    return {
      type: "value",

      nameTextStyle: {
        color: "#818282",
        fontFamily: "Spoqa Han Sans Neo Medium",
        fontSize: 12,
      },
      nameGap: 8,
      axisTick: {
        length: 0,
      },
      axisLabel: { show: false },
      splitLine: {
        show: false,
      },
      splitNumber: 10,
      min: -50,
      max: 50,
      offset: 10,
      axisLine: {
        lineStyle: {
          color: "#f0f0f0",
        },
      },
    };
  }

  private getYAxis(): EChartsOption["yAxis"] {
    return {
      axisTick: {
        length: 0,
      },
      axisLabel: { show: false },

      nameGap: 8,
      nameTextStyle: {
        color: "#818282",
        fontFamily: "Spoqa Han Sans Neo Medium",
        fontSize: 12,
      },
      splitLine: {
        show: false,
      },
      min: -50,
      max: 50,
      offset: 100,
      axisLine: {
        lineStyle: {
          color: "#f0f0f0",
        },
      },
    };
  }

  private getAxisLine(): EChartsOption["axisLine"] {
    return {
      show: false,
      onZero: false,
    };
  }

  private getTooltip(): EChartsOption["tooltip"] {
    return {
      trigger: "item",
      formatter: "{b}",
    };
  }

  private getSeries(): EChartsOption["series"] {
    return [
      {
        symbolSize: 6,
        label: {
          show: true,
          color: "#818282",
          fontFamily: "Spoqa Han Sans Neo Medium",
          position: [15, 0],
          formatter: "{b}",
        },
        //echart에서 type을 export 해주지 않아서 any로 처리함
        data: this.getSeriesData() as any,
        type: "scatter",
        emphasis: {
          label: {
            fontWeight: "bolder",
            color: "#000",
          },
        },
      },
    ];
  }

  private getLegend(): EChartsOption["legend"] {
    return {
      itemWidth: 9,
      itemHeight: 9,
      bottom: 500,
      icon: "path:// M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0",
    };
  }
}

export default QuadrantChartOption;
