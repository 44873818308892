import axios, { AxiosResponse } from "axios";
import { useQuery } from "react-query";
import { IQuickPulseFetchData, QuickPulseDataType } from "../types";
import { util } from "./util";

const getData = () =>
  axios(
    "https://s3.ap-northeast-2.amazonaws.com/pocketsurvey.demo.pulsesurvey-report-viewer/2a97516c/quickPulse.json"
  );

const useMultiCardChartData = (): IQuickPulseFetchData | undefined => {
  const { data } = useQuery<AxiosResponse<QuickPulseDataType>>(
    ["@list/graph/quadrant"],
    getData,
    { suspense: true }
  );

  return util.getViewData(data?.data);
};

export default useMultiCardChartData;
