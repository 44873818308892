import styled from "styled-components";
import text from "../font";

export const Title = styled.p`
  ${text({
    size: 16,
    weight: "bold",
    color: "gray05",
  })}
  letter-spacing: -0.5px;
  width: fit-content;
  display: inline;
`;
