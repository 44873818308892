import { css } from "styled-components";
import text from "../../../../font";

export const cssUtil = {
  getCellText({ rank }: { rank?: number }) {
    return rank
      ? text({
          size: 14,
          weight: "bold",
          color: "gray05",
        })
      : text({
          size: 14,
          weight: "regular",
          color: "gray05",
        });
  },
  getRowBackground({ index }: { index: number }) {
    return index % 2 === 1 ? `#f9fafa` : `#fff`;
  },
  getCellWidth({
    rank,
    label,
    preference,
    isIframe,
  }: {
    rank?: number;
    label?: string;
    preference?: number;
    isIframe: boolean;
  }) {
    if (rank) {
      return "96px";
    }
    if (label) {
      return isIframe ? "200px" : "322px";
    }
    if (preference) {
      return isIframe ? "180px" : "258px";
    }
  },

  getRowBorderBottom({ index }: { index: number }) {
    return index === 2
      ? css`
          border-bottom: 1px solid #f9fafb;
        `
      : css``;
  },
};
