import React from "react";
import styled from "styled-components";
import text from "../../font";

interface ISummary {
  title: string;
  value: JSX.Element;
}

const Summary = ({ title, value }: ISummary) => {
  return (
    <SummaryWrapper>
      <SummaryTitle>{title}</SummaryTitle>
      <ValueWrapper>
        <SummayValue>{value}</SummayValue>
      </ValueWrapper>
    </SummaryWrapper>
  );
};

export default Summary;

const SummaryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const SummaryTitle = styled.p`
  ${text({
    color: "gray05",
    size: 16,
    weight: "bold",
  })}
  letter-spacing: -0.5px;
`;

const ValueWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 32px;

  background-color: #fbfaf8;
`;

const SummayValue = styled.p`
  line-height: 130%;
`;
