import EChartsReact from "echarts-for-react";
import React from "react";
import styled from "styled-components";
import { monthTrendOption } from "./MonthTrend.constant";

const MonthTrend = () => {
  return (
    <MonthTrendWrapper>
      <TitleWrapper>
        <Title>NPS 12개월 추이</Title>
      </TitleWrapper>
      <ChartBox>
        <EChartsReact
          option={monthTrendOption}
          style={{ width: "1136px", height: "382px" }}
          notMerge={true}
        />
      </ChartBox>
    </MonthTrendWrapper>
  );
};

export default MonthTrend;

const MonthTrendWrapper = styled.div`
  width: 1200px;
  padding: 32px;
  border: 1px solid #f0f0f0;
  border-radius: 3px;
  background-color: #ffffff;
`;

const ChartBox = styled.div``;

const TitleWrapper = styled.div`
  margin-bottom: 24px;
`;
const Title = styled.p`
  font-family: "Spoqa Han Sans Neo Medium";
  font-size: 18px;
  line-height: 150%;
  letter-spacing: -0.5px;
`;
