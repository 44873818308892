export const SUMMARY = [
  {
    title: "잘하고 있어요👍",
    value: (
      <ul>
        <li>
          <b>조직 전체</b>의 <b>몰입도 점수</b>가 3회 연속 증가하고 있어요. 이
          추세를 유지할 수 있도록 현재 노력을 유지해보세요.
        </li>
        <li>
          1회차 대비 조직 전체의 <b>업무 성취감</b>이 큰 폭으로 개선되었어요.
        </li>
      </ul>
    ),
  },
  {
    title: "개선이 필요해요😥",
    value: (
      <ul>
        <li>
          다른 구성원 대비 <b>팀리더</b>의 <b>몰입도 점수</b>에서 개선이 되지
          않고 있어요. <b>팀리더</b>의 몰입도 영향 요소를 면밀히 검토해보세요.
        </li>
        <li>
          <b>동료의 지지</b> 요소에서 <b>팀리더</b>의 만족도가 큰 폭으로
          감소하고 있어요. 잠재적으로 위험한 요소일 수 있으니 적극적으로
          개선해주세요.
        </li>
      </ul>
    ),
  },
];
