export const LEGEND_DATA = [
  '중점 개선 영역',
  '점전적 개선 영역',
  '유지 강화 영역',
  '현상 유지 영역',
];

export const LENGEND_COLOR = new Map([
  ['중점 개선 영역', '#f37165'],
  ['유지 강화 영역', '#70c33c'],
  ['점전적 개선 영역', '#f2ab28'],
  ['현상 유지 영역', '#818282'],
]);
