import { EChartsOption } from "echarts";
import EChartsReact from "echarts-for-react";
import React from "react";
import styled from "styled-components";
import text from "../../../../../font";

interface IPieGraph {
  option: EChartsOption;
  averageScore: number;
}

const PieGraph = ({ option, averageScore }: IPieGraph): JSX.Element => {
  return (
    <GraphWrapper>
      <EChartsReact option={option} style={{ width: 164, height: 164 }} />
      <AverageScoreWrapper>
        <Average>평균 점수</Average>
        <Score>{averageScore.toFixed(1)}점</Score>
      </AverageScoreWrapper>
    </GraphWrapper>
  );
};

const GraphWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
`;

const AverageScoreWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 64px;
  gap: 4px;
`;

const Average = styled.p`
  ${text({
    size: 14,
    weight: "medium",
    color: "gray04",
  })}
  letter-spacing: -0.5px;
`;

const Score = styled.p`
  ${text({
    size: 20,
    weight: "bold",
    color: "gray05",
  })}
  letter-spacing: -0.5px;
`;

export default PieGraph;
