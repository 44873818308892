import styled, { css } from "styled-components";

export const Legend1 = () => {
  return (
    <Background>
      <Box color={"#FAC62D"} />
      매출
      <svg
        style={{
          marginLeft: 12,
        }}
        width="10"
        height="11"
        viewBox="0 0 50 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line
          x1="2"
          y1="6"
          x2="47.0059"
          y2="6"
          stroke="black"
          stroke-width="4"
          stroke-linecap="round"
        />
        <circle
          cx="24.5"
          cy="5.5"
          r="4"
          fill="white"
          stroke="black"
          stroke-width="3"
        />
      </svg>
      성장률
    </Background>
  );
};

export const Legend2 = () => {
  return (
    <Background>
      <Box color={"#DB472D"} />
      보유 현금액
      <svg
        width="10"
        height="4"
        viewBox="0 0 54 4"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line
          x1="2"
          y1="2"
          x2="14"
          y2="2"
          stroke="black"
          stroke-width="4"
          stroke-linecap="round"
        />
        <line
          x1="40"
          y1="2"
          x2="52"
          y2="2"
          stroke="black"
          stroke-width="4"
          stroke-linecap="round"
        />
        <line
          x1="21"
          y1="2"
          x2="33"
          y2="2"
          stroke="black"
          stroke-width="4"
          stroke-linecap="round"
        />
      </svg>
      예상 런웨이
    </Background>
  );
};

const Background = styled.div`
  background-color: #f2f2f2;
  display: flex;
  width: fit-content;
  padding: 7px;
  align-items: center;
  gap: 7px;
  font-size: 10px;
  position: absolute;
  top: 7px;
  left: 15px;
`;

const Box = styled.div<{ color: string }>`
  width: 23px;
  height: 10px;
  ${(props) =>
    props.color &&
    css`
      background-color: ${props.color};
    `}
`;
