import React from "react";
import { useMatch, useParams } from "react-router-dom";
import EpisodeReport from "./EpisodeReport";

const ReportRouter = () => {
  const params = useParams<{ ID: string; projectID: string }>();

  const { ID, projectID } = params;

  if (!ID || !projectID) return <></>;

  return <EpisodeReport id={Number(ID)} projectID={Number(projectID)} />;
};

export default ReportRouter;
