import React from "react";
import { useParams } from "react-router-dom";

const CompanyName = () => {
  const { company } = useParams<{
    company: string;
  }>();

  return (
    <div>
      <div className="font-[700] text-[32px] leading-[150%] mb-[28px]">
        {company ?? ""}
      </div>
      <div className="border-[0.5px] border-solid border-[#DFDEDD] mb-[40px]" />
    </div>
  );
};

export default CompanyName;
