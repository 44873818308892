import { css, FlattenSimpleInterpolation } from "styled-components";
import text from "../../../font";
import { factorTagType, LabelType } from "../../types";

export const isLabelType = (
  row: string | number | LabelType,
  index: number
): row is LabelType => index === 4;

export const isFactorTagType = (
  row: string | factorTagType | number | number | LabelType,
  index: number
): row is factorTagType => index === 1;

export const cssUtil = {
  getRowText({ index }: { index: number }): FlattenSimpleInterpolation {
    return index === 0
      ? text({
          size: 14,
          weight: "bold",
          color: "gray05",
        })
      : text({
          size: 14,
          weight: "regular",
          color: "gray05",
        });
  },
  getRowBackgroundColor({ index }: { index: number }) {
    return index % 2 === 1
      ? css`
          background-color: #f9fafb;
        `
      : css``;
  },

  getHeaderWidth({ index, isIframe }: { index: number; isIframe: boolean }) {
    return index === 0 ? "36%" : "16%";
  },
};
