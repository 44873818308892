import React from "react";
import ReactECharts from "echarts-for-react";

const PersonalLineChart = () => {
  return (
    <ReactECharts
      option={LINE_CHART_OPTIONS}
      style={{ width: "830px", height: "440px" }}
    />
  );
};

export default PersonalLineChart;

const LINE_CHART_OPTIONS = {
  grid: { top: 50, right: 50, bottom: 100, left: 150 },
  xAxis: {
    axisTick: {
      length: 0,
    },
    axisLabel: {
      rotate: 50,
    },
    type: "category",
    data: [
      "2021-12-04",
      "2021-12-10",
      "2021-12-20",
      "2021-12-28",
      "2022-01-03",
      "2022-01-07",
      "2022-01-15",
      "2022-01-26",
      "2022-02-02",
      "2022-02-05",
      "2022-02-11",
    ],
  },
  yAxis: {
    type: "value",
  },
  legend: {
    top: 50,
    left: "left",
    orient: "vertical",
  },
  series: [
    {
      name: "조직 전체",
      data: [4.5, 4.3, 4.2, 4.4, 4.4, 4.7, 4.5, 4.5, 4.3, 4.2, 4.4, 4.6],
      type: "line",
      smooth: true,
      symbolSize: 10,
    },
    {
      name: "운영팀",
      data: [4.4, 4.3, 4, 4.1, 4.2, 4.4, 3.7, 3.6, 3.4, 3.6, 4.1, 4.2],
      type: "line",
      smooth: true,
      symbolSize: 10,
    },
    {
      name: "나무늘보님",
      data: [4, 4, 4, 4.1, 3, 2, 2.1, 1, 1.1, 1, 3, 4],
      type: "line",
      smooth: true,
      symbolSize: 10,
    },
  ],
  tooltip: {
    trigger: "axis",
  },
};
