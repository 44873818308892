import React from "react";
import CalloutLi from "./CalloutLi";
import styled from "styled-components";
import text from "../../../../font";

interface ICallout {
  question: string;
}

const Callout = ({ question }: ICallout) => {
  return (
    <CalloutWrapper>
      <CalloutTitle>문항 제목</CalloutTitle>
      <CalloutLi>Q : {question}</CalloutLi>
    </CalloutWrapper>
  );
};

export default Callout;

const CalloutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  background: #fbfaf8;
  border: 1px solid #f0f0f0;
  box-sizing: border-box;
  border-radius: 3px;
  gap: 16px;
`;

const CalloutTitle = styled.p`
  margin: 0;
  ${text({
    weight: "regular",
    size: 16,
    color: "gray04",
  })}
  line-height: 100%;
  letter-spacing: -0.5px;
`;
