import EChartsReact from "echarts-for-react";
import React from "react";
import { Legend1, Legend2 } from "./Legend";

const GrowthRateChart = () => {
  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <Legend1 />
      <EChartsReact
        option={{
          xAxis: {
            type: "category",
            data: ["1월", "2월", "3월", "4월", "5월", "6월"],
          },
          yAxis: [
            {
              type: "value",
              interval: 10000,
              axisLabel: {
                formatter: "{value}",
              },
            },
            {
              type: "value",
              min: 0,
              max: 60,
              interval: 10,
              axisLabel: {
                formatter: "{value} %",
              },
            },
          ],
          series: [
            {
              data: [26263, 27927, 30543, 37143, 43730, 54902],
              type: "bar",
              color: "#FAC62D",
            },
            {
              type: "line",
              yAxisIndex: 1,
              smooth: true,
              data: [5.3, 6.34, 9.34, 21.61, 17.73, 25.55],
              color: "#000000",
              symbolSize: 7,
            },
          ],
        }}
        style={{ width: "656px", height: "440px" }}
      />
    </div>
  );
};

export default GrowthRateChart;
