import styled, { CSSProperties } from "styled-components";

const NumberCell = ({ children }: { children: string }) => {
  const color = Number(children) > 0 ? "#45B47B" : "#F07764";
  return (
    <td
      style={{
        color,
      }}
    >
      {children}
    </td>
  );
};

const TableStyle = {
  Title: styled.h1`
    margin: 0;
    padding: 0;
    margin-bottom: 32px;
    font-size: 18px;
    line-height: 150%;
    letter-spacing: -0.5px;
    font-weight: 600;
    color: #2b2e33;
  `,
  TableWrapper: styled.table`
    border-collapse: collapse;
    border-radius: 6px;
    border-style: hidden;
    box-shadow: 0 0 0 1px #f0f0f0;

    white-space: nowrap;
    width: 1136px;

    * {
      box-sizing: border-box;
      color: #2b2e33;
    }
  `,
  Header: styled.tr`
    background: #f8f8f8;
    border: 1px solid #f0f0f0;
    height: 47px;
    font-size: 12px;

    th {
      padding: 17px 24px;
      vertical-align: middle;
      text-align: end;
    }

    th:not(:last-child) {
      border-right: 1px solid rgba(240, 240, 240, 0.5);
    }
  `,
  TableBody: styled.tr`
    td {
      font-size: 14px;
      height: 54px;
      padding: 20px 24px;
      vertical-align: middle;
      border-bottom: 1px solid #f0f0f0;

      text-align: end;
    }

    td:not(:last-child) {
      border-right: 1px solid rgba(240, 240, 240, 0.5);
    }
  `,
  alignStart: {
    textAlign: "start",
  } as CSSProperties,
  NumberCell,
};

export default TableStyle;
