import ReactECharts from "echarts-for-react";

import { VERTICAL_BAR_LINE_CHART2_OPTIONS } from "./VerticalBarLineChart2.utils";

const VerticalBarLineChart2 = () => {
  return (
    <ReactECharts
      option={VERTICAL_BAR_LINE_CHART2_OPTIONS}
      style={{ width: "1136px", height: "382px" }}
    />
  );
};

export default VerticalBarLineChart2;
