import React from "react";
import styled from "styled-components";
import text from "../../../font";
import { IGetVCData, VCDataType } from "../../hooks/query/useGetVCData";
import AnswerTable from "./TableElements/AnswerTable";
import AnswerTD from "./TableElements/AnswerTD";
import DateTD from "./TableElements/DateTD";
import HeaderTh from "./TableElements/HeaderTh";

interface IShorttextTableProps {
  dataList: VCDataType;
}

const ShorttextTable = ({ dataList }: IShorttextTableProps) => {
  const dates = dataList.map((v) => v.date);
  const goodContents = dataList.map(({ good }) => good);
  const badContents = dataList.map(({ bad }) => bad);
  const askContents = dataList.map(({ ask }) => ask);

  return (
    <div className="flex flex-col gap-[20px]">
      <TableHeader className="text-[#2B2E33] text-[18px]">
        잘하고 있는 영역
      </TableHeader>
      <AnswerTable dates={dates} contents={goodContents} />

      <TableHeader className="text-[#2B2E33] text-[18px]">
        개선이 필요한 영역
      </TableHeader>
      <AnswerTable dates={dates} contents={badContents} />

      <TableHeader className="text-[#2B2E33] text-[18px]">
        질문 / 요청 사항
      </TableHeader>
      <AnswerTable dates={dates} contents={askContents} />
    </div>
  );
};

export default ShorttextTable;

const TableHeader = styled.h1`
  ${text({
    weight: "bold",
  })}
`;
