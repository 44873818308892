import React, {
  useState,
  useMemo,
  useRef,
  useEffect,
  useCallback,
} from "react";
import styled, { css } from "styled-components";
import {
  Dropdown2,
  Icon,
  Button2,
} from "@earlysloth/pocketsurvey-ui-components";

import { IDropdownContextProps } from "@earlysloth/pocketsurvey-ui-components/dist/types/Dropdown2/Dropdown2";
import { RawEpisodeReportType } from "../hooks/useRawData";
import useOutsideAlerter from "../../useOutsideAlerter";
import text from "../../font";
import useEpisodeReportData from "../hooks/useEpisodeReportData";

interface IFilterDropdownProps {
  rawData: RawEpisodeReportType | undefined;

  filterInfo: {
    parent: string;
    child: string;
  } | null;
}

const inititalPosition = {
  top: 0,
  parent: "",
};

const FilterDropdown = ({ rawData, filterInfo }: IFilterDropdownProps) => {
  const [position, setPosition] = useState<{ top: number; parent: string }>(
    inititalPosition
  );

  const showTwoDepth = useMemo(() => !!position.top, [position]);
  const depth2Selections = useMemo(() => {
    try {
      if (!rawData) return [];
      return Object.keys(rawData[0][position.parent]) ?? [];
    } catch (e) {
      return [];
    }
  }, [position.parent]);

  const headers = useMemo(() => {
    if (!rawData) return [];
    return Object.keys(rawData[0]);
  }, []);
  const dropdown2Cxt = useRef<IDropdownContextProps>(null);

  const wrapperDom = useRef<HTMLDivElement>(null);
  const dropdownWrapperDom = useRef<HTMLDivElement>(null);

  const { setFilterInfo } = useEpisodeReportData();

  useOutsideAlerter(dropdownWrapperDom, () => {
    dropdown2Cxt.current?.setShowList(false);
    setPosition(inititalPosition);
  });

  const handleClickParentSelection = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    head: string
  ) => {
    const { top } = event.currentTarget.getBoundingClientRect();
    const dropdownWrapperRect =
      dropdownWrapperDom.current?.getBoundingClientRect();

    setPosition({
      top: top - (dropdownWrapperRect?.top ?? 0),
      parent: head,
    });
  };

  const handleClickChildSelection = (item: string) => {
    setPosition(inititalPosition);
    dropdown2Cxt.current?.setShowList(false);

    setFilterInfo({
      parent: position.parent,
      child: item,
    });
  };

  return (
    <FilterDropdownWrapper ref={dropdownWrapperDom}>
      <DropdownResetWrapper>
        {!!filterInfo && (
          <ResetButton
            preset="tertiary-text"
            onClick={() => {
              setFilterInfo(null);
            }}
          >
            필터 초기화
          </ResetButton>
        )}
        <Dropdown2
          value={
            filterInfo
              ? `${filterInfo?.parent} - ${filterInfo?.child}`
              : "보고서 전체 필터 설정하기"
          }
          extraCSS={css`
            width: 205px;
          `}
          ref={dropdown2Cxt}
        >
          <Group ref={wrapperDom}>
            <Selection
              onClick={() => {
                setFilterInfo(null);
                dropdown2Cxt.current?.setShowList(false);
              }}
            >
              전체
            </Selection>
            {headers.map((head) => (
              <Selection
                onClick={(event) => {
                  handleClickParentSelection(event, head);
                }}
              >
                {head}
                <Icon icon="arrow" width={16} color={"#2B2E33"} />
              </Selection>
            ))}
          </Group>
        </Dropdown2>
      </DropdownResetWrapper>

      {showTwoDepth && (
        <TwoDepthGroup top={position.top}>
          {depth2Selections.map((item) => (
            <Selection
              key={`selection-${item}`}
              onClick={() => {
                handleClickChildSelection(item);
              }}
            >
              {item}
            </Selection>
          ))}
        </TwoDepthGroup>
      )}
    </FilterDropdownWrapper>
  );
};

export default FilterDropdown;

const FilterDropdownWrapper = styled.div`
  position: relative;
`;

const Selection = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  height: 36px;
  &:hover {
    background: #f0f0f0;
  }
  white-space: nowrap;

  ${text({
    weight: "regular",
    size: 14,
    color: "gray05",
  })}
`;

const TwoDepthGroup = styled.div<{
  top: number;
}>`
  position: absolute !important;
  margin: 0;
  padding: 8px 0;
  border: 1px solid #dfdedd;
  border-radius: 3px;
  background: #ffffff;
  box-shadow: 0px 1px 4px rgb(0 0 0 / 10%);
  box-sizing: border-box;
  z-index: 999;
  left: 240px;
  ${(props) =>
    props.top &&
    css`
      top: ${props.top}px;
    `}
`;

const Group = styled.div`
  margin: 0;
  padding: 8px 0;
  border: 1px solid #dfdedd;
  border-radius: 3px;
  background: #ffffff;
  box-shadow: 0px 1px 4px rgb(0 0 0 / 10%);
  box-sizing: border-box;
  z-index: 999;
  max-height: 400px;
  overflow-y: auto;
  min-width: 240px;
  max-width: 205px;
  top: 40px;
  position: initial;
`;

const DropdownResetWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

const ResetButton = styled(Button2)`
  ${text({
    weight: "medium",
    size: 14,
    color: "boldYellow",
  })}
  margin-left: -37px;
  margin-top: -4px;
  background-color: initial !important;
`;
