import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useParams } from "react-router-dom";
import styled, { css } from "styled-components";
import { Title } from "../../EpisodeReport/styles";

import { DUMMY_DATA, values } from "./constants";
import WorkCard from "./WorkCard";

const PotentialWork = () => {
  const params = useParams<{ ID: string; projectID: string }>();
  const isIframe = useMediaQuery({
    query: "(max-width:1100px)",
  });

  const { ID, projectID } = params;

  if (!ID || !projectID) return <></>;

  return (
    <PotentialWorkWrapper isIframe={isIframe}>
      <Title>인공지능 제언</Title>
      <WorkCardWrapper>
        {values.get(Number(ID))?.map((v) => (
          <WorkCard cardValue={v.cardValue} />
        ))}
      </WorkCardWrapper>
    </PotentialWorkWrapper>
  );
};

export default PotentialWork;

const PotentialWorkWrapper = styled.div<{
  isIframe: boolean;
}>`
  padding-bottom: 30px;
  margin-top: 45px;
  ${(props) =>
    props.isIframe
      ? css`
          width: 937px;
          overflow: scroll;
        `
      : css``}
`;

const WorkCardWrapper = styled.div`
  display: flex;
  gap: 24px;
  margin-top: 22px;
`;
