import React from "react";
import styled from "styled-components";
import Header from "../../../Components/Header";
import Search from "../../../Components/Search";
import Column from "../../../Components/Table/Column";
import DiagnosisChart from "./DiagnosisChart";
import Rows from "../../../Components/Table/Rows";

const ReportGraph = () => {
  const column = ["", "만족도", "중요도", "IPA", ""];

  const rows = [
    ["법인카드 지원", "1.05", "3.25", "중점 개선 영역", ""],
    ["통신비 지원", "3.89", "2.58", "유지 강화 영역", ""],
    ["OTT 구독 지원", "1.17", "1.91", "점진적 개선 영역", ""],
    ["주차비 지원", "0.72", "2.08", "점진적 개선 영역", ""],
    ["네이버페이 만원", "0.80", "1.58", "점진적 개선 영역", ""],
    ["결혼 시 휴가 지원", "2.66", "1.58", "현상 유지 영역", ""],
    ["가족 조사 지원", "0.48", "2.16", "점진적 개선 영역", ""],
    ["부모님 환갑 화환", "0.80", "2.00", "점진적 개선 영역", ""],
    ["명절 지원금", "4.33", "2.83", "유지 강화 영역", ""],
    ["회사 헬스장 운영", "4.91", "2.75", "유지 강화 영역", ""],
    ["회사 조식 뷔페 운영", "5.00", "2.50", "유지 강화 영역", ""],
    ["회사 간식 지원", "4.40", "2.08", "현상 유지 영역", ""],
    ["회사내 어린이집 지원", "0.46", "2.83", "중점 개선 영역", ""],
    ["전세 대출 무이자 지원", "5.00", "3.83", "유지 강화 영역", ""],
  ];

  return (
    <ReportGraphWrapper>
      <Header
        title={"진단 그래프"}
        subTitle={
          "이번 조사의 결과를 진단한 그래프 입니다. 복지제도에 대한 직원들의 평가를 한눈에 확인해보세요."
        }
      />
      <DiagnosisChart />
      <Search />
      <Column column={column} type="diagnosisReport" />
      <Rows rows={rows} type="diagnosisReport" />
    </ReportGraphWrapper>
  );
};

const ReportGraphWrapper = styled.div`
  padding: 32px;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.gray01};
  border-radius: 3px;
`;

export default ReportGraph;
