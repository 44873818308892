
import React from 'react';
import styled from 'styled-components';
import text from '../../font';
import { ILengendData } from '../types';


interface ILenged {
  legendData: ILengendData;
}

const Legend = ({ legendData }: ILenged): JSX.Element => {
  return (
    <LegendWrappperItem>
      <Left>
        <LegendColor color={legendData.color} />
        <LegendItem>{legendData.name}</LegendItem>
      </Left>
      <Right>
        <LegendFigures>
          {legendData.percent}% ({legendData.N}명)
        </LegendFigures>
      </Right>
    </LegendWrappperItem>
  );
};

const LegendWrappperItem = styled.div`
  display: flex;
  ${text({
    size: 14,
    color: 'gray04',
    weight: 'regular',
  })}
  letter-spacing: -0.5px;
  justify-content: space-between;
  align-items: center;
`;

const Left = styled.div`
  display: flex;
  gap: 12px;
`;
const Right = styled.div`
  display: flex;
`;

const LegendColor = styled.div<{ color: string }>`
  background-color: ${(props) => props.color};
  width: 24px;
  height: 24px;
  border-radius: 3px;
`;

const LegendItem = styled.p`
  margin-top: 2px;
`;

const LegendFigures = styled.p`
  margin-top: 2px;
`;

export default Legend;
