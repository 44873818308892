
import { Button2 } from '@earlysloth/pocketsurvey-ui-components';
import React from 'react';
import { shallowEqual } from 'react-redux';
import styled from 'styled-components';
import text from '../../../font';
import Modal from '../../components/Modal';
import useResultReportStore from '../../Module/Zustand/useResultReportStore';
import { DESCRIPTION_AREA } from './constants';
import { cssUtil } from './util';

const ExplainModal = () => {
  const { setIsModalOpen } = useResultReportStore(
    (state) => ({
      setIsModalOpen: state.setIsModalOpen,
    }),
    shallowEqual,
  );
  return (
    <Modal>
      <ExplainModalWrapper>
        <TitleWrapper>
          <Title>IPA 분석 그래프 안내</Title>
        </TitleWrapper>
        <MainWrapper>
          {DESCRIPTION_AREA.map((v, index) => (
            <DescriptionArea styled={v.style} index={index} key={v.area}>
              <AreaTitle styled={v.style}>{v.area}</AreaTitle>
              <Describe>{v.describe}</Describe>
            </DescriptionArea>
          ))}
          <ImportanceHeight>높음</ImportanceHeight>
          <Importance>중요도</Importance>
          <Satisfaction>만족도</Satisfaction>
          <SatisfactionHeight>높음</SatisfactionHeight>

          <Lowness>낮음</Lowness>
          <Lowness2>낮음</Lowness2>
        </MainWrapper>
        <FooterWrapper>
          <Button2
            preset="secondary-basic-gray"
            size="small"
            onClick={() => setIsModalOpen()}
          >
            확인
          </Button2>
        </FooterWrapper>
      </ExplainModalWrapper>
    </Modal>
  );
};

export default ExplainModal;

const ExplainModalWrapper = styled.div`
  width: 689px;
  background-color: #fff;
  * {
    box-sizing: border-box;
  }
  position: relative;
`;

const TitleWrapper = styled.div`
  height: 66px;
  padding: 24px 32px;
  background-color: #fbfaf8;
`;
const Title = styled.p`
  ${text({
    size: 18,
    weight: 'bold',
    color: 'gray05',
  })}
  letter-spacing: -0.5px;
`;

const MainWrapper = styled.div`
  width: 438px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border: 1px solid #c9c8c7;
  margin: 32px auto;
`;

const DescriptionArea = styled.div<{
  index: number;
  styled: {
    backgroundColor: '#fcf6f4' | '#f4f4f4' | '#f7fced' | '#fff9f0' | undefined;
    color: '#70c33c' | '#f2ab28' | '#f37165' | '#818282' | undefined;
  };
}>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 218px;
  height: 113px;
  background-color: ${(props) => props.styled.backgroundColor};
  padding: 14px;
  box-sizing: border-box;
  ${cssUtil.getBorder}
`;

const AreaTitle = styled.p<{
  styled: {
    backgroundColor: '#fcf6f4' | '#f4f4f4' | '#f7fced' | '#fff9f0' | undefined;
    color: '#70c33c' | '#f2ab28' | '#f37165' | '#818282' | undefined;
  };
}>`
  color: ${(props) => props.styled.color};
  ${text({
    weight: 'bold',
    size: 16,
  })}
  letter-spacing: -0.5px;
`;

const Describe = styled.p`
  ${text({
    weight: 'regular',
    size: 12,
    color: 'gray05',
  })}
  letter-spacing: -0.5px;
`;

const FooterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #f0f0f0;
  padding: 16px 24px;
`;

const Importance = styled.p`
  position: absolute;
  ${text({
    size: 12,
    weight: 'medium',
    color: 'gray04',
  })}
  letter-spacing: -0.5px;
  left: 88px;
  top: 97px;
`;
const Satisfaction = styled.p`
  position: absolute;
  ${text({
    size: 12,
    weight: 'medium',
    color: 'gray04',
  })}
  letter-spacing: -0.5px;
  bottom: 78px;
  right: 127px;
`;

const ImportanceHeight = styled.p`
  position: absolute;
  ${text({
    size: 12,
    weight: 'medium',
    color: 'gray03',
  })}
  letter-spacing: -0.5px;
  bottom: 78px;
  right: 163px;
`;

const SatisfactionHeight = styled.p`
  position: absolute;
  ${text({
    size: 12,
    weight: 'medium',
    color: 'gray03',
  })}
  letter-spacing: -0.5px;
  top: 117px;
  left: 98px;
`;

const Lowness = styled.p`
  position: absolute;
  ${text({
    size: 12,
    weight: 'medium',
    color: 'gray03',
  })}
  letter-spacing: -0.5px;
  bottom: 78px;
  left: 130px; ;
`;

const Lowness2 = styled.p`
  position: absolute;
  ${text({
    size: 12,
    weight: 'medium',
    color: 'gray03',
  })}
  letter-spacing: -0.5px;
  bottom: 103px;
  left: 98px;
`;
