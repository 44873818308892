import React from "react";

interface Props {
  label: string;
  data: string;
}

const TrendReportSuggestion = ({ label, data }: Props) => {
  return (
    <div className="mb-[24px] px-[32px] py-[22px] border-solid border-[1px] border-[#f0f0f0] rounded-[3px]">
      <div className="text-[18px] leading-[150%] font-[700] mb-[9px]">
        {label}
      </div>
      <div
        className="text-[14px] leading-[150%] text-[#2B2E33]"
        dangerouslySetInnerHTML={{
          __html: data,
        }}
      />
    </div>
  );
};

export default TrendReportSuggestion;
