import React from "react";
import styled from "styled-components";
import Header from "../Header/Header";

const EmptyPage = () => {
  return (
    <EmptyPageWrapper>
      {["컨디션", "몰입도"].map((el) => (
        <ReportWrapper>
          <Header title={el} />
          <TopPostion></TopPostion>
        </ReportWrapper>
      ))}
    </EmptyPageWrapper>
  );
};

export default EmptyPage;

const EmptyPageWrapper = styled.div``;

const ReportWrapper = styled.div`
  margin-top: 24px;
`;

const TopPostion = styled.div`
  display: flex;
  gap: 32px;
`;
