const YAXIS_LABEL_DATA = [
  "객실 컨디션",
  "예약 과정",
  "컨시어지 서비스",
  "체크 아웃",
  "전담 매니저",
  "케이터링",
  "음식 주문",
  "온라인 예약",
  "어메니티",
  "고객 서비스",
];

const GOOD_DATA = [
  73.88, 75.19, 71.68, 99.66, 83.7, 89.24, 79.39, 68.57, 65.99, 62.5,
];

const BAD_DATA = [
  -39.31, -14.99, -28.39, -39.22, -32.13, -17.57, -31.48, -45.59, -24.01,
  -48.71,
];

export const BAR_CHART_WITH_NEGATIVE_VALUE_OPTIONS = {
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "shadow",
    },
  },
  legend: {
    data: ["부정", "긍정"],
    icon: "circle",
    bottom: 10,
    itemWidth: 9,
    itemHeight: 9,
  },
  grid: {
    top: 40,
    left: "3%",
    right: "4%",
    bottom: 45,
    containLabel: true,
  },
  xAxis: {
    type: "value",
    splitLine: {
      show: true,
    },
    min: -100,
    max: 100,
    axisLabel: {
      formatter: "{value}점",
    },
  },
  yAxis: [
    {
      axisTick: {
        show: false,
      },
      data: YAXIS_LABEL_DATA,
      axisLine: {
        show: false,
      },
    },
  ],
  color: ["#89D8B2", "#FFA093"],
  series: [
    {
      name: "긍정",
      type: "bar",
      stack: "Total",
      emphasis: {
        focus: "series",
      },
      data: GOOD_DATA,
      barWidth: 20,
    },
    {
      name: "부정",
      type: "bar",
      stack: "Total",
      emphasis: {
        focus: "series",
      },
      data: BAD_DATA,
      barWidth: 20,
    },
  ],
};
