import React, { useMemo } from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import HRDashboardLayout from "../../styles";
import { ILineSmoth } from "../../DashboardGraphs/types";

import EChartsReact from "echarts-for-react";
import TrendIndexUtil from "../../DashboardGraphs/TrendChart/TrendIndexUtil";
import withAsyncError from "../WithAsyncError/WithAsyncError";
import ComponentLoading from "../../../EpisodeReport/components/ComponentLoading";
import useTrendData from "./hooks/getTrendData";

const TrendIndex = () => {
  const rawTrendData = useTrendData();
  const myData = useMemo(
    () => new TrendIndexUtil(getRawData(rawTrendData)),
    [rawTrendData]
  );

  const isIframe = useMediaQuery({
    query: "(max-width:1100px)",
  });

  return (
    <HRDashboardLayout.BlockWrapper height={570}>
      <HRDashboardLayout.Title>몰입도 트렌드 지수</HRDashboardLayout.Title>
      <ChartBox>
        {isIframe ? (
          <EChartsReact
            option={myData.getChartOption(true)}
            style={{ width: "750px", height: "100%", margin: "0 auto" }}
          />
        ) : (
          <EChartsReact
            option={myData.getChartOption()}
            style={{ width: "1175px", height: "100%", margin: "0 auto" }}
          />
        )}
      </ChartBox>
    </HRDashboardLayout.BlockWrapper>
  );
};

const getRawData = (
  rawTrendData:
    | {
        전체: number[];
        팀리더: number[];
        팀원: number[];
      }
    | undefined
): ILineSmoth => {
  return {
    type: "line_smoth",
    xAxis: ["1회차", "2회차", "3회차"],
    series: [
      {
        name: "전체",
        values: rawTrendData?.전체 ?? ([] as number[]),
      },
      {
        name: "팀리더",
        values: rawTrendData?.팀리더 ?? ([] as number[]),
      },
      {
        name: "팀원",
        values: rawTrendData?.팀원 ?? ([] as number[]),
      },
    ],
  };
};

export default withAsyncError(
  TrendIndex,
  <ComponentLoading width="100%" height="920px" backgroundColor="#ffffff" />
);

const ChartBox = styled.div`
  height: 440px;
`;
