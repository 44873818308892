import EChartsReact from "echarts-for-react";
import React from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import text from "../../../font";
import QuadrantChartOption from "../../EpisodeReportChartOptions/QuadrantChart/QuadrantChartOption";
import { IQuadrantChartData } from "../../types";

import { IPADataUtil } from "../util";
import { LEGEND_DATA } from "./constants";
import { cssUtil } from "./util";

interface IQuadrantChart {
  chartData: IQuadrantChartData[];
}

const QuadrantChart = ({ chartData }: IQuadrantChart): JSX.Element => {
  const option = new QuadrantChartOption(chartData);
  const isIframe = useMediaQuery({
    query: "(max-width:1100px)",
  });
  return (
    <QuadrantChartWrapper>
      {isIframe ? (
        <EChartsReact
          option={option.getOption(true)}
          style={{ height: 500, width: 9000 }}
          opts={{
            renderer: "svg",
          }}
        />
      ) : (
        <EChartsReact
          option={option.getOption()}
          style={{ height: 500, width: 1100 }}
          opts={{
            renderer: "svg",
          }}
        />
      )}

      <LegendWrapper isIframe={isIframe}>
        {chartData.length > 0 &&
          LEGEND_DATA.map((v, index) => (
            <LegendItem key={index + 1}>
              <LegendSymbol legendData={v} />
              <Value>{v}</Value>
            </LegendItem>
          ))}
      </LegendWrapper>
      <ImportanceHeight>높음</ImportanceHeight>
      <SatisfactionHeight isIframe={isIframe}>높음</SatisfactionHeight>
      <Lowness>낮음</Lowness>
      <XAxisName>만족도</XAxisName>
      <YAxisName isIframe={isIframe}>중요도</YAxisName>
      <XAxisLine isIframe={isIframe} />
      <YAxisLine isIframe={isIframe} />
    </QuadrantChartWrapper>
  );
};

export default QuadrantChart;

const QuadrantChartWrapper = styled.div`
  position: relative;
`;

const LegendWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  gap: 16px;
  left: ${cssUtil.getLegendWrapperLeft};
  bottom: 15px;
  ${text({
    size: 12,
    color: "gray05",
    weight: "medium",
  })}
  letter-spacing: -0.5px;
`;

const LegendItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
`;

const LegendSymbol = styled.div<{
  legendData: string;
}>`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: ${cssUtil.getLegendColor};
`;

const Value = styled.p``;

const ImportanceHeight = styled.p`
  position: absolute;
  ${text({
    size: 12,
    weight: "medium",
    color: "gray03",
  })}
  letter-spacing: -0.5px;
  bottom: 50px;
  right: 52px;
`;

const SatisfactionHeight = styled.p`
  position: absolute;
  ${text({
    size: 12,
    weight: "medium",
    color: "gray03",
  })}
  letter-spacing: -0.5px;
  top: 60px;
  left: ${cssUtil.getSatisfactionHeightLeft};
`;

const Lowness = styled.p`
  position: absolute;
  ${text({
    size: 12,
    weight: "medium",
    color: "gray03",
  })}
  letter-spacing: -0.5px;
  bottom: 50px;
  left: 16px;
`;

const XAxisName = styled.p`
  ${text({
    size: 12,
    weight: "medium",
    color: "gray05",
  })}
  letter-spacing: -0.5px;
  position: absolute;
  bottom: 50px;
  right: 15px;
`;
const YAxisName = styled.p`
  ${text({
    size: 12,
    weight: "medium",
    color: "gray05",
  })}
  letter-spacing: -0.5px;
  position: absolute;
  top: 38px;
  left: ${cssUtil.getYAxisName};
`;

const XAxisLine = styled.div`
  ${cssUtil.getXAxisLine}
`;

const YAxisLine = styled.div<{
  isIframe: boolean;
}>`
  ${cssUtil.getYAxisLine}
`;
