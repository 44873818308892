import create from 'zustand';
import { combine } from 'zustand/middleware';
import produce from 'immer';

interface IRectTooltip {
  position?: {
    x: number;
    y: number;
  };
  selectedLabel?: string;
}

const initState: IRectTooltip = {
  position: undefined,
  selectedLabel: undefined,
};

const useRectTooltip = create(
  combine(initState, (set, get) => ({
    setSelectedLabel: (label: string) => {
      set(
        produce((state: IRectTooltip) => {
          state.selectedLabel = label;
        }),
      );
    },
    setPosition: (
      event: React.MouseEvent<HTMLDivElement, MouseEvent> | undefined,
    ) => {
      set(
        produce((state: IRectTooltip) => {
          if (event === undefined) {
            state.position = undefined;
            return;
          }

          const rect = event.currentTarget.getBoundingClientRect();
          const position = {
            x: rect.x,
            y: window.pageYOffset + rect.y,
          };
          state.position = position;
        }),
      );
    },
  })),
);

export default useRectTooltip;
