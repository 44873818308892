import create from 'zustand';
import { combine, devtools } from 'zustand/middleware';
import produce from 'immer';

export interface IShorttextAnswerState {
  selectedColumnId: string | null;
  filterXYPosition: { x: number; y: number };
}

const initState: IShorttextAnswerState = {
  selectedColumnId: null,
  filterXYPosition: {
    x: 0,
    y: 0,
  },
};

const useShorttextAnswer = create(
  devtools(
    combine(initState, (set, get) => ({
      handleFilterClick: (
        event: React.MouseEvent<HTMLElement>,
        columnId: string,
        parentRect: DOMRect | undefined,
      ) => {
        const { x, y } = event.currentTarget.getBoundingClientRect();
        set(
          produce((state: IShorttextAnswerState) => {
            state.selectedColumnId = columnId;
            state.filterXYPosition = {
              x: x - 300,
              y,
            };
          }),
        );
      },
      setClickedColumnId: (payload: string | null) => {
        set(
          produce((state: IShorttextAnswerState) => {
            state.selectedColumnId = payload;
          }),
        );
      },
    })),
  ),
);

export default useShorttextAnswer;
