import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "styled-components";

import Router from "./Router";
import GlobalStyle from "./styles/GlobalStyle";
import GlobalFonts from "./styles/GlobalFonts";
import { theme } from "./styles/theme";
import { QueryClient, QueryClientProvider } from "react-query";

import "./styles/default.css";

const queryClient = new QueryClient();

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <GlobalFonts />
    <GlobalStyle />
    <QueryClientProvider client={queryClient}>
      <Router />
    </QueryClientProvider>
  </ThemeProvider>,
  document.getElementById("root")
);
