import { EChartsOption } from "echarts";

import { TrendIndexChartUtil } from "./TrendIndexChartUtil";
import { ILineSmoth } from "../types";
import ChartError, {
  ErrorCode,
} from "../../../EpisodeReport/EpisodeReportChartOptions/ChartError";

class TrendIndexUtil extends TrendIndexChartUtil {
  private readonly options: EChartsOption = {};

  constructor(protected readonly graphData: ILineSmoth) {
    super(graphData);
  }

  getChartOption(isIframe = false): EChartsOption {
    this.options.series = this.getSeries();
    this.options.xAxis = this.getXAxis();
    this.options.yAxis = this.getYAxis();
    this.options.grid = this.getGrid(isIframe);
    this.options.legend = this.getLegend();
    this.options.tooltip = this.getTooltip();

    return {
      ...this.options,
    };
  }

  private getSeries(): EChartsOption["series"] {
    try {
      const chartSeries: EChartsOption["series"] = super.getSeriesData(
        this.graphData
      );
      return chartSeries;
    } catch (e) {
      throw new ChartError(ErrorCode.E1);
    }
  }

  private getXAxis(): EChartsOption["xAxis"] {
    return {
      type: "category",
      data: this.getXAxisData(this.graphData.series[0].values.length),
      axisLine: {
        lineStyle: {
          color: "#818282",
        },
      },
    };
  }

  private getYAxis(): EChartsOption["yAxis"] {
    return {
      type: "value",
      min: 0,
      max: 100,
      axisLabel: {
        formatter: "{value}%",
      },
      axisLine: {
        lineStyle: {
          color: "#C9C8C7",
        },
      },
    };
  }

  private getGrid(isIframe = false): EChartsOption["grid"] {
    return { top: "20%", width: isIframe ? 725 : 1090, left: "4%" };
  }

  private getLegend(): EChartsOption["legend"] {
    return {
      itemWidth: 9,
      itemHeight: 9,
      bottom: 0,
      icon: "path:// M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0",
    };
  }

  private getTooltip(): EChartsOption["tooltip"] {
    return {
      trigger: "axis",
      backgroundColor: `rgba(0, 0, 0, 0)`,
      borderColor: `rgba(0, 0, 0, 0)`,
      padding: 0,
      triggerOn: "click",
      formatter: (params) => this.getTooltipFormatter(params),
      position: (point, params) => this.getTooltipPosition(point, params),
      axisPointer: {
        shadowStyle: {
          color: "rgba(0,0,0,0)",
        },
      },
    };
  }
}

export default TrendIndexUtil;
