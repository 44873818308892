
import { LABEL_TAG_COLOR } from '../../constants';
import { IQuadrantChartData, IQuadrantChartDataWithColor, IQuadrantChartDataWithTooltip, LabelColor, LabelType, tooltipType } from '../../types';
import ChartUtil from '../ChartUtil';
import {
  유지강화영역,
  점진적개선영역,
  중점개선영역,
  현상유지영역,
} from './constants';

class QuadrantChartUtil extends ChartUtil {
  private readonly labelColor = LABEL_TAG_COLOR;

  private readonly tooltipFormat = new Map([
    [LabelType['중점 개선 영역'], 중점개선영역],
    [LabelType['현상 유지 영역'], 현상유지영역],
    [LabelType['점진적 개선 영역'], 점진적개선영역],
    [LabelType['유지 강화 영역'], 유지강화영역],
  ]);

  constructor(protected readonly graphData: IQuadrantChartData[]) {
    super();
  }

  protected getSeriesData() {
    return this.setItemStyleAccordingToLabel();
  }

  private setItemStyleAccordingToLabel() {
    return this.setTooltipAccordingToLabel().map((v) => {
      const borderColor = this.labelColor.get(v.label) as LabelColor;
      return {
        ...v,
        emphasis: {
          itemStyle: {
            borderWidth: 4,
            borderColor,
          },
        },
        label: {
          position: v.value[0] > 40 ? [-70, 10] : [10, 5],
        },
      };
    });
  }

  private setTooltipAccordingToLabel(): IQuadrantChartDataWithTooltip[] {
    return this.setColorAccordingToLabel().map((v) => {
      const tooltip = this.tooltipFormat.get(v.label) as tooltipType;

      return {
        ...v,
        tooltip: {
          formatter: tooltip,
          backgroundColor: 'rgba(0,0,0,0)',
          borderColor: '#fff',
          padding: 0,
        },
      };
    });
  }

  private setColorAccordingToLabel(): IQuadrantChartDataWithColor[] {
    return this.graphData.map((v) => {
      const color = this.labelColor.get(v.label) as LabelColor;

      return {
        ...v,
        itemStyle: {
          color,
        },
      };
    });
  }
}

export default QuadrantChartUtil;
