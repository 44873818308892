import React, { useEffect } from "react";
import styled, { css } from "styled-components";
import { Icon } from "@earlysloth/pocketsurvey-ui-components";

import useRectTooltip from "../hooks/useRectTooltip";
import text from "../../font";

const RectTooltip = () => {
  const { position, setPosition, selectedLabel } = useRectTooltip((state) => ({
    position: state.position,
    setPosition: state.setPosition,
    selectedLabel: state.selectedLabel,
  }));

  if (!position) return <></>;

  return (
    <RectTooltipWrapper x={position.x} y={position.y}>
      <InnerWrapper>
        <Title>{selectedLabel}</Title>
        <XIcon
          icon="exit"
          width={16}
          color="#2B2E33"
          onClick={() => {
            setPosition(undefined);
          }}
          useCursor
        />
      </InnerWrapper>
      <ValueWrapper>
        {!!selectedLabel ? (
          <ListWrapper>
            {TOOLTIP_MAP.get(selectedLabel)?.map((item) => (
              <LiDot>{item}</LiDot>
            ))}
          </ListWrapper>
        ) : null}
      </ValueWrapper>
    </RectTooltipWrapper>
  );
};

export default RectTooltip;

export const QuestionIconWrapper = styled.div`
  width: fit-content;
`;

const RectTooltipWrapper = styled.div<{ x?: number; y?: number }>`
  position: absolute;
  background: #ffffff;
  border: 1px solid #dfdedd;
  box-sizing: border-box;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  z-index: 300;
  max-width: 622px;
  padding: 24px;

  ${text({
    weight: "regular",
    size: 14,
    color: "gray05",
  })}

  ${(props) =>
    props.x &&
    css`
      left: ${props.x}px;
    `}

${(props) =>
    props.y &&
    css`
      top: ${props.y}px;
    `}
`;

const XIcon = styled(Icon)`
  margin-bottom: 2px;
`;

const InnerWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
`;

const Title = styled.p`
  ${text({
    weight: "bold",
    size: 14,
    color: "gray05",
  })}
`;

const LiDot = styled.li`
  list-style-type: disc;
  ${text({
    weight: "regular",
    size: 14,
    color: "gray05",
  })};
  line-height: 130%;
  word-break: keep-all;
`;

const ListWrapper = styled.ul`
  padding-left: 12px;
  padding-right: 12px;

  gap: 8px;
  display: flex;
  flex-direction: column;
`;

const TOOLTIP_MAP = new Map([
  [
    "요소 선호도란?",
    [
      "설문에 사용된 여러 평가 요소에 대해 조직원 전체가 가장 선호하고 중요하게 생각하는 요소가 무엇인지 보여주는 점수입니다.",
      "조직원들이 더 높은 우선순위로 많이 선택한 평가요소일수록 해당 평가 요소의 선호도가 증가합니다.",
      "가장 선호되는 요소의 선호도를 100%로 보고 나머지 요소들의 선호도를 상대적인 비율로 표시합니다.",
    ],
  ],
  [
    "평가 영향이란?",
    [
      "해당 평가 요소에 대한 조직원들의 전반적인 평가가 부정적인지 긍정적인지를 보여주는 척도입니다. (중요도 및 만족도와는 관계가 없습니다.)",
      "요소의 평가 영향이 긍정 영향인 경우, 이는 해당 요소에 대한 조직원들의 전반적인 평가가 긍정적임을 뜻하며 해당 요소가 조직 평가(조직원 컨디션, 몰입도, 만족도 등)에 긍정적인 영향을 주고 있음을 보여줍니다.",
      "요소의 평가 영향이 부정 영향인 경우, 이는 해당 요소에 대한 조직원들의 전반적인 평가가 부정적임을 뜻하며 해당 요소가 조직 평가(조직원 컨디션, 몰입도, 만족도 등)에 부정적인 영향을 주고 있음을 보여줍니다.",
    ],
  ],
  [
    "중요도란?",
    [
      "중요도는 해당 요소를 선택하거나 선택하지 않은 모든 설문 참여자의 의견을 반영합니다.",
      "중요도는 상대수치이며, 모든 요소들의 중요도를 더하면 100%가 됩니다.",
      "해당 평가 요소가 조직의 상태에 영향을 미치는 정도를 보여주는 점수입니다.",
      "중요도가 높은 요소들은 낮은 요소들에 비해 조직평가에 큰 영향을 미칩니다.",
    ],
  ],
  [
    "만족도란?",
    [
      "만족도는 설문 응답자 중 해당 요소를 선택한 응답자들만의 의견을 반영합니다.",
      "평가 중 해당 요소를 선택하지 않은 조직원들은 만족도에 영향을 주지 않습니다.",
      "만족도는 절대수치이며, -100% ~ +100% 사이의 수치가 나옵니다.",
      "만족도 수치가 +값이면 만족, -값이면 불만족을 의미합니다.",
    ],
  ],
]);

const ValueWrapper = styled.div``;
