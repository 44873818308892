import React, { useRef } from "react";
import styled, { css, FlattenSimpleInterpolation } from "styled-components";
import text from "../../font";
import useOutsideAlerter from "../../useOutsideAlerter";

type SizeType = number | string;

interface ISizeProps {
  width?: SizeType;
  height?: SizeType;
  padding?: SizeType;
}

interface IModalProps extends ISizeProps {
  children: React.ReactNode;
  outsideClickCallback?: () => any;
  customCSS?: FlattenSimpleInterpolation;
}

const Modal = ({
  children,
  outsideClickCallback,
  width,
  height,
  padding,
  customCSS,
}: IModalProps): JSX.Element => {
  const modalWrapperDom = useRef<HTMLDivElement>(null);

  useOutsideAlerter(modalWrapperDom, () => {
    if (outsideClickCallback) outsideClickCallback();
  });

  return (
    <ModalBackground>
      <ModalBlackCurtain />
      <ModalWrapper
        extraCSS={getModalCSS({
          width,
          height,
          padding,
        })}
        customCSS={customCSS}
        ref={modalWrapperDom}
      >
        {children}
      </ModalWrapper>
    </ModalBackground>
  );
};

const ModalBackground = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999999;
  flex-direction: column;
`;
const ModalBlackCurtain = styled.div`
  cursor: pointer;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -10000;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
`;

const ModalWrapper = styled.div<{
  extraCSS: FlattenSimpleInterpolation;
  customCSS?: FlattenSimpleInterpolation;
}>`
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  ${(props) => props.extraCSS}
  box-sizing: border-box;
  position: relative;
  ${(props) => props.customCSS ?? css``}
  overflow: hidden;
`;

const getModalCSS = (props: ISizeProps) => css`
  ${props.width
    ? css`
        width: ${getSize(props.width)};
      `
    : css`
        width: fit-content;
      `}

  ${props.height
    ? css`
        height: ${getSize(props.height)};
      `
    : css`
        height: fit-content;
      `}

      ${props.padding &&
  css`
    padding: ${getSize(props.padding)};
  `}
`;

const getSize = (value: number | string | undefined) => {
  if (typeof value === "number") return `${value}px`;
  const isNumberic = !isNaN(value as unknown as number);
  if (isNumberic) return `${value}px`;
  return value;
};

export default Modal;

const HStack = styled.div`
  display: flex;
  flex-direction: row;
`;

const VStack = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h1`
  ${text({
    weight: "bold",
    size: 18,
    color: "gray05",
  })}
  margin: 0px;
  line-height: 100%;
  letter-spacing: -0.5px;
`;

const SubTitle = styled.p`
  ${text({
    weight: "regular",
    size: 12,
    color: "gray05",
  })}
  margin-top: 8px;
  line-height: 100%;
  letter-spacing: -0.5px;
`;

Modal.HStack = HStack;
Modal.VStack = VStack;
Modal.Title = Title;
Modal.SubTitle = SubTitle;
