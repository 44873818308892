import dayjs from "dayjs";

export const replaceUnderbarWithSpace = (name: string) =>
  name.replaceAll("_", " ");

export const sortByString = (a: string, b: string): 1 | -1 | 0 => {
  if (a > b) return 1;
  if (a < b) return -1;
  return 0;
};

export const convertTimestampToDate = (timestamp: string) =>
  dayjs(new Date(Number(timestamp) * 1000)).format("YYYY년 MM월");

export const getTargetColors = (
  colors: string[],
  targetIndex: number
): string => {
  if (colors.length === 0) return "";

  if (targetIndex > colors.length - 1) {
    return getTargetColors([...colors, ...colors], targetIndex);
  }

  return colors[targetIndex];
};

export const convertStringNumberToNumber = (stringNumber: string) => {
  return parseInt(stringNumber.replaceAll(",", ""));
};
