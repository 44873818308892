import { css } from 'styled-components';

export const END_COLUMN_WIDTH = css`
  :nth-last-child(1) {
    min-width: none !important;
    max-width: none !important;
    flex: 1;
    border-right: 1px solid ${({ theme }) => theme.colors.gray01};
  }
`;

export const getCellWidth = (length?: number) => {
  if (!length) return css``;
  if (length < 7) {
    return css`
      min-width: 125px;
      max-width: 125px;
    `;
  }
  if (length < 12) {
    return css`
      min-width: 182px;
      max-width: 182px;
    `;
  }
  return css`
    min-width: 252px;
    max-width: 252px;
  `;
};
