import React from "react";
import styled from "styled-components";
import OrganizationDiagnosisReport from "./OrganizationDiagnosisReport";
import Participation from "./Participation";
import Trand from "./Trand";

const DashBoard = () => {
  return (
    <Container>
      <DashBoardWrapper>
        <Trand />
        <Participation />
        <OrganizationDiagnosisReport />
      </DashBoardWrapper>
    </Container>
  );
};

export default DashBoard;

const Container = styled.div`
  ${({ theme }) => theme.flexSets.flexColumn}
  align-items: center;
  width: 100vw;

  padding-top: 92px;
  padding-bottom: 92px;
`;

const DashBoardWrapper = styled.div`
  width: 720px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
