import React from "react";
import styled from "styled-components";
import Header from "../../../Components/Header";
import Column from "../../../Components/Table/Column";
import Rows from "../../../Components/Table/Rows";

const FinishReport = () => {
  const column = ["참여 여부", "회차", "참여 완료일"];
  const rows = [
    ["참여 완료", "1회차", "2021년 12월 04일 (공휴일)"],
    ["참여 완료", "2회차", "2021년 12월 10일 (금요일)"],
    ["참여 완료", "3회차", "2021년 12월 20일 (월요일)"],
    ["참여 완료", "4회차", "2021년 12월 28일 (화요일)"],
    ["참여 완료", "5회차", "2022년 01월 03일 (월요일)"],
    ["참여 완료", "6회차", "2022년 01월 07일 (금요일)"],
    ["참여 완료", "7회차", "2022년 01월 15일 (공휴일)"],
    ["참여 완료", "8회차", "2022년 01월 26일 (수요일)"],
    ["참여 완료", "9회차", "2022년 02월 02일 (수요일)"],
    ["참여 완료", "10회차", "2022년 02월 05일 (공휴일)"],
    ["참여 완료", "11회차", "2022년 02월 11일 (금요일)"],
  ];
  return (
    <FinishReportWrapper>
      <Header title="11회 설문 중, 11회 참여를 완료했어요" />
      <div>
        <Column column={column} type="personalReport" />
        <Rows rows={rows} type="personalReport" />
      </div>
    </FinishReportWrapper>
  );
};

export default FinishReport;

export const FinishReportWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px 32px 64px 32px;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.gray01};
  border-radius: 3px;
`;
