const data = [
  {
    Good: 52,
    Neutral: 22,
    Bad: 26,
    NPS: 26,
  },
  {
    Good: 64,
    Neutral: 10,
    Bad: 26,
    NPS: 38,
  },
  {
    Good: 58,
    Neutral: 32,
    Bad: 10,
    NPS: 48,
  },
  {
    Good: 54,
    Neutral: 30,
    Bad: 16,
    NPS: 38,
  },
  {
    Good: 52,
    Neutral: 31,
    Bad: 17,
    NPS: 35,
  },
  {
    Good: 68,
    Neutral: 8,
    Bad: 24,
    NPS: 44,
  },
  {
    Good: 65,
    Neutral: 22,
    Bad: 13,
    NPS: 52,
  },
  {
    Good: 55,
    Neutral: 23,
    Bad: 22,
    NPS: 33,
  },
  {
    Good: 48,
    Neutral: 40,
    Bad: 12,
    NPS: 36,
  },
  {
    Good: 55,
    Neutral: 33,
    Bad: 12,
    NPS: 43,
  },
  {
    Good: 54,
    Neutral: 38,
    Bad: 8,
    NPS: 46,
  },
  {
    Good: 48,
    Neutral: 32,
    Bad: 20,
    NPS: 28,
  },
];

export const monthTrendOption = {
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "shadow",
    },
  },
  legend: {
    itemWidth: 9,
    itemHeight: 9,
    bottom: 16,
    icon: "path:// M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0",
  },
  grid: {
    top: 18,
    bottom: 52,
    left: 13,
    right: 12,
    containLabel: true,
  },
  yAxis: [
    {
      type: "value",
      min: 0,
      max: 100,
      interval: 10,
    },
    {
      type: "value",
      min: -100,
      max: 100,
      interval: 100,
    },
  ],
  xAxis: {
    type: "category",
    axisTick: {
      show: false,
    },
    data: Array(12)
      .fill(null)
      .map((_, i) => `2020년 ${i + 1}월`),
  },
  series: [
    {
      name: "비추천",
      type: "bar",
      stack: "total",
      yAxisIndex: 0,
      emphasis: {
        focus: "series",
      },
      color: "#ffa093",
      data: data.map((item) => item.Bad),
      barWidth: 20,
    },
    {
      name: "중립",
      type: "bar",
      stack: "total",
      yAxisIndex: 0,
      emphasis: {
        focus: "series",
      },
      color: "#ffedc8",
      data: data.map((item) => item.Neutral),
      barWidth: 20,
    },
    {
      name: "추천",
      type: "bar",
      stack: "total",
      yAxisIndex: 0,
      emphasis: {
        focus: "series",
      },
      color: "#89d8b2",
      data: data.map((item) => item.Good),
      barWidth: 20,
    },
    {
      name: "NPS",
      type: "line",
      stack: "Total",
      data: data.map((item) => item.NPS),
      color: "#2b2e33",
      yAxisIndex: 1,
    },
  ],
};
