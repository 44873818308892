import {
  Chart,
  CheckBox2,
  Dropdown2,
} from "@earlysloth/pocketsurvey-ui-components";
import { useMediaQuery } from "react-responsive";
import EChartsReact from "echarts-for-react";
import React, { useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import ComponentLoading from "../../../EpisodeReport/components/ComponentLoading";
import text from "../../../font";
import HRDashboardLayout from "../../styles";
import withAsyncError from "../WithAsyncError/WithAsyncError";
import useChartDataSelection from "./hooks/useChartDataSelection";
import useTrendItem from "./hooks/useTrendItem";

const getEchartOption = (data: number[], data2: number[], isIframe = false) => {
  return {
    series: [
      {
        name: "중요도",
        data: data,
        type: "line",
        itemStyle: {
          color: "#ff7078",
        },
      },
      {
        name: "만족도",
        data: data2,
        type: "line",
        itemStyle: {
          color: "#23C062",
        },
      },
    ],
    xAxis: {
      type: "category",
      data: ["1회차", "2회차", "3회차"],
      axisLine: {
        lineStyle: {
          color: "#818282",
        },
      },
    },
    yAxis: {
      type: "value",
      //   min: 0,
      //   max: 100,
      axisLabel: {
        formatter: "{value}%",
      },
      axisLine: {
        lineStyle: {
          color: "#C9C8C7",
        },
      },
    },
    grid: {
      top: "20%",
      width: isIframe ? 725 : 1090,
      left: "4%",
    },
    legend: {
      itemWidth: 9,
      itemHeight: 9,
      bottom: 10,

      icon: "path:// M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0",
    },
    tooltip: {
      trigger: "axis",
    },
  };
};

const TrendItemChart = () => {
  const { hideList, setHideItem } = useChartDataSelection();
  const chartData = useTrendItem();
  const isIFrame = useMediaQuery({ query: "(max-width:1100px)" });
  if (chartData === undefined) return <></>;

  const keys = Object.keys(chartData);

  return (
    <HRDashboardLayout.BlockWrapper height={570}>
      <HRDashboardLayout.Title>요소별 트렌드</HRDashboardLayout.Title>
      <Desc>요소를 선택해서 각 요소별 트렌드 차트를 확인할 수 있습니다.</Desc>
      <DropdownWrapper>
        <Dropdown2
          value="요소 선택"
          extraCSS={css`
            width: fit-content;
          `}
        >
          <Dropdown2.Group>
            {keys.map((key) => (
              <Selection
                onClick={() => {
                  setHideItem(key);
                }}
              >
                <SelectionWrapper>
                  <div>
                    <CheckBox2 isSelected={hideList.includes(key) === false} />
                  </div>
                  {key}
                </SelectionWrapper>
              </Selection>
            ))}
          </Dropdown2.Group>
        </Dropdown2>
      </DropdownWrapper>
      <LoopWrapper>
        {keys.map((key) => {
          if (hideList.includes(key)) return <></>;

          const rawData = chartData[key];
          return (
            <ChartBox>
              <p>{key}</p>
              {isIFrame ? (
                <EChartsReact
                  option={getEchartOption(
                    rawData.map((v) => v.importance),
                    rawData.map((v) => v.satisfaction),
                    true
                  )}
                  style={{
                    width: "750px",
                    height: "300px",
                    margin: "0 auto",
                  }}
                />
              ) : (
                <EChartsReact
                  option={getEchartOption(
                    rawData.map((v) => v.importance),
                    rawData.map((v) => v.satisfaction)
                  )}
                  style={{
                    width: "1175px",
                    height: "300px",
                    margin: "0 auto",
                  }}
                />
              )}
            </ChartBox>
          );
        })}
      </LoopWrapper>
    </HRDashboardLayout.BlockWrapper>
  );
};

export default withAsyncError(
  TrendItemChart,
  <ComponentLoading width="100%" height="360px" backgroundColor="#ffffff" />
);

const ChartBox = styled.div`
  height: fit-content;
`;

const SelectionWrapper = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;
  gap: 7px;
`;

const Selection = styled.div`
  font-family: "Spoqa Han Sans Neo Regular";
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 32px;
  font-size: 14px;

  &:hover {
    background-color: rgb(240, 240, 240);
  }
`;

const DropdownWrapper = styled.div`
  /* div:nth-child(1) {
    width: fit-content; */
  /* } */
`;

const Desc = styled.p`
  ${text({
    weight: "medium",
    color: "gray04",
    size: 14,
  })}
  margin-bottom: 20px;
`;

const LoopWrapper = styled.div`
  margin-top: 30px;
`;
