import { EChartsOption } from "echarts";

export type ColorType =
  | "#23C062"
  | "#ff7078"
  | "#8d58ff"
  | "#ffcf42"
  | "#494fe2"
  | "#ffab2e"
  | "#59c4db"
  | "#2982f2"
  | "#41b0a3"
  | "#c9c8c7";

class ChartUtil {
  protected readonly CHART_COLOR: ColorType[] = [
    "#ff7078",
    "#23C062",
    "#8d58ff",
    "#ffcf42",
    "#494fe2",
    "#ffab2e",
    "#59c4db",
    "#2982f2",
    "#41b0a3",
    "#c9c8c7",
  ];

  public readonly etcColor: "#c9c8c7" = "#c9c8c7";

  protected getColorList(len: number): ColorType[] {
    const dup = Math.floor(len / this.CHART_COLOR.length);
    const sliceRange = len % this.CHART_COLOR.length;

    return new Array(dup)
      .fill(null)
      .map((_) => this.CHART_COLOR)
      .flat()
      .concat(this.CHART_COLOR.slice(0, sliceRange));
  }

  public getQuestionColorList(): ColorType[] {
    return this.CHART_COLOR.slice(0, 4).concat("#c9c8c7");
  }

  protected getXAxisData(valueLength: number): string[] {
    return Array(valueLength)
      .fill(null)
      .map((_, index) => `${index + 1}회차`);
  }
}

export default ChartUtil;
