import axios from "axios";
import React from "react";
import { useQuery } from "react-query";

const useTrendItem = () => {
  const { data } = useQuery(["hr", "trend", "item"], () => getTrendItemData(), {
    suspense: true,
    staleTime: 1000 * 60 * 60 * 24, //한번만 데이터를 받아옴
  });

  return data?.data;
};

export default useTrendItem;

const getTrendItemData = () =>
  axios.get<{
    [key: string]: { importance: number; satisfaction: number }[];
  }>(
    `https://s3.ap-northeast-2.amazonaws.com/pocketsurvey.demo.pulsesurvey-report-viewer/2a97516c/trend-line.json`
  );
