

import React from 'react';
import styled from 'styled-components';
import text from '../../../../font';
import { factorTagType } from '../../../types';
import { FACTOR_TAG } from './constants';
import { cssUtil } from './util';

interface IFactorTag {
  factorTagType: factorTagType;
}

const FactorTag = ({ factorTagType }: IFactorTag): JSX.Element => {
  return (
    <FactorTagWrapper factorTagType={factorTagType}>
      <Value>{FACTOR_TAG.name.get(factorTagType)}</Value>
    </FactorTagWrapper>
  );
};

export default FactorTag;

const FactorTagWrapper = styled.div<{
  factorTagType: factorTagType;
}>`
  ${text({
    size: 12,
    weight: 'regular',
  })}
  color:${cssUtil.getText};
  letter-spacing: -0.5px;
  border-radius: 3px;
  border: 1px solid ${cssUtil.getBorderColor};
  background-color: ${cssUtil.getBackgroundColor};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 61px;
  height: 24px;
  box-sizing: border-box;
`;
const Value = styled.p``;
