import React from "react";

import ComponentLoading from "../EpisodeReport/components/ComponentLoading";

import withAsyncError from "../HRDashboard/components/WithAsyncError/WithAsyncError";
import ClientLabel from "./components/ClientLabel";
import TrendReportChart from "./components/TrendReportChart";
import TrendReportSuggestion from "./components/TrendReportSuggestion";
import TrendReportTable from "./components/TrendReportTable";

import useTrendReportDataQuery from "./hooks/query/useTrendReportDataQuery";
import { isMixedChart, isSuggestion, isTextTable } from "./types/types";

import { reorderDataByChart } from "./utils/reorderDataByChart";

const TrendReport = () => {
  const { data: rawData, suggestions, isLoading } = useTrendReportDataQuery();

  if (isLoading || rawData.length === 0) return <></>;

  const { reorderedData, colors } = reorderDataByChart(
    rawData,
    suggestions ?? []
  );

  return (
    <div className="min-h-screen flex justify-center">
      <div className="max-w-[1200px]">
        <ClientLabel />
        {reorderedData.map((item, index) => {
          if (isMixedChart(item)) {
            return (
              <TrendReportChart key={index} chartData={item} colors={colors} />
            );
          }

          if (isTextTable(item)) {
            return <TrendReportTable key={index} data={item} />;
          }

          if (isSuggestion(item)) {
            const { data, label } = item[0];

            return (
              <TrendReportSuggestion key={index} label={label} data={data} />
            );
          }

          return <React.Fragment key={index}></React.Fragment>;
        })}
      </div>
    </div>
  );
};

export default withAsyncError(
  TrendReport,
  <ComponentLoading width="100%" height="360px" backgroundColor="#ffffff" />
);
