import { z } from "zod";

import {
  TrendReportDataSchema,
  TrendReportSuggestionsSchema,
} from "../utils/trendReportDataSchema";

import { CHART_TYPES } from "../utils/constants";

export type UnArray<T> = T extends Array<infer R> ? R : never;

export type TrendReportUrlParams = {
  clientLabel: string;
  companyLabel: string;
};

export type TrendReportDataType = z.infer<typeof TrendReportDataSchema>;

export type TrendReportDataItemType = UnArray<UnArray<TrendReportDataType>>;

type TrendReportChartTypes = typeof CHART_TYPES[keyof typeof CHART_TYPES];

export interface TrendReportMixedChart {
  type: "mixed";
  label: string;
  data: {
    [key: string]: {
      value: string;
      chart: TrendReportChartTypes;
    };
  };
  date: `${number}`;
}

export const isMixedChart = (
  data:
    | TrendReportMixedChart[]
    | TrendReportTextTable[]
    | TrendReportSuggestionComponent[]
): data is TrendReportMixedChart[] => data[0].type === "mixed";

export const isTextTable = (
  data:
    | TrendReportMixedChart[]
    | TrendReportTextTable[]
    | TrendReportSuggestionComponent[]
): data is TrendReportTextTable[] => data[0].type === "text";

export const isSuggestion = (
  data:
    | TrendReportMixedChart[]
    | TrendReportTextTable[]
    | TrendReportSuggestionComponent[]
): data is TrendReportSuggestionComponent[] => data[0].type === "suggestion";

export interface TrendReportTextTable {
  type: "text";
  label: string;
  data: string;
  date: `${number}`;
}

export interface TrendReportSuggestionComponent {
  type: "suggestion";
  label: string;
  data: string;
}

export type ReorderedTrendReportDataItem = {
  id: string;
  colors: string[];
  reorderedData: (
    | TrendReportMixedChart[]
    | TrendReportTextTable[]
    | TrendReportSuggestionComponent[]
  )[];
};

export type RemappedChartData = {
  date: `${number}`;
  name: string;
  value: string;
  chart: TrendReportChartTypes;
};

export type TrendReportSuggestion = UnArray<
  z.infer<typeof TrendReportSuggestionsSchema>
>;
