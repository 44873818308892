import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Button, Icon, Input } from '@earlysloth/pocketsurvey-ui-components';
import { cloneDeep } from 'lodash';
import useOutsideAlerter from '../../useOutsideAlerter';
import { ColumnFilterPropertiesType } from '../types';



const FilterPanel = styled.div`
  position: absolute;
  padding: 14px;
  box-sizing: border-box;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 2px 5px 4px rgba(0, 0, 0, 0.05);
  width: 280px;
  z-index: 20;
  .add-filter-tag-input {
    div {
      padding: 7px 14px;
      box-sizing: border-box;
    }
    font-family: 'Spoqa Han Sans Neo Regular';
  }
  left: -90px;
  top: 30px;
`;
const FilterTagList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 14px;
  margin-bottom: 15px;
`;
const FilterTagItem = styled.div`
  display: flex;
  align-items: center;
  font-family: 'Spoqa Han Sans Neo Regular';
  padding: 7px 14px;
  border: 1px solid #dfdedd;
  border-radius: 30px;
  width: fit-content;
  margin: 3.5px;
  .delete-tag {
    margin-left: 7px;
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
`;
const FitlerResetButtonContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const FilterResetText = styled.p`
  font-family: 'Spoqa Han Sans Neo Regular';
  color: #818282;
`;
const CheckCancelButtonContainer = styled.div`
  display: flex;
  align-items: center;
  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px 14px;
    width: 54px;
    height: 34px;
    box-sizing: border-box;
    font-family: 'Spoqa Han Sans Neo Regular';
    :nth-child(1) {
      color: #818282;
      margin-right: 4px;
    }
    :nth-child(2) {
      color: #2b2e33;
    }
  }
`;

function ShorttextColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
  setfilterIsVisible,
}: ColumnFilterPropertiesType) {
  const filterRef = useRef<HTMLDivElement>(null);
  const [newFilterValue, setNewFilterValue] = React.useState<string>('');
  const [filterList, setFilterList] = React.useState<Set<string>>(
    new Set(filterValue) || new Set(),
  );

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (newFilterValue) {
      setFilterList(filterList.add(newFilterValue));
      setNewFilterValue('');
    }
  };

  const deleteTag = (tag: string) => {
    const newFilterList = cloneDeep(filterList);
    newFilterList.delete(tag);
    setFilterList(newFilterList);
  };

  useOutsideAlerter(filterRef, () => {
    setfilterIsVisible(false);
  });

  return (
    <FilterPanel ref={filterRef}>
      <form onSubmit={handleSubmit}>
        <Input
          className="add-filter-tag-input"
          mode="basic"
          value={newFilterValue}
          onChange={(value) => setNewFilterValue(value)}
          placeholder="키워드를 입력해주세요."
          buttonAlways
          iconButton={newFilterValue ? 'exit' : 'search'}
          onClickCancelButton={() => setNewFilterValue('')}
          width={250}
          isError={false}
          errorMessage=""
          borderColor="#fac62d"
        />
      </form>
      {filterList.size > 0 && (
        <FilterTagList>
          {Array.from(filterList).map((item) => (
            <FilterTagItem>
              {item}
              <Icon
                className="delete-tag"
                icon="exit"
                width={10}
                color="#818282"
                onClick={() => deleteTag(item)}
                useCursor
              />
            </FilterTagItem>
          ))}
        </FilterTagList>
      )}
      <ButtonContainer>
        <FitlerResetButtonContainer
          onClick={() => {
            setFilter(undefined);
            setfilterIsVisible(false);
          }}
        >
          <Icon icon="reset" width={20} color="#818282" useCursor />
          <FilterResetText>초기화</FilterResetText>
        </FitlerResetButtonContainer>
        <CheckCancelButtonContainer>
          <Button
            className="button"
            shape="square"
            size="medium"
            mode="White"
            disabled={false}
            onClick={() => {
              setfilterIsVisible(false);
            }}
          >
            취소
          </Button>
          <Button
            shape="square"
            size="medium"
            className="button"
            mode="Yellow"
            disabled={false}
            onClick={() => {
              if (!newFilterValue) {
                const isEmptyFilterList = Array.from(filterList).length === 0;
                setFilter(
                  isEmptyFilterList ? undefined : Array.from(filterList),
                );
                setfilterIsVisible(false);
              }
              if (newFilterValue) {
                setFilterList(new Set());
                setFilterList(filterList.add(newFilterValue));
                setFilter([newFilterValue]);
                setNewFilterValue('');
                setfilterIsVisible(false);
              }
            }}
          >
            적용
          </Button>
        </CheckCancelButtonContainer>
      </ButtonContainer>
    </FilterPanel>
  );
}

export default ShorttextColumnFilter;
