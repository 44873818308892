import styled from "styled-components";
import QuickPulse from "./components/QuickPulse/QuickPulse";
import TrendIndex from "./components/TrendIndex/TrendIndex";
import TrendItemChart from "./components/TrendItemChart/TrendItemChart";
import ReportSummary from "./ReportSummary/ReportSummary";

const HRDashBoard = () => {
  return (
    <HRDashBoardWrapper>
      <TrendIndex />
      <TrendItemChart />
      <QuickPulse />
      <ReportSummary />
    </HRDashBoardWrapper>
  );
};

export default HRDashBoard;

const HRDashBoardWrapper = styled.div`
  padding: 53px;
`;
