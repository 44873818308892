import React from "react";
import styled from "styled-components";

import ReportTitle from "../../Components/ReportTitle";
import ReportDescription from "./ReportDescription";
import ReportGraph from "./ReportGraph";
import ReportFinalResult from "./ReportFinalResult";

const DiagnosisReport = () => {
  return (
    <Container>
      <DiagnosisReportWrapper>
        <ReportTitle
          firstTitle={"진단 보고서"}
          secondTitle={"회사 복지 만족도"}
          thirdTitle={"직원 내부 만족도 조사 2022년 1분기 선호도 조사 프로젝트"}
        />
        <ReportDescription />
        <ReportGraph />
        <ReportFinalResult />
      </DiagnosisReportWrapper>
    </Container>
  );
};

export default DiagnosisReport;

export const Container = styled.div`
  ${({ theme }) => theme.flexSets.flexColumn}
  align-items: center;
  width: 100vw;
  padding-top: 92px;
  padding-bottom: 92px;
`;

export const DiagnosisReportWrapper = styled.div`
  width: 720px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
