import { stat } from "fs";
import create from "zustand";
import { combine } from "zustand/middleware";

interface IChartDataSelection {
  hideList: string[];
}

const initState: IChartDataSelection = {
  hideList: [
    "업무와 일상의 밸런스",
    "나의 건강",
    "업무 자율성",
    "업무 성취감",
    "동료의 지지",
    "리더의 지지",
    "동료와의 친목 교류",
    "회사의 성장",
    "나의 성장",
  ],
};

const useChartDataSelection = create(
  combine(initState, (set, get) => ({
    setHideItem: (payload: string) => {
      set((state) => {
        if (state.hideList.includes(payload)) {
          state.hideList = state.hideList.filter((item) => item !== payload);
        } else {
          state.hideList = state.hideList.concat(payload);
        }
      });
    },
  }))
);

export default useChartDataSelection;
