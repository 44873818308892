import React, { useMemo } from "react";
import { css } from "styled-components";
import ShorttextTable from "./ShorttextTable/TableContainer";

import { PrintDisplayNone, PrintSection } from "../EpisodeReport";
import useShorttextData from "./hooks/useShorttextData";
import HRDashboardLayout from "./ShorttextTable/styles";
import { useParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

const ShorttextAnswer = () => {
  const params = useParams<{ ID: string; projectID: string }>();
  const rawShorttextData = useShorttextData(Number(params.ID));

  const count = useMemo(() => {
    const data = rawShorttextData;

    return data?.data.length;
  }, [rawShorttextData]);

  const isIframe = useMediaQuery({
    query: "(max-width:1100px)",
  });

  return (
    <PrintSection>
      <HRDashboardLayout.BlockWrapper
        css={css`
          margin-top: 24px;

          @media print {
            width: 1145px;
          }
        `}
        isIframe={isIframe}
      >
        <HRDashboardLayout.Title>주관식 문항 답변 결과</HRDashboardLayout.Title>
        <HRDashboardLayout.Divider isOverflow />

        <HRDashboardLayout.MiddleTitle
          css={css`
            margin-bottom: 16px;
          `}
        >
          조직에게 새로운 의견이나 궁금한 점이 있으면 이야기해주세요.
        </HRDashboardLayout.MiddleTitle>
        <HRDashboardLayout.MiddleTitle
          css={css`
            margin-bottom: 24px;
          `}
        >
          응답 수 {count}건
        </HRDashboardLayout.MiddleTitle>
        <PrintDisplayNone>
          <HRDashboardLayout.Divider
            css={css`
              margin-top: 0px;
              @media print {
                display: none;
              }
            `}
          />
        </PrintDisplayNone>

        <ShorttextTable />
      </HRDashboardLayout.BlockWrapper>
    </PrintSection>
  );
};

export default ShorttextAnswer;
