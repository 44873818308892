import React, { useState } from "react";
import axios from "axios";
import { useQuery } from "react-query";
import useEpisodeReportData from "./useEpisodeReportData";

const useRawData = (id: number) => {
  const { data } = useQuery(["hr", "result", id], () => getResultData(id), {
    suspense: true,
    staleTime: 1000 * 60 * 60 * 24, //한번만 데이터를 받아옴
    onSuccess: (res) => {
      useEpisodeReportData.getState().setRawData(res.data);
    },
  });

  return [data?.data] as unknown as RawEpisodeReportType;
};

export default useRawData;

const getResultData = (id: number) =>
  axios.get<RawEpisodeReportType>(
    `https://s3.ap-northeast-2.amazonaws.com/pocketsurvey.demo.pulsesurvey-report-viewer/2a97516c/${id}_seg_result.json`
  );

export type UnArray<T> = T extends Promise<infer U> ? U : T;

export type RawEpisodeReportType = {
  [key: string]: {
    [key: string]: EpisodeReportType;
  };
}[];

export type EpisodeReportType = {
  engagement: {
    graph: {
      score: number;
      data: { label: string; percent: string; count: number }[];
    };
    table: { rank: number; label: string; preference: number }[];
    IPA: {
      label: string;
      table: {
        importance: number;
        satisfaction: number;
        isPositive: boolean;
      };
      graph: {
        importance: number;
        satisfaction: number;
      };
    }[];
  };
};
