export const HeaderCellWidth = new Map([
  ['번호', '96px'],
  ['하위 요소', '322px'],
  ['요소 선호도', '258px'],
]);


export const HeaderCellWidthIframe = new Map([
  ['번호', '96px'],
  ['하위 요소', '200px'],
  ['요소 선호도', '180px'],
]);