import { css } from "@emotion/css";
import EChartsReact from "echarts-for-react";
import React from "react";
import styled from "styled-components";
import Description from "../../Components/Description";
import Header from "../../Components/Header";

import ReportTitle from "../../Components/ReportTitle";
import Tag from "../../Components/Tag";
import {
  Container,
  DiagnosisReportWrapper,
} from "../DiagnosisReport/DiagnosisReport";
import {
  ReportDescriptionWrapper,
  StrongText,
} from "../DiagnosisReport/ReportDescription/ReportDescription";
import GrowthRateChart from "./GrowthRateChart";
import RunwayChart from "./RunwayChart";
import VCTable from "./VCTable";

const Trend = () => {
  return (
    <>
      <Container>
        <DiagnosisReportWrapper>
          {/* <ReportTitle
            firstTitle={"진단 보고서"}
            secondTitle={"회사 복지 만족도"}
            thirdTitle={
              "직원 내부 만족도 조사 2022년 1분기 선호도 조사 프로젝트"
            }
          /> */}
          <ReportDescriptionWrapper>
            <Header title={"얼리슬로스"} subTitle={"매출 및 매출 성장률"} />
            <GrowthRateChart />
            <SubTitleValue>예상 현금 보유 및 런웨이 추이</SubTitleValue>
            <RunwayChart />
            <SubTitleValue>포트폴리오 요청사항</SubTitleValue>
            <Description descriptions={earlyslothDescriptions} />
          </ReportDescriptionWrapper>
        </DiagnosisReportWrapper>
      </Container>
    </>
  );
};

export const SubTitleValue = styled.p`
  color: #818282;
  font-size: 16px;
  font-family: "Spoqa Han Sans Neo Medium";
  letter-spacing: -0.5px;
`;

export default Trend;

const earlyslothDescriptions = (
  <>
    <StrongText>2022년 3월</StrongText>
    <li>
      • 시리즈 A 투자 유치 진행 중입니다. 투자 밸류는 Pre 300억 원이고, 50억
      펀드레이징 진행하려고 합니다. 투자 의향 있으신지 확인 부탁드립니다.
    </li>
    <li>
      • 3월 셋째 주 금요일에 주주총회 예정입니다.
      <br />
      &nbsp; &nbsp; <a href="#">정기주주총회 의결서</a>를 확인하시고, 참여 여부
      및 각 의안 동의 여부 전달 부탁드립니다.
    </li>
    <div
      className={css`
        width: 111px;
        margin-top: 10px;
      `}
    >
    <Tag name={"정기주주총회 의결서"} />
    </div>
    <br />

    <StrongText>2022년 2월</StrongText>

    <li>
      • 신규 사업 모델을 시작했습니다. <a href="#">서비스 소개 자료</a>를
      확인해보시고, 주변에 소개 부탁드립니다.{" "}
    </li>
    <div
      className={css`
        width: 93px;
        margin-top: 10px;
      `}
    >
    <Tag name={"서비스 소개 자료"} />
    </div>
  </>
);
