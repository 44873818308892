import React, { useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import text from "../../../../font";
import zip from "lodash/zip";
import useTableTooltip from "../../hooks/useTableTooltip";
import useOutsideAlerter from "../../../../useOutsideAlerter";

const headers = ["매우 만족", "만족", "보통", "불만족", "매우 불만족"];

const TableTooltip = () => {
  const { position, selected, resetPosition, row } = useTableTooltip();
  const hasPosition = !!position;
  const wrapperDom = useRef<HTMLDivElement>(null);

  const closeTooltip = () => {
    resetPosition();
  };

  useEffect(() => {
    if (wrapperDom.current) {
      wrapperDom.current.addEventListener("mouseleave", closeTooltip);
    }
    return () => {
      wrapperDom.current?.removeEventListener("mouseleave", closeTooltip);
    };
  }, [closeTooltip]);

  return (
    <>
      <TooltipWrapper
        show={hasPosition}
        x={position?.x}
        y={position?.y}
        ref={wrapperDom}
      >
        <TooltipTitle>
          <b>{row}</b> 요소의 만족도에 대한 조직원의 생각을 자세히 확인해보세요.
          <br />
          평가 수가 적으면 점수에 왜곡이 있을 수 있어요.
          <br />
          5명 미만이 평가한 요소의 만족도를 확인할 때는 주의해주세요.
        </TooltipTitle>
        <TableStyles.Wrapper>
          <TableStyles.TBody>
            <tr>
              <TableStyles.Head
                width={146}
                borderRadius={"3px 0px 0px 0px"}
              ></TableStyles.Head>
              <TableStyles.Head width={98}>평가수</TableStyles.Head>
              <TableStyles.Head width={171} borderRadius={"0px 3px 0px 0px"}>
                요소의 평균 순위
              </TableStyles.Head>
            </tr>

            {zip(headers, selected[0], selected[1]).map((item, index) => {
              const isEven = index % 2 === 0;
              return (
                <tr>
                  <TableStyles.Cell isBold isEven={isEven}>
                    {item[0]}
                  </TableStyles.Cell>
                  <TableStyles.Cell isEven={isEven}>{item[1]}</TableStyles.Cell>
                  <TableStyles.Cell isEven={isEven}>{item[2]}</TableStyles.Cell>
                </tr>
              );
            })}
          </TableStyles.TBody>
        </TableStyles.Wrapper>
      </TooltipWrapper>
    </>
  );
};

const TableStyles = {
  Wrapper: styled.table``,
  Head: styled.td<{
    width: number;
    borderRadius?: string;
  }>`
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 16px;
    background-color: #f9fafb;
    ${text({
      weight: "medium",
      size: 12,
      color: "gray04",
    })}
    ${(props) =>
      props.width &&
      css`
        width: ${props.width}px;
      `}
    ${(props) =>
      props.borderRadius &&
      css`
        border-radius: ${props.borderRadius};
      `}
  `,
  Cell: styled.td<{
    isBold?: boolean;
    isEven: boolean;
  }>`
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 16px;
    background-color: #f9fafb;
    ${text({
      weight: "regular",
      size: 12,
      color: "gray05",
    })}

    ${(props) =>
      props.isBold &&
      css`
        ${text({
          weight: "bold",
        })}
      `}

    ${(props) =>
      props.isEven &&
      css`
        background-color: #ffffff;
      `}
  `,
  TBody: styled.tbody`
    border-bottom: 1px solid #f9fafb;
  `,
};

const TooltipWrapper = styled.div<{ show: boolean; x?: number; y?: number }>`
  ${(props) =>
    props.show === false &&
    css`
      visibility: hidden;
    `}
  background: #ffffff;
  border: 1px solid #dfdedd;
  box-sizing: border-box;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  display: flex;
  flex-direction: column;

  width: 463px;
  padding: 24px;
  gap: 24px;

  position: absolute;
  z-index: 9999;

  top: ${(props) => props.y}px;
  left: ${(props) => props.x}px;
`;

const TooltipTitle = styled.p`
  ${text({
    weight: "regular",
    size: 14,
    color: "gray05",
  })}

  line-height: 130%;

  b {
    ${text({
      weight: "bold",
    })}
  }
`;

export default TableTooltip;
