import { useQuery } from "react-query";
import useGetUrlParams from "../useGetUrlParams";

import trendReportServices from "../../services/trendReportServices";

import { QUERY_KEYS } from "../../utils/constants";
import { TrendReportDataType, UnArray } from "../../types/types";

const useTrendReportDataQuery = () => {
  const { clientLabel, companyLabel } = useGetUrlParams();
  const { data: sheetLists, isFetching: isSheetListsLoading } = useQuery(
    [QUERY_KEYS.trendReportSheetList],
    trendReportServices.getTrendReportSheetList,
    {
      staleTime: Infinity,
    }
  );

  const sheetLink = sheetLists?.slice(1).find(([client]) => {
    return client === clientLabel;
  })?.[1];

  const { data: trendReportData, isFetching: isTrendReportDataLoading } =
    useQuery(
      [QUERY_KEYS.trendReportData],
      () => trendReportServices.getTrendReportData(sheetLink),
      {
        staleTime: Infinity,
        enabled: !!sheetLink,
      }
    );

  const companyTrendReportData: UnArray<TrendReportDataType> =
    trendReportData
      ?.filter((data) => data[0].id === companyLabel)
      .map((data) => data[0]) ?? [];

  const suggestionSheetLink = sheetLists?.slice(1).find(([client]) => {
    return client === clientLabel;
  })?.[4];

  const {
    data: trendReportSuggestions,
    isFetching: isTrendReportSuggestionLoading,
  } = useQuery(
    [QUERY_KEYS.trendReportSuggestion],
    () =>
      trendReportServices.getTrendReportSuggestions(
        suggestionSheetLink ?? "",
        companyLabel || ""
      ),
    {
      staleTime: Infinity,
      enabled:
        !!sheetLists && companyLabel !== undefined && !!suggestionSheetLink,
    }
  );

  return {
    data: companyTrendReportData,
    suggestions: trendReportSuggestions?.filter(
      (item) => item.id === companyLabel
    ),
    isLoading:
      isSheetListsLoading ||
      isTrendReportDataLoading ||
      isTrendReportSuggestionLoading,
  };
};

export default useTrendReportDataQuery;
