import React from "react";
import styled from "styled-components";
import ReportTitle from "../../Components/ReportTitle";
import VCTable from "../Trend/VCTable";
import EmployeeChart from "./EmployeeChart";
import IRRChart from "./IRRChart";

const Summary = () => {
  return (
    <Container>
      <DiagnosisReportWrapper>
        <ReportTitle
          firstTitle={"스마트비대면· 모빌리티펀드"}
          secondTitle={"포트폴리오전체"}
          thirdTitle={"갱신일: 2022년3월31일"}
        />
        <ChartWarpper>
          <EmployeeChart />
          <IRRChart />
        </ChartWarpper>

        <VCTable />
      </DiagnosisReportWrapper>
    </Container>
  );
};

const Container = styled.div`
  ${({ theme }) => theme.flexSets.flexColumn}
  align-items: center;
  width: 100vw;
  padding-top: 92px;
  padding-bottom: 92px;
`;

const DiagnosisReportWrapper = styled.div`
  width: 720px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ChartWarpper = styled.div`
  display: flex;
  gap: 34px;
`;

export default Summary;
