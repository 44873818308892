import { TrendReportTextTable } from "../types/types";
import { convertTimestampToDate } from "../utils/utils";

export class TrendReportTableData {
  constructor(private _tableData: TrendReportTextTable[]) {}

  get title() {
    return this._tableData[0].label;
  }

  get dates() {
    return this._tableData.map((item) => convertTimestampToDate(item.date));
  }

  get contents() {
    return this._tableData.map((item) => item.data);
  }
}
