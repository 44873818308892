import React from "react";
import ReactECharts from "echarts-for-react";

const GroupLineChart = () => {
  return (
    <ReactECharts
      option={LINE_CHART_OPTIONS}
      style={{ width: "830px", height: "440px" }}
    />
  );
};

export default GroupLineChart;

const LINE_CHART_OPTIONS = {
  grid: { top: 50, right: 50, bottom: 100, left: 150 },
  xAxis: {
    axisTick: {
      length: 0,
    },
    axisLabel: {
      rotate: 50,
    },
    type: "category",
    data: [
      "2021-12-03",
      "2021-12-10",
      "2021-12-17",
      "2021-12-24",
      "2021-12-31",
      "2022-01-07",
      "2022-01-14",
      "2022-01-21",
      "2022-01-28",
      "2022-02-04",
      "2022-02-11",
      "2022-02-18",
    ],
  },
  yAxis: {
    type: "value",
  },
  legend: {
    top: 50,
    left: "left",
    orient: "vertical",
  },
  series: [
    {
      name: "조직 전체",
      data: [4.5, 4.3, 4, 4.4, 4.4, 4.6, 4, 4, 4.4, 4.3, 4.4, 4.6],
      type: "line",
      smooth: true,
      symbolSize: 10,
    },
    {
      name: "운영팀",
      data: [4.4, 4.3, 4, 4.1, 4.2, 4.3, 3.7, 3.6, 3.4, 3.6, 4.1, 4.2],
      type: "line",
      smooth: true,
      symbolSize: 10,
    },
    {
      name: "개발팀",
      data: [3.4, 3.2, 3.1, 2.5, 3.4, 4.5, 4.6, 4.7, 4.5, 4.6, 5, 4.6],
      type: "line",
      smooth: true,
      symbolSize: 10,
    },
    {
      name: "디자인팀",
      data: [4.3, 4.2, 3.5, 3.5, 3.6, 3.7, 3.8, 4.3, 4.2, 4.1, 3.8, 4.6],
      type: "line",
      smooth: true,
      symbolSize: 10,
    },
    {
      name: "홍보팀",
      data: [4.1, 3.7, 3.6, 3.6, 3.5, 3.6, 4.1, 4.2, 4.4, 4.3, 3.9, 3.7],
      type: "line",
      smooth: true,
      symbolSize: 10,
    },
  ],
  tooltip: {
    trigger: "axis",
  },
};
