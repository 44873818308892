import React from "react";
import ReactECharts from "echarts-for-react";

const BubbleChart = () => {
  return (
    <ReactECharts
      option={LINE_CHART_OPTIONS}
      style={{ width: "760px", height: "600px" }}
    />
  );
};

export default BubbleChart;

const LINE_CHART_OPTIONS = {
  grid: { show: true },
  xAxis: {
    type: "value",
    name: "만족도",
    axisTick: {
      length: 0,
    },
    axisLabel: { show: false },
    splitLine: {
      show: false,
    },
    splitNumber: 10,
    min: -5.087857143,
    max: 5.087857143,
    offset: 10,
  },
  yAxis: {
    axisTick: {
      length: 0,
    },
    axisLabel: { show: false },
    name: "중요도",
    splitLine: {
      show: false,
    },
    min: -1.678571429,
    max: 1.678571429,
    offset: 100,
  },
  axisLine: {
    show: false,
    onZero: false,
  },
  tooltip: {
    trigger: "item",
    formatter: "{b}",
  },
  itemStyle: {
    color: "red",
  },
  color: ["#fac62d"],
  series: [
    {
      colorBy: "series",
      symbolSize: 10,
      label: {
        show: true,
        color: "black",
        position: [15, 0],
        formatter: "{b}",
      },
      data: [
        {
          name: "법인카드 지원",
          value: [-1.4978571429999998, 0.8214285710000002],
        },
        {
          name: "통신비 지원",
          value: [1.3421428570000002, 0.15476190400000034],
        },
        {
          name: "OTT 구독 지원",
          value: [-1.377857143, -0.5119047619999997],
        },
        {
          name: "주차비 지원",
          value: [-1.827857143, -0.34523809599999966],
        },
        {
          name: "네이버페이 만원",
          value: [-1.7478571429999998, -0.8452380959999999],
        },
        {
          name: "결혼 시 휴가 지원",
          value: [0.11214285700000026, -0.8452380959999999],
        },
        {
          name: "가족 조사 지원",
          value: [-2.067857143, -0.2619047619999999],
        },
        {
          name: "부모님 환갑 화환",
          value: [-1.7478571429999998, -0.4285714289999998],
        },
        {
          name: "명절 지원금",
          value: [1.7821428570000002, 0.40476190400000034],
        },
        {
          name: "회사 헬스장 운영",
          value: [2.3621428570000003, 0.3214285710000002],
        },
        {
          name: "회사 조식 뷔페 운영",
          value: [2.452142857, 0.07142857100000022],
        },
        {
          name: "회사 간식 지원",
          value: [1.8521428570000005, -0.34523809599999966],
        },
        {
          name: "회사내 어린이집 지원",
          value: [-2.087857143, 0.40476190400000034],
        },
        {
          name: "전세 대출 무이자 지원",
          value: [2.452142857, 1.4047619040000003],
        },
      ],
      type: "scatter",
    },
  ],
};
