
import { EChartsOption } from 'echarts';
import { ILengendData } from '../../types';
import ChartError, { ErrorCode } from '../ChartError';
import ChartUtil from '../ChartUtil';
import { IPieChart } from '../types';
class PieChartOptionUtil extends ChartUtil {
  constructor(protected readonly charData: IPieChart[]) {
    super();
  }

  protected getSeriesData() {
    return this.charData.map((v) => v.count);
  }

  protected getSeriesColors() {
    return this.getColorList(this.charData.length);
  }

  protected getLegendData(): ILengendData[] {
    return this.charData.map((data, index) => ({
      color: this.getSeriesColors()[index],
      name: data.label,
      percent: data.percent,
      N: data.count,
    }));
  }
}

export class PieChartOption extends PieChartOptionUtil {
  private readonly options: EChartsOption = {};
  constructor(protected readonly charData: IPieChart[]) {
    super(charData);
  }

  getChartOption(): EChartsOption {
    this.options.series = this.getSeries();
    return {
      ...this.options,
    };
  }

  getLegend(): ILengendData[] {
    return this.getLegendData();
  }

  private getSeries(): EChartsOption['series'] {
    try {
      const chartSeries = this.charData;
      if (chartSeries === undefined || chartSeries.length === 0)
        throw new ChartError(ErrorCode.E1);

      return {
        type: 'pie',
        radius: ['50%', '90%'],
        center: ['50%', '50%'],
        label: {
          show: false,
        },
        data: super.getSeriesData(),
        color: super.getSeriesColors(),
      };
    } catch (e) {
      throw new ChartError(ErrorCode.E1);
    }
  }
}
