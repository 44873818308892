import React from "react";
import ReactECharts from "echarts-for-react";
import styled from "styled-components";
import { css } from "@emotion/css";

const EmployeeChart = () => {
  return (
    <ChartWapper
      className={css`
        border: 1px solid #f0f0f0;
        background-color: #ffffff;
      `}
    >
      <div
        className={css`
          display: flex;
          gap: 10px;
          margin-bottom: 8px;
        `}
      >
        <p
          className={css`
            font-size: 20px;
          `}
        >
          IRR
        </p>
        <p
          className={css`
            font-size: 15px;
            margin-top: 2px;
          `}
        >
          21.3%
        </p>
      </div>
      <p
        className={css`
          font-size: 13px;
        `}
      >
        2022년 2월 기준 예상치
      </p>

      <ReactECharts
        option={IRR_CHART_OPTION}
        style={{ width: "310px", height: "220px" }}
      />
    </ChartWapper>
  );
};

export default EmployeeChart;

const ChartWapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.gray01};
  padding: 15px;
  border-radius: 3px;
`;

const IRR_CHART_OPTION = {
  xAxis: {
    type: "category",
    data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    show: false,
  },
  yAxis: {
    type: "value",
    min: 12,
    max: 25,
    interval: 5,
    show: false,
  },
  series: [
    {
      data: [20, 19, 21, 19, 20, 21],
      type: "line",
      itemStyle: {
        color: "#FBCD27",
        width: 4,
      },
      symbolSize: 0,
    },
  ],
};
