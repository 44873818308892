import { graphic } from "echarts";
import { IGetVCData } from "../../hooks/query/useGetVCData";

export interface MockDataItem {
  company: string;
  revenue: number;
  netmargin: number;
  cashonhand: number;
  good: string;
  bad: string;
  ask: string;
  date: string;
}

export const MOCK_DATA: MockDataItem[] = [
  {
    company: "얼리슬로스",
    revenue: 3000000,
    netmargin: 1340000,
    cashonhand: 1000000,
    good: "우리는 잘나가고 있습니다.",
    bad: "아쉬운 게 하나도 없습니다.",
    ask: "야호",
    date: "2022년 9월",
  },
  {
    company: "얼리슬로스",
    revenue: 2000000,
    netmargin: 1340000,
    cashonhand: 1100000,
    good: "우리는 잘나가고 있습니다.",
    bad: "아쉬운 게 하나도 없습니다.",
    ask: "야호",
    date: "2022년 10월",
  },
  {
    company: "얼리슬로스",
    revenue: 2500000,
    netmargin: 1940000,
    cashonhand: 3000000,
    good: "우리는 잘나가고 있습니다.",
    bad: "아쉬운 게 하나도 없습니다.",
    ask: "야호",
    date: "2022년 11월",
  },
  {
    company: "얼리슬로스",
    revenue: 2500000,
    netmargin: 1940000,
    cashonhand: 1000000,
    good: "우리는 잘나가고 있습니다.",
    bad: "아쉬운 게 하나도 없습니다.",
    ask: "야호",
    date: "2022년 12월",
  },
  {
    company: "얼리슬로스",
    revenue: 2500000,
    netmargin: 1940000,
    cashonhand: 1100000,
    good: "우리는 잘나가고 있습니다.",
    bad: "아쉬운 게 하나도 없습니다.",
    ask: "야호",
    date: "2023년 1월",
  },
  {
    company: "얼리슬로스",
    revenue: 2500000,
    netmargin: 1940000,
    cashonhand: 500000,
    good: "우리는 잘나가고 있습니다.",
    bad: "아쉬운 게 하나도 없습니다.",
    ask: "야호",
    date: "2023년 2월",
  },
];

export const getChartOption = (data?: IGetVCData[]) => {
  return {
    grid: {
      left: 0,
      right: 10,
      top: 10,
      bottom: 40,
      containLabel: true,
    },
    legend: {
      icon: "circle",
      itemWidth: 5,
      itemHeight: 5,
      bottom: 0,
      textStyle: {
        fontSize: 12,
      },
    },
    tooltip: {
      trigger: "axis",
    },
    xAxis: {
      type: "category",
      data: data?.map((item) => item.date),
      axisTick: {
        show: false,
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: "#C9C8C7",
        },
      },
      axisLabel: {
        color: "#818282",
      },
    },
    yAxis: {
      type: "value",
      axisLabel: {
        formatter: "{value}",
        color: "#C9C8C7",
      },
      splitLine: {
        lineStyle: {
          color: "#F0F0F0",
        },
      },
    },
    color: ["#41B0A3", "#494FE2"],
    series: [
      {
        data: data?.map((item) => item.cashonhand),
        name: "보유 자금",
        type: "line",
        smooth: true,
        areaStyle: {
          color: new graphic.LinearGradient(0, 1, 0, 0.5, [
            {
              offset: 0,
              color: "rgba(65, 176, 163, 0)",
            },
            {
              offset: 1,
              color: "rgba(65, 176, 163, 1)",
            },
          ]),
        },
      },
      {
        data: data?.map((item) => item.revenue),
        name: "매출",
        type: "line",
      },
    ],
  };
};
