import React, { useState } from "react";
import { Icon, Input2 } from "@earlysloth/pocketsurvey-ui-components";
import styled from "styled-components";

const Search = () => {
  const [value, setValue] = useState("");
  return (
    <SearchWrapper>
      <Input2
        width={283}
        color={"green"}
        placeholder="여기에 키워드를 입력해주세요."
        value={value}
        size="small"
        onChange={(event) => setValue(event.target.value)}
      >
        <Input2.Clear icon="circle" />
      </Input2>

      <IconWrapper>
        <Icon
          icon="search"
          width={14}
          color={"#2b2e33"}
          onClick={() => alert("Hello!")}
          useCursor={true}
        />
      </IconWrapper>
    </SearchWrapper>
  );
};

const SearchWrapper = styled.div`
  position: relative;
  margin-bottom: 24px;
`;

const IconWrapper = styled.div`
  position: absolute;
  left: 260px;
  bottom: 7px;
`;

export default Search;
