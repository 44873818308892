const XAXIS_LABEL_DATA = [
  "2021년 1월",
  "2021년 2월",
  "2021년 3월",
  "2021년 4월",
  "2021년 5월",
  "2021년 6월",
  "2021년 7월",
  "2021년 8월",
  "2021년 9월",
  "2021년 10월",
  "2021년 11월",
];

const data = [
  { good: "17.0", bad: "83.0" },
  { good: "90.5", bad: "9.5" },
  { good: "21.6", bad: "78.4" },
  { good: "47.0", bad: "53.0" },
  { good: "77.2", bad: "22.8" },
  { good: "47.4", bad: "52.6" },
  { good: "30.1", bad: "69.9" },
  { good: "52.7", bad: "47.3" },
  { good: "58.9", bad: "41.1" },
  { good: "65.6", bad: "34.4" },
  { good: "44.6", bad: "55.4" },
];

const GOOD_DATA = data.map((item) => Number(item.good));

const BAD_DATA = data.map((item) => Number(item.bad));

const AVERAGE_DATA = [
  3.4, 3.8, 3.6, 3.7, 3.9, 3.8, 3.7, 4.2, 4.3, 4.4, 4.3,
].map((item) => item * 20);

export const VERTICAL_BAR_LINE_CHART2_OPTIONS = {
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "shadow",
    },
  },
  legend: {
    show: true,
    icon: "circle",
    bottom: 16,
    itemWidth: 9,
    itemHeight: 9,
  },
  grid: {
    top: 18,
    bottom: 52,
    left: 13,
    right: 12,
    containLabel: true,
  },
  yAxis: [
    {
      min: 0,
      max: 100,
      axisLabel: {
        formatter: "{value} %",
      },
      interval: 10,
    },
    {
      min: 60,
      max: 100.0,
      axisLabel: {
        formatter: function (value: string) {
          return Number(value).toFixed(2);
        },
      },
      show: false,
      interval: 20,
    },
  ],
  xAxis: [
    {
      type: "category",
      axisTick: {
        show: false,
      },
      data: XAXIS_LABEL_DATA,
      axisLine: {
        show: false,
      },
    },
  ],
  series: [
    {
      name: "전체 평점",
      type: "line",
      yAxisIndex: 1,
      data: AVERAGE_DATA,
      symbol: "circle",
    },
    {
      name: "불만",
      type: "bar",
      stack: "Total",
      yAxisIndex: 0,
      data: BAD_DATA,
      barWidth: 20,
    },
    {
      name: "칭찬",
      type: "bar",
      stack: "Total",
      yAxisIndex: 0,
      data: GOOD_DATA,
      barWidth: 20,
    },
  ],
  color: ["#2B2E33", "#FFA093", "#89D8B2"],
};
