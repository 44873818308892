import { useState } from "react";
import styled from "styled-components";

import VocToggle from "./VocToggle/VocToggle";
import VerticalBarLineChart from "../Charts/VerticalBarLineChart/VerticalBarLineChart";
import VerticalBarLineChart2 from "../Charts/VerticalBarLineChart2/VerticalBarLineChart2";

const VocTrend = () => {
  const [isSelected, setIsSelected] = useState(true);

  const toggleSelected = (value: boolean) => setIsSelected(value);

  return (
    <Container>
      <div>
        <Title>VOC 칭찬/불만 추이</Title>
        <ToggleWrapper>
          <VocToggle isSelected={isSelected} toggleSelected={toggleSelected} />
        </ToggleWrapper>
      </div>
      <ChartWrapper>
        {isSelected ? (
          <>
            <GrayText>
              최근 4주 평점 <BoldText>78.4/100.0</BoldText>
            </GrayText>
            <VerticalBarLineChart />
          </>
        ) : (
          <VerticalBarLineChart2 />
        )}
      </ChartWrapper>
    </Container>
  );
};

export default VocTrend;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 1200px;
  height: 542px;
  padding: 32px;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  border-radius: 3px;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 150%;
  color: #2b2e33;
  letter-spacing: -0.5px;
`;

const ToggleWrapper = styled.div`
  display: flex;
  justify-content: end;
`;

const GrayText = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: -0.5px;
  color: #818282;
`;

const BoldText = styled.span`
  margin-left: 4px;
  font-weight: 700;
  font-size: 20px;
  line-height: 150%;
  letter-spacing: -0.5px;
  color: #2b2e33;
`;

const ChartWrapper = styled.div`
  margin-top: 20px;
`;
