import {
  LABEL_TAG_BACKGROUNDCOLOR,
  LABEL_TAG_BORDER_COLOR,
  LABEL_TAG_COLOR,
} from "../../constants";
import { LabelType } from "../../types";

export const 중점개선영역 = `<div
style="
  border: 1px solid ${LABEL_TAG_BORDER_COLOR.get(LabelType["중점 개선 영역"])};
  background-color: ${LABEL_TAG_BACKGROUNDCOLOR.get(
    LabelType["중점 개선 영역"]
  )};
  border-radius: 3px;
  box-sizing: border-box;
  padding: 6px 8px;
  font-size: 12px;
  letter-spacing: -0.5px;
  color:  ${LABEL_TAG_COLOR.get(LabelType["중점 개선 영역"])};;
  width: 85px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family:"Spoqa Han Sans Neo Medium"
"
>
<p style="margin: 0">중점 개선 영역</p>
</div>`;

export const 현상유지영역 = `
<div
      style="
        border: 1px solid ${LABEL_TAG_BORDER_COLOR.get(
          LabelType["현상 유지 영역"]
        )};
        background-color: ${LABEL_TAG_BACKGROUNDCOLOR.get(
          LabelType["현상 유지 영역"]
        )};
        border-radius: 3px;
        box-sizing: border-box;
        padding: 6px 8px;
        font-size: 12px;
        letter-spacing: -0.5px;
        color: ${LABEL_TAG_COLOR.get(LabelType["현상 유지 영역"])};
        width: 85px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family:"Spoqa Han Sans Neo Medium"
      "
    >
      <p style="margin: 0">현상 유지 영역</p>
    </div>
`;

export const 점진적개선영역 = `
<div
style="
  border: 1px solid ${LABEL_TAG_BORDER_COLOR.get(
    LabelType["점진적 개선 영역"]
  )};
  background-color: ${LABEL_TAG_BACKGROUNDCOLOR.get(
    LabelType["점진적 개선 영역"]
  )};
  border-radius: 3px;
  box-sizing: border-box;
  padding: 6px 8px;
  font-size: 12px;
  letter-spacing: -0.5px;
  color: ${LABEL_TAG_COLOR.get(LabelType["점진적 개선 영역"])};
  width: 95px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family:"Spoqa Han Sans Neo Medium"
"
>
<p style="margin: 0">점진적 개선 영역</p>
</div>
`;

export const 유지강화영역 = `
<div
style="
  border: 1px solid ${LABEL_TAG_BORDER_COLOR.get(LabelType["유지 강화 영역"])};
  background-color: ${LABEL_TAG_BACKGROUNDCOLOR.get(
    LabelType["유지 강화 영역"]
  )};
  border-radius: 3px;
  box-sizing: border-box;
  padding: 6px 8px;
  font-size: 12px;
  letter-spacing: -0.5px;
  color:  ${LABEL_TAG_COLOR.get(LabelType["유지 강화 영역"])};
  width: 85px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family:"Spoqa Han Sans Neo Medium"
"
>
<p style="margin: 0">유지 강화 영역</p>
</div>
`;
