import React from "react";

import useTrendReportUrlParams from "../hooks/useGetUrlParams";

const ClientLabel = () => {
  const { clientLabel, companyLabel } = useTrendReportUrlParams();

  document.title = `${clientLabel}-${companyLabel}`;

  return (
    <div className="font-[700] text-[34px] leading-[150%] border-[#E5E9EB] border-solid border-b-[1px] px-[28px] py-[32px] mb-[25px]">
      {companyLabel ?? ""}
    </div>
  );
};

export default ClientLabel;
