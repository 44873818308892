import {
  IIPAData,
  IQuadrantChartData,
  LabelType,
  TableDataType,
} from "../types";

export class IPADataUtil {
  private readonly CORRECTION_VALUE = -50;
  private readonly THRESHOLD = 50;
  constructor(private readonly ipaData: IIPAData[]) {}

  getGraphData(): IQuadrantChartData[] {
    return this.ipaData
      .filter((v) => v.table.importance !== null)
      .map((v) => ({
        name: v.label,
        value: [
          v.graph.satisfaction + this.CORRECTION_VALUE,
          v.graph.importance + this.CORRECTION_VALUE,
        ],
        label: this.getLabel(
          v.graph.satisfaction,
          v.graph.importance
        ) as LabelType,
      }));
  }

  getTableData(): TableDataType[] {
    return this.ipaData
      .filter((v) => v.table.importance !== null)
      .map((data) => [
        data.label,
        this.getFactorTagType(data.table.isPositive),
        data.table.importance,
        data.table.satisfaction,
        this.getLabel(
          data.graph.satisfaction,
          data.graph.importance
        ) as LabelType,
      ]);
  }

  private getFactorTagType(isPositive: boolean) {
    return isPositive ? 0 : 1;
  }

  private getLabel(satisfaction: number, importance: number) {
    if (this.THRESHOLD >= satisfaction) {
      if (this.THRESHOLD >= importance) {
        return LabelType["점진적 개선 영역"];
      }
      if (this.THRESHOLD < importance) {
        return LabelType["중점 개선 영역"];
      }
    }
    if (this.THRESHOLD < satisfaction) {
      if (this.THRESHOLD >= importance) {
        return LabelType["현상 유지 영역"];
      }
      if (this.THRESHOLD < importance) {
        return LabelType["유지 강화 영역"];
      }
    }
  }
}
