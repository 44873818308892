import React from "react";
import styled from "styled-components";
import text from "../../font";

interface IWorkCard {
  cardValue: {
    subtitle: string;
    title: string;
    description: JSX.Element;
  };
}

const WorkCard = ({ cardValue }: IWorkCard) => {
  return (
    <WorkCardWrapper>
      <TitleWrapper>
        <SubTitle>{cardValue.subtitle}</SubTitle>
        <MainTitle>{cardValue.title}</MainTitle>
      </TitleWrapper>
      <Description>{cardValue.description}</Description>
    </WorkCardWrapper>
  );
};

export default WorkCard;

const WorkCardWrapper = styled.div`
  padding: 32px;
  box-sizing: border-box;
  border: 1px solid #dfdedd;

  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  min-width: 349px;
  min-height: 220px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  letter-spacing: 0.5px;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  letter-spacing: 0.5px;
`;

const SubTitle = styled.p`
  ${text({
    size: 12,
    color: "gray04",
    weight: "regular",
  })}
  letter-spacing: 0.5px;
`;

const MainTitle = styled.p`
  ${text({
    size: 16,
    color: "gray05",
    weight: "bold",
  })}
  letter-spacing: 0.5px;
`;

const Description = styled.div`
  ${text({
    size: 14,
    color: "gray05",
    weight: "regular",
  })}
  letter-spacing: 0.5px;
  line-height: 130%;
`;
