import React from "react";
import styled from "styled-components";

interface IReportTitleProps {
  firstTitle: string;
  secondTitle: string;
  thirdTitle: string;
}

const ReportTitle = ({
  firstTitle,
  secondTitle,
  thirdTitle,
}: IReportTitleProps) => {
  return (
    <ReportTitleWrapper>
      <FirstHeaderTitle>{firstTitle}</FirstHeaderTitle>
      <SencondHeaderTitle>{secondTitle}</SencondHeaderTitle>
      <ThridHeaderTitle>{thirdTitle}</ThridHeaderTitle>
    </ReportTitleWrapper>
  );
};

const ReportTitleWrapper = styled.div`
  box-sizing: border-box;
  height: 166px;
  padding: 32px 32px 0px 32px;
  background-color: #ffffff;
  border: 1px solid #f0f0f0;
  border-radius: 3px;
`;

const FirstHeaderTitle = styled.p`
  font-family: "Spoqa Han Sans Neo";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: -0.5px;
  color: #818282;
  margin-bottom: 14px;
`;

const SencondHeaderTitle = styled.p`
  font-family: "Spoqa Han Sans Neo";
  font-weight: 700;
  font-size: 24px;
  letter-spacing: -0.5px;
  color: #2b2e33;
  margin-bottom: 18px;
`;

const ThridHeaderTitle = styled.p`
  font-family: "Spoqa Han Sans Neo Medium";
  font-size: 14px;
  letter-spacing: -0.5px;
  color: #2b2e33;
`;

export default ReportTitle;
