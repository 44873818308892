import React from "react";
import styled from "styled-components";
import GroupLineChart from "../../../Components/GroupLineChart";
import Header from "../../../Components/Header";

const Trand = () => {
  return (
    <TrandWrapper>
      <Header title="얼리슬로스의 행복 트렌드" isFilter />
      <ChartWrapper>
        <GroupLineChart />
      </ChartWrapper>
    </TrandWrapper>
  );
};

const TrandWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px 32px 64px 32px;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.gray01};
  border-radius: 3px;
`;

const ChartWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
export default Trand;
