import React, { useRef } from 'react';
import styled, { css } from 'styled-components';

import { Icon } from '@earlysloth/pocketsurvey-ui-components';
import { END_COLUMN_WIDTH, getCellWidth } from './ShorttextTableStyle';
import { ShorttextAnswerTablePropsType } from '../hooks/useShorttextAnswerTable';

import { HeaderGroup } from 'react-table';
import useShorttextAnswer from '../hooks/useShorttextAnswer';
import text from '../../../font';
import ShorttextColumnFilter from '../../components/ShortTextFilter';

interface ITableHeader {
  tableProps: ShorttextAnswerTablePropsType;
}

const TableHeader = ({ tableProps }: ITableHeader) => {
  const { headerGroups } = tableProps;

  const tableHeadRef = useRef<HTMLDivElement>(null);

  const {
    handleFilterClick,
    selectedColumnId,
    filterXYPosition,
    setClickedColumnId,
  } = useShorttextAnswer((state) => ({
    handleFilterClick: state.handleFilterClick,
    selectedColumnId: state.selectedColumnId,
    filterXYPosition: state.filterXYPosition,
    setClickedColumnId: state.setClickedColumnId,
  }));

  return (
    <>
      {headerGroups.map((headerGroup, index) => (
        <TableHeaderWrapper
          {...headerGroup.getHeaderGroupProps()}
          key={`table-header-wrapper-${index}`}
        >
          {headerGroup.headers.map((column, index) => (
            <>
              {selectedColumnId === column.id && (
                <TableFitlerWrapper {...filterXYPosition}>
                  <ShorttextColumnFilter
                    column={column as HeaderGroup<object>}
                    setfilterIsVisible={() => {
                      setClickedColumnId(null);
                    }}
                  />
                </TableFitlerWrapper>
              )}
              <TableHead
                {...column.getHeaderProps()}
                key={`header-group-${column.id}`}
                length={column.render('Header')?.toLocaleString().length ?? 0}
                ref={tableHeadRef}
              >
                <TableHeadContent>
                  <NameSortWrapper>
                    {column.render('Header')}
                    <SortIconSpan {...column.getSortByToggleProps()}>
                      {column.isSorted ? (
                        <Icon
                          className="down-arrow"
                          color="#818282"
                          icon="arrow"
                          width={14}
                          rotate={column.isSortedDesc ? 90 : -90}
                          useCursor
                        />
                      ) : (
                        <Icon
                          className="down-arrow"
                          color="#818282"
                          icon="sort"
                          width={14}
                          useCursor
                        />
                      )}
                    </SortIconSpan>
                  </NameSortWrapper>

                  <div>
                    <MoreIconSpan
                      onClick={(event) =>
                        handleFilterClick(
                          event,
                          column.id,
                          tableHeadRef.current?.getBoundingClientRect(),
                        )
                      }
                    >
                      <Icon
                        icon="moreDetail"
                        width={14}
                        color="#818282"
                        rotate={90}
                        useCursor
                      />
                    </MoreIconSpan>
                    <Resizer
                      {...column.getResizerProps()}
                      className={`resizer ${
                        column.isResizing ? 'isResizing' : ''
                      }`}
                    >
                      <ResizeLine />
                    </Resizer>
                  </div>
                </TableHeadContent>
              </TableHead>
            </>
          ))}
        </TableHeaderWrapper>
      ))}
    </>
  );
};

const TableHeaderWrapper = styled.div`
  display: flex;
  width: 100% !important; // 100%로 정하지 않으면 react-table의 useBlockLayout에서 width를 지정px로 고정시킴
  position: relative;
`;

const TableHead = styled.div<{ length: number }>`
  padding: 0;
  position: relative;

  border-bottom: 1px solid #f0f0f0;
  background-color: #f8f8f8;
  box-sizing: border-box;
  font-size: 12px;
  border-top: 1px solid ${({ theme }) => theme.colors.gray01};

  ${END_COLUMN_WIDTH}/* ${(props) => getCellWidth(props.length)} */
`;

const TableHeadContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding-left: 16px;
  padding-top: 16px;
  padding-bottom: 16px;

  height: 50px;
  position: relative;
  ${text({
    size: 12,
    weight: 'medium',
  })}
`;

const TableFitlerWrapper = styled.div<{
  x: number;
  y: number;
}>`
  position: absolute;
  z-index: 19;
  left: ${({ x }) => `${x}px`};
  /* top: ${({ y }) => `${y - 10}px`}; */
  top: 0px;
  font-size: 12px;
`;

export default TableHeader;

const Resizer = styled.div`
  display: inline-block;
  width: 15px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(50%);
  z-index: 1;
  touch-action: none;
  opacity: 0;

  &.isResizing {
    opacity: 100;
  }
  &:hover {
    opacity: 100;
  }
`;

const ResizeLine = styled.div`
  width: 2px;
  height: 100%;
  background-color: #fac62d;
`;

const SortIconSpan = styled.span`
  margin-left: 4px;
`;

const MoreIconSpan = styled.span`
  margin-right: 8px;
`;

const NameSortWrapper = styled.div`
  display: flex;
  align-self: center;
`;
