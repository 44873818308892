import React from "react";
import styled, { css } from "styled-components";

interface IHeaderProps {
  title: string;
  subTitle?: string;
  isFilter?: boolean;
}

const Header = ({ title, subTitle, isFilter }: IHeaderProps) => {
  return (
    <HeaderWrapper>
      <TitleWrapper>
        <TitleValue>{title}</TitleValue>
        {subTitle ? (
          <SubTitleValue subTitle={subTitle}>{subTitle}</SubTitleValue>
        ) : null}
      </TitleWrapper>
      {isFilter ? <FilterWrapper>필터:조직</FilterWrapper> : null}
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled.div`
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const TitleValue = styled.p`
  color: #2b2e33;
  font-size: 20px;
  font-weight: 700;
  font-family: "Spoqa Han Sans Neo";
  letter-spacing: -0.5px;
`;

export const SubTitleValue = styled.p<{ subTitle: string }>`
  color: #818282;

  font-family: "Spoqa Han Sans Neo Medium";
  letter-spacing: -0.5px;
  ${(props) =>
    props.subTitle === "매출 및 매출 성장률"
      ? css`
          margin-top: 30px;
          font-size: 16px;
        `
      : css`
          font-size: 14px;
        `}
`;

const FilterWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 91px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #dfdedd;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 9px 16px;
  font-family: "Spoqa Han Sans Neo Medium";
  font-size: 14px;
  text-align: center;
  letter-spacing: -0.5px;
  color: #2b2e33;
`;

export default Header;
