import React, { useEffect } from 'react';
import styled from 'styled-components';
import TableHeader from './TableHeader';
import TableRows from './TableRows';
import { Pagination2 } from '@earlysloth/pocketsurvey-ui-components';
import useShorttextAnswerTable, {
  ShorttextAnswerTablePropsType,
} from '../hooks/useShorttextAnswerTable';

interface IShorttextTableProps {
  tableProps: ShorttextAnswerTablePropsType;
}

const ShorttextTable = ({ tableProps }: IShorttextTableProps) => {
  return (
    <>
      <TableWrapper {...tableProps.getTableProps()}>
        <TableHeader tableProps={tableProps} />
        <TableRows tableProps={tableProps} />
      </TableWrapper>
    </>
  );
};

const TableWrapper = styled.div`
  margin-top: 28px;
  min-height: 920px;
`;

export default ShorttextTable;
