import styled, { css } from "styled-components";

interface IVocToggleProps {
  isSelected: boolean;
  toggleSelected: (value: boolean) => void;
}

const VocToggle = ({ isSelected, toggleSelected }: IVocToggleProps) => {
  return (
    <ToggleContainer>
      <FlexBox>
        <ToggleItem
          isSelected={isSelected}
          onClick={() => toggleSelected(true)}
        >
          건수
        </ToggleItem>
        <ToggleItem
          isSelected={!isSelected}
          onClick={() => toggleSelected(false)}
        >
          비율
        </ToggleItem>
      </FlexBox>
    </ToggleContainer>
  );
};

export default VocToggle;

const ToggleContainer = styled.div`
  display: inline-block;
  background: #f0f0f0;
  border-radius: 3px;
`;

const FlexBox = styled.div`
  display: flex;
  padding: 1.5px;
`;

const ToggleItem = styled.div<{
  isSelected: boolean;
}>`
  padding: 8px 16px;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: -0.5px;
  ${({ isSelected }) =>
    isSelected &&
    css`
      background: #ffffff;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
      border-radius: 3px;
    `};
  color: ${({ isSelected }) => (isSelected ? "#2B2E33" : "#C9C8C7")};
  cursor: pointer;
`;
