import zip from "lodash/zip";
import { graphic } from "echarts";
import Color from "color";

import {
  convertStringNumberToNumber,
  convertTimestampToDate,
  getTargetColors,
  replaceUnderbarWithSpace,
  sortByString,
} from "../utils/utils";

import { RemappedChartData, TrendReportMixedChart } from "../types/types";
import { isNull } from "lodash";

export class TrendReportChartData {
  constructor(
    private _chartData: TrendReportMixedChart[],
    private colors: string[]
  ) {}

  get title() {
    return this._chartData[0].label;
  }

  get date() {
    return this._chartData
      .map((item) => item.date)
      .sort((a, b) => sortByString(a, b))
      .map((item) => convertTimestampToDate(item));
  }

  get seriesData() {
    const remappedChartData = this.remapChartData(this._chartData);

    const zippedChartData = this.zipChartData(remappedChartData);

    const seriesData = zippedChartData.map((items, index) => {
      if (items[0]?.chart === "line") {
        return this.getLineSeriesData(items);
      }

      if (items[0]?.chart === "line_smooth") {
        return this.getLineSmoothSeriesData(items);
      }

      if (items[0]?.chart === "bar") {
        return this.getBarSeriesData(items);
      }

      if (items[0]?.chart === "area") {
        return this.getAreaSeriesData(items, index);
      }

      if (items[0]?.chart === "area_smooth") {
        return this.getAreaSmoothSeriesData(items, index);
      }

      return null;
    });

    return seriesData.filter((item) => !isNull(item)) as {
      data: number[];
      name: string;
      type: string;
    }[];
  }

  get option() {
    const xLabels = this.date;
    const colors = this.colors;
    const series = this.seriesData;

    return {
      grid: {
        left: 0,
        right: 0,
        top: 30,
        bottom: 90,
        containLabel: true,
      },
      dataZoom: {
        width: "auto",
        left: "center",
        bottom: 0,
        maxValueSpan: 10,
        brushSelect: false,
        startValue: Infinity,
      },
      legend: {
        icon: "circle",
        itemWidth: 5,
        itemHeight: 5,
        bottom: 50,
        textStyle: {
          fontSize: 12,
        },
      },
      tooltip: {
        trigger: "axis",
      },
      xAxis: {
        type: "category",
        data: xLabels,
        axisTick: {
          show: false,
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: "#C9C8C7",
          },
        },
        axisLabel: {
          color: "#818282",
        },
      },
      yAxis: {
        type: "value",
        axisLabel: {
          formatter: "{value}",
          color: "#C9C8C7",
        },
        splitLine: {
          lineStyle: {
            color: "#F0F0F0",
          },
        },
      },
      color: colors,
      series: series,
    };
  }

  private remapChartData(data: TrendReportMixedChart[]): RemappedChartData[][] {
    return data
      .map((item) => {
        const newItem = Object.entries(item.data).map(([key, value]) => ({
          ...value,
          date: item.date,
          name: replaceUnderbarWithSpace(key),
        }));
        return newItem;
      })
      .map((item) => item.sort((a, b) => sortByString(a.name, b.name)));
  }

  private zipChartData(data: RemappedChartData[][]) {
    return zip(...data).map((item) =>
      item.sort((a, b) => sortByString(a?.date ?? "", b?.date ?? ""))
    );
  }

  private getLineSeriesData(data: (RemappedChartData | undefined)[]) {
    return {
      data: data.map((item) => convertStringNumberToNumber(item?.value ?? "0")),
      name: data[0]?.name ?? "",
      type: "line",
    };
  }

  private getLineSmoothSeriesData(data: (RemappedChartData | undefined)[]) {
    return {
      data: data.map((item) => convertStringNumberToNumber(item?.value ?? "0")),
      name: data[0]?.name ?? "",
      type: "line",
      smooth: true,
    };
  }

  private getBarSeriesData(data: (RemappedChartData | undefined)[]) {
    return {
      data: data.map((item) => {
        const value = convertStringNumberToNumber(item?.value ?? "0");

        const itemStyle = {
          emphasis: {
            barBorderRadius: [4, 4],
          },
          normal:
            value >= 0
              ? {
                  barBorderRadius: [4, 4, 0, 0],
                }
              : {
                  barBorderRadius: [0, 0, 4, 4],
                },
        };

        return {
          value,
          itemStyle,
        };
      }),
      name: data[0]?.name ?? "",
      type: "bar",
      barWidth: 36,
      itemStyle: {
        emphasis: {
          barBorderRadius: [4, 4],
        },
        normal: {
          barBorderRadius: [4, 4, 0, 0],
        },
      },
    };
  }

  private getAreaSeriesData(
    data: (RemappedChartData | undefined)[],
    index: number
  ) {
    const startColor = Color(getTargetColors(this.colors, index))
      .rgb()
      .alpha(0)
      .toString();

    const endColor = Color(getTargetColors(this.colors, index))
      .rgb()
      .alpha(1)
      .toString();

    return {
      data: data.map((item) => convertStringNumberToNumber(item?.value ?? "0")),
      name: data[0]?.name ?? "",
      type: "line",
      areaStyle: {
        color: new graphic.LinearGradient(0, 1, 0, 0.5, [
          {
            offset: 0,
            color: startColor,
          },
          {
            offset: 1,
            color: endColor,
          },
        ]),
      },
    };
  }

  private getAreaSmoothSeriesData(
    data: (RemappedChartData | undefined)[],
    index: number
  ) {
    const startColor = Color(getTargetColors(this.colors, index))
      .rgb()
      .alpha(0)
      .toString();

    const endColor = Color(getTargetColors(this.colors, index))
      .rgb()
      .alpha(1)
      .toString();

    return {
      data: data.map((item) => convertStringNumberToNumber(item?.value ?? "0")),
      name: data[0]?.name ?? "",
      type: "line",
      smooth: true,
      areaStyle: {
        color: new graphic.LinearGradient(0, 1, 0, 0.5, [
          {
            offset: 0,
            color: startColor,
          },
          {
            offset: 1,
            color: endColor,
          },
        ]),
      },
    };
  }
}
