const XAXIS_LABEL_DATA = [
  "2021년 1월",
  "2021년 2월",
  "2021년 3월",
  "2021년 4월",
  "2021년 5월",
  "2021년 6월",
  "2021년 7월",
  "2021년 8월",
  "2021년 9월",
  "2021년 10월",
  "2021년 11월",
];

const GOOD_DATA = [17, 38, 8, 55, 44, 74, 22, 69, 73, 82, 25];

const BAD_DATA = [-83, -4, -29, -62, -13, -82, -51, -62, -51, -43, -31];

const AVERAGE_DATA = [
  3.4, 3.8, 3.6, 3.7, 3.9, 3.8, 3.7, 4.2, 4.3, 4.4, 4.3,
].map((item) => item * 20);

export const VERTICAL_BAR_LINE_CHART_OPTIONS = {
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "shadow",
    },
  },
  legend: {
    show: true,
    icon: "circle",
    bottom: 16,
    itemWidth: 9,
    itemHeight: 9,
  },
  grid: {
    top: 18,
    bottom: 52,
    left: 13,
    right: 12,
    containLabel: true,
  },
  yAxis: [
    {
      min: 60.0,
      max: 100.0,
      axisLabel: {
        formatter: function (value: string) {
          return Number(value).toFixed(2);
        },
      },
      interval: 5,
    },
    {
      min: -100,
      max: 100,
      axisLabel: {
        formatter: "{value} 점",
      },
      interval: 100,
    },
  ],
  xAxis: [
    {
      type: "category",
      axisTick: {
        show: false,
      },
      data: XAXIS_LABEL_DATA,
      axisLine: {
        show: false,
      },
    },
  ],
  series: [
    {
      name: "전체 평점",
      type: "line",
      yAxisIndex: 0,
      data: AVERAGE_DATA,
      symbol: "circle",
    },
    {
      name: "칭찬",
      type: "bar",
      stack: "Total",
      yAxisIndex: 1,
      data: GOOD_DATA,
      barWidth: 20,
    },
    {
      name: "불만",
      type: "bar",
      stack: "Total",
      yAxisIndex: 1,
      data: BAD_DATA,
      barWidth: 20,
    },
  ],
  color: ["#2B2E33", "#89D8B2", "#FFA093"],
};
