import styled, { css, FlattenSimpleInterpolation } from "styled-components";

const BlockWrapper = styled.div<{
  height?: number;
  css?: FlattenSimpleInterpolation;
  isIframe?: boolean;
}>`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border: 1px solid #f0f0f0;
  border-radius: 3px;
  margin-bottom: 24px;
  padding: 30px;

  ${(props) =>
    props.height &&
    css`
      height: ${props.height};
    `}

  ${(props) => props.css}
  ${(props) =>
    props.isIframe
      ? css`
          width: 970px;
        `
      : css`
          width: 1200px;
        `}
`;

export default BlockWrapper;
