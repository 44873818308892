import React from "react";
import styled from "styled-components";
import text from "../../../../../font";

interface ITitle {
  title: string;
}

const ChartTitle = ({ title }: ITitle) => {
  return (
    <TitleWrapper>
      <Title>{title}의 점수</Title>
    </TitleWrapper>
  );
};

const TitleWrapper = styled.div``;
const Title = styled.p`
  ${text({
    size: 16,
    weight: "bold",
    color: "gray05",
  })}
  letter-spacing: -0.5px;
`;

export default ChartTitle;
