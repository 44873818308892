import React from "react";
import styled from "styled-components";

import BubbleChart from "../../../../Components/BubbleChart";

const DiagnosisChart = () => {
  return (
    <ChartWrapper>
      <BubbleChart />
    </ChartWrapper>
  );
};

export default DiagnosisChart;

const ChartWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
