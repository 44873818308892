import { css } from 'styled-components';

export const cssUtil = {
  getBorder({ index }: { index: number }) {
    if (index === 0) {
      return css`
        border-right: 1px solid #f0f0f0;
        border-bottom: 1px solid #f0f0f0;
      `;
    }
    if (index === 1) {
      return css`
        border-bottom: 1px solid #f0f0f0;
      `;
    }
    if (index === 2) {
      return css`
        border-right: 1px solid #f0f0f0;
      `;
    }
  },
};
