import styled from "styled-components";

import ReviewTable from "../ReviewTable";
import TableStyle from "../TableStyle";

const KeywordReview = () => {
  return (
    <Container>
      <TableStyle.Title>키워드가 언급된 리뷰</TableStyle.Title>
      <ReviewTable />
    </Container>
  );
};

export default KeywordReview;

const Container = styled.div`
  margin-top: 24px;
  padding: 32px;
  background-color: #ffffff;
  border: 1px solid #f0f0f0;
  border-radius: 3px;
`;
