import EChartsReact from "echarts-for-react";
import React from "react";
import styled from "styled-components";
import text from "../../font";
import { PieChartOption } from "../EpisodeReportChartOptions/PieChart/PieChart";
import { IPieChart } from "../types";
import Legend from "./Legend";

interface IPieChartProps {
  chartData: IPieChart[];
  score: number;
  title: string;
}

const PieChart = ({ chartData, score, title }: IPieChartProps): JSX.Element => {
  const pieChartData = new PieChartOption(chartData);
  const option = pieChartData.getChartOption();
  const legendData = pieChartData.getLegend();
  return (
    <PieChartWrapper>
      <GraphWrapper>
        <EChartsReact
          option={option}
          style={{ width: 200, height: 200, zIndex: 0 }}
        />
      </GraphWrapper>
      {legendData.map((legend, index) => (
        <Legend legendData={legend} key={index + 1} />
      ))}
      <DescriptionWrapper>
        <DescriptionTitle>
          <p>조직원</p>
          <p>{title} 점수</p>
        </DescriptionTitle>
        <Score>{score.toFixed(1)}점</Score>
      </DescriptionWrapper>
    </PieChartWrapper>
  );
};

export default PieChart;

const PieChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 13px;
  position: relative;
`;

const GraphWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 70px;
  left: 136px;
  gap: 4px;

  @media print {
    top: 69px;
    left: 160px;
  }
`;

const DescriptionTitle = styled.div`
  ${text({
    size: 14,
    weight: "bold",
    color: "gray04",
  })}
  letter-spacing: -0.5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
`;

const Score = styled.p`
  ${text({
    size: 20,
    weight: "bold",
    color: "gray05",
  })}
  letter-spacing: -0.5px;
`;
