import React from "react";
import styled from "styled-components";
import Description from "../../../Components/Description";
import Header from "../../../Components/Header";

const ReportFinalResult = () => {
  const descriptions = (
    <>
      조직원들 모두가 <StrongText>‘전세 대출 무이자 지원’</StrongText>,{" "}
      <StrongText>‘명절 지원금’</StrongText>,{" "}
      <StrongText>‘회사 헬스장 운영’</StrongText>,{" "}
      <StrongText>‘통신비 지원’</StrongText>,{" "}
      <StrongText>‘회사 조식 뷔페 운영’</StrongText> 을 중요하게 생각하며,
      만족도도 높습니다. 계속 더 잘할 수 있도록 지원해주세요.
    </>
  );

  const descriptions2 = (
    <>
      <StrongText>‘법인카드 지원’</StrongText>,{" "}
      <StrongText>‘회사내 어린이집 지원’</StrongText>은 조직원들이 중요하다고
      생각하지만, 많은 조직원들에게 사랑 받지 못하고 있어요. 이들 복지 제도에
      어떠한 문제가 있는지 살펴볼 수 있도록 조사를 수행해보세요
    </>
  );

  return (
    <ReportFinalResultWrapper>
      <Header
        title={"보고서 요약"}
        subTitle={
          "이번 진단으로 통해서 얻은 주요 결과입니다. 아래 내용을 살펴보고, 개선할 수 있는 영역을 확인해보세요."
        }
      />
      <div>
        <SubTitle>잘하고 있어요 👍</SubTitle>
        <Description descriptions={descriptions} />
      </div>
      <div>
        <SubTitle>개선이 필요해요 ✨</SubTitle>
        <Description descriptions={descriptions2} />
      </div>
      <TopThreeWrapper>
        <TopThreeWrapper2>
          <SubTitle>
            조직원들이 가장 사랑하는
            <br /> 우리 회사 복지 제도 Top 3
          </SubTitle>
          <SubContainer>
            <div>1️⃣ 전세 대출 무이자 지원</div>
            <div>2️⃣ 회사 조식 뷔페 지원</div>
            <div>3️⃣ 회사 헬스장 운영</div>
          </SubContainer>
        </TopThreeWrapper2>
        <TopThreeWrapper2>
          <SubTitle>
            조직원들이 가장 중요하다고 생각하는 <br />
            우리 회사 복지 제도 Top 3
          </SubTitle>
          <SubContainer>
            <div>1️⃣ 전세 대출 무이자 지원</div>
            <div>2️⃣ 법인카드 지원</div>
            <div>3️⃣ 회사내 어린이집 지원</div>
          </SubContainer>
        </TopThreeWrapper2>
      </TopThreeWrapper>
    </ReportFinalResultWrapper>
  );
};

const ReportFinalResultWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.gray01};
  border-radius: 3px;
`;

const StrongText = styled.span`
  font-family: ${({ theme }) => theme.fonts.bold};
`;

const SubTitle = styled.h2`
  margin-bottom: 15px;
  font-size: 16px;
  font-family: ${({ theme }) => theme.fonts.medium};
  line-height: 140%;
`;

const TopThreeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TopThreeWrapper2 = styled.div`
  width: 48%;
`;

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 25px;
  background-color: ${({ theme }) => theme.colors.lightBeige};
`;

export default ReportFinalResult;
