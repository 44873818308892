import ReactECharts from "echarts-for-react";

import { BAR_CHART_WITH_NEGATIVE_VALUE_OPTIONS } from "./BarChartWithNegativeValue.utils";

const BarChartWithNegativeValue = () => {
  return (
    <ReactECharts
      option={BAR_CHART_WITH_NEGATIVE_VALUE_OPTIONS}
      style={{ width: "1112px", height: "490px" }}
    />
  );
};

export default BarChartWithNegativeValue;
