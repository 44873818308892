import styled, { CSSProperties } from "styled-components";
import TableStyle from "./TableStyle";

interface IKeyWordTableProps {}

const KeyWordTable = ({}: IKeyWordTableProps) => {
  const headerList = [
    {
      width: 92,
      values: ["평가 요소"],
    },
    {
      width: 103,
      values: ["전체 리뷰 중", "언급 비율"],
    },
    {
      width: 418,
      values: ["함께 언급 된 키워드"],
      style: TableStyle.alignStart,
    },
    {
      width: 99,
      values: ["중요도", "(언급 빈도)"],
    },
    {
      width: 163,
      values: ["평가 점수", "(키워드 언급 평점의 평균)"],
    },
    {
      width: 140,
      values: ["영향도", "(평점과의 상관 관계)"],
    },
    {
      width: 121,
      values: ["감정 수치", "(감정 수치 정도)"],
    },
  ];

  return (
    <>
      <TableStyle.TableWrapper>
        <TableStyle.Header>
          {headerList.map((header) => (
            <th
              style={{
                width: header.width,
                ...(header.style ?? {}),
              }}
            >
              {header.values[0]}
              {header.values[1] && (
                <>
                  <br />
                  {header.values[1]}
                </>
              )}
            </th>
          ))}
        </TableStyle.Header>
        <TableStyle.TableBody>
          <td
            rowSpan={4}
            style={{
              fontWeight: 700,
              ...TableStyle.alignStart,
            }}
          >
            예약
          </td>
          <td
            rowSpan={4}
            style={{
              fontWeight: 700,
            }}
          >
            14.3%
          </td>
          <td style={TableStyle.alignStart}>전화 [키워드]</td>
          <td>11.7%</td>
          <td>70.0</td>
          <TableStyle.NumberCell>+0.13</TableStyle.NumberCell>
          <TableStyle.NumberCell>+30</TableStyle.NumberCell>
        </TableStyle.TableBody>
        <TableStyle.TableBody>
          <td style={TableStyle.alignStart}>온라인 [키워드]</td>
          <td>25.3%</td>
          <td>82.0</td>
          <TableStyle.NumberCell>+0.43</TableStyle.NumberCell>
          <TableStyle.NumberCell>+20</TableStyle.NumberCell>
        </TableStyle.TableBody>
        <TableStyle.TableBody>
          <td style={TableStyle.alignStart}>어려움 [술어]</td>
          <td>51.0%</td>
          <td>66.0</td>
          <TableStyle.NumberCell>-0.62</TableStyle.NumberCell>
          <TableStyle.NumberCell>-10</TableStyle.NumberCell>
        </TableStyle.TableBody>
        <TableStyle.TableBody>
          <td style={TableStyle.alignStart}>쉬움 [술어]</td>
          <td>0.8%</td>
          <td>95.0</td>
          <TableStyle.NumberCell>+0.58</TableStyle.NumberCell>
          <TableStyle.NumberCell>+50</TableStyle.NumberCell>
        </TableStyle.TableBody>
      </TableStyle.TableWrapper>
    </>
  );
};

export default KeyWordTable;
