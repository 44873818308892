import { LabelType } from "./types";

export const LABEL_TAG_NAME = new Map([
  [LabelType["유지 강화 영역"], "유지 강화 영역"],
  [LabelType["점진적 개선 영역"], "점진적 개선 영역"],
  [LabelType["중점 개선 영역"], "중점 개선 영역"],
  [LabelType["현상 유지 영역"], "현상 유지 영역"],
]);

export const LABEL_TAG_COLOR: Map<
  LabelType,
  "#70c33c" | "#f2ab28" | "#f37165" | "#818282"
> = new Map([
  [LabelType["유지 강화 영역"], "#70c33c"],
  [LabelType["점진적 개선 영역"], "#f2ab28"],
  [LabelType["중점 개선 영역"], "#f37165"],
  [LabelType["현상 유지 영역"], "#818282"],
]);

export const LABEL_TAG_BORDER_COLOR: Map<
  LabelType,
  "#f6e4df" | "#e1e1e1" | "#c6eb9b" | "#ffdca7"
> = new Map([
  [LabelType["유지 강화 영역"], "#c6eb9b"],
  [LabelType["점진적 개선 영역"], "#ffdca7"],
  [LabelType["중점 개선 영역"], "#f6e4df"],
  [LabelType["현상 유지 영역"], "#e1e1e1"],
]);

export const LABEL_TAG_BACKGROUNDCOLOR: Map<
  LabelType,
  "#fcf6f4" | "#f4f4f4" | "#f7fced" | "#fff9f0"
> = new Map([
  [LabelType["유지 강화 영역"], "#f7fced"],
  [LabelType["점진적 개선 영역"], "#fff9f0"],
  [LabelType["중점 개선 영역"], "#fcf6f4"],
  [LabelType["현상 유지 영역"], "#f4f4f4"],
]);
