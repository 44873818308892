import { Pagination2 } from "@earlysloth/pocketsurvey-ui-components";
import React from "react";
import styled from "styled-components";
import { TableDataType } from "../../types";
import useTooltipData from "../hooks/useTooltipData";

import TableHeaders from "./TableHeaders";
import TableRows from "./TableRows";

interface ITableProp {
  tableData: TableDataType[];
}

const Table = ({ tableData }: ITableProp): JSX.Element => {
  useTooltipData();
  return (
    <div>
      <TableHeaders
        headerValues={["평가요소", "평가 영향", "중요도", "만족도", "라벨"]}
      />
      <TableRows rowValues={tableData} />
      <PaginationWrapper>
        <Pagination2 count={1} onChange={() => {}} />
      </PaginationWrapper>
    </div>
  );
};

export default Table;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;
`;
