import React from "react";
import CompanyName from "./components/CompanyName/CompanyName";
import CashflowChart from "./components/CashflowChart/CashflowChart";
import RevenueChart from "./components/RevenueChart/RevenueChart";
import ShorttextTable from "./components/ShorttextTable/ShorttextTable";
import { useGetVCData } from "./hooks/query/useGetVCData";
import withAsyncError from "../HRDashboard/components/WithAsyncError/WithAsyncError";
import ComponentLoading from "../EpisodeReport/components/ComponentLoading";

const VCModel = () => {
  const data = useGetVCData();

  return (
    <div className="bg-[#fbfaf8] min-h-screen flex justify-center">
      <div className="bg-white w-[1200px] mt-[36px] mb-[200px] px-[32px] py-[48px] rounded-[3px] border border-solid border-[#F0F0F0]">
        <CompanyName />
        <RevenueChart />
        <CashflowChart />
        {!!data && <ShorttextTable dataList={data} />}
      </div>
    </div>
  );
};

export default withAsyncError(
  VCModel,
  <ComponentLoading width="100%" height="360px" backgroundColor="#ffffff" />
);
