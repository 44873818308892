import React from "react";
import styled from "styled-components";
import Description from "../../../Components/Description";
import Header from "../../../Components/Header";

const ReportDescription = () => {
  const descriptions = (
    <>
      본 조사는 2022년 2월 1회차에 수행한{" "}
      <StrongText>회사가 제공하는 복지 만족도 조사</StrongText>의 분석
      보고서입니다. 조직원 전체에게 회사에서 제공하는 복지를 나열하고, 선호하는
      순위를 질문하는 방식으로 현재 회사가 제공하는 다양한 복지 제도 중,
      조직원들이 중요하다고 느끼는 것이 무엇인지, 만족도가 높은 제도가 무엇인지
      진단합니다.
    </>
  );

  return (
    <ReportDescriptionWrapper>
      <Header title={"보고서 설명"} />
      <Description descriptions={descriptions} />
    </ReportDescriptionWrapper>
  );
};

export const ReportDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.gray01};
  border-radius: 3px;
`;

export const StrongText = styled.span`
  font-family: ${({ theme }) => theme.fonts.bold};
`;

export default ReportDescription;
