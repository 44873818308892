import React, { useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { useResizeDetector } from 'react-resize-detector';
import { Row } from 'react-table';
import { END_COLUMN_WIDTH, getCellWidth } from './ShorttextTableStyle';
import CellText from './CellText';
import { ShorttextAnswerTablePropsType } from '../hooks/useShorttextAnswerTable';
import text from '../../../font';

interface ITableRowsProps {
  tableProps: ShorttextAnswerTablePropsType;
}

const TableRows = ({ tableProps }: ITableRowsProps) => {
  const { getTableBodyProps, page, prepareRow } = tableProps;
  return (
    <RowsWrapper {...getTableBodyProps()}>
      {page.map((row: Row<any>, pageIndex) => {
        prepareRow(row);
        return (
          <RowWrapper {...row.getRowProps()} key={`row-wrapper-${pageIndex}`}>
            {row.cells.map((cell, index) => (
              <CellsWrapper
                {...cell.getCellProps()}
                index={pageIndex}
                key={`cell-wrapper-${index}`}
              >
                {cell.column.Header?.toLocaleString() === '답변내용' ? (
                  <CellText>{cell.render('Cell')}</CellText>
                ) : (
                  <>{cell.render('Cell')}</>
                )}
              </CellsWrapper>
            ))}
          </RowWrapper>
        );
      })}
    </RowsWrapper>
  );
};

const RowsWrapper = styled.div``;

const RowWrapper = styled.div`
  display: flex;
  width: 100% !important; // 100%로 정하지 않으면 react-table의 useBlockLayout에서 width를 지정px로 고정시킴
`;

const CellsWrapper = styled.div<{
  index: number;
}>`
  display: flex;
  align-items: center;
  padding: 0;
  min-height: 46px;
  box-sizing: border-box;
  padding-left: 16px;
  ${text({
    size: 14,
  })}
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray01};
  padding: 16px 15px;
  white-space: pre-line;

  :nth-of-type(1) {
    ${text({
      color: 'gray05',
      weight: 'bold',
    })}
  }

  ${(props) =>
    props.index % 2 === 1 &&
    css`
      background-color: #f9fafb;
    `}

  ${END_COLUMN_WIDTH}
`;

export default TableRows;
