export const DUMMY_DATA = [
  {
    cardValue: {
      subtitle: "확인해보세요⚠️",
      title: "업무 자율성에 대한 부정적 인식",
      description: (
        <>
          다수의 조직원이 <b>업무 자율성</b> 요소에 대해서 <b>부정적</b>으로
          인식하고 있습니다.
          <br />
          <b>업무 자율성</b>은 조직의 <b>몰입도</b>에 부정적인 영향을 주는 큰
          요인입니다.
          <br />
          <b>업무자율성</b>을 점진적으로 개선할 수 있는 방법을 확인해보세요.
        </>
      ),
    },
  },
  {
    cardValue: {
      subtitle: "확인해보세요⚠️",
      title: "동료의 지지에 대한 부정적 인식",
      description: (
        <>
          다수의 조직원이 <b>동료의 지지</b> 요소에 대해서 <b>부정적</b>으로
          인식하고 있습니다.
          <br />
          특히 <b>직책</b>에서 <b>팀원</b>과 <b>팀리더</b> 사이에 편차가 큽니다.
          <br />
          조직 몰입도에 잠재적으로 악영향이 될 수 있는 요소이니 확인해보세요.
        </>
      ),
    },
  },
  {
    cardValue: {
      subtitle: "개선하세요",
      title: "점진적으로 개선해가세요",
      description: (
        <>
          최초에 수행하는 펄스 보고서를 확인하고 섣부르게 조직의 문제를
          정의하는 것은 위험할 수 있습니다.
          <br />
          보고서에 기반하여 당분간 요인을 관찰하고, 문제를 점진적으로 개선할 수
          있는 방안을 탐색해보세요.
        </>
      ),
    },
  },
];

export const DUMMY_DATA2 = [
  {
    cardValue: {
      subtitle: "잘하고 있어요",
      title: "몰입도 점수가 상승했어요",
      description: (
        <>
          조직원 전체 몰입도 점수가 지난 회차 대비 <b>6% 상승</b>했어요.
          <br />
          회사가 몰입도 점수를 높이기 위해서 수행한 노력을 기록해서, 지속적으로
          활용해보세요.
        </>
      ),
    },
  },
  {
    cardValue: {
      subtitle: "잘하고 있어요",
      title: "업무 자율성 요소가 개선됐어요",
      description: (
        <>
          지난 회차 대비 <b>업무 자율성</b> 요소가 큰 폭으로 개선됐어요.
          <br />
          <b>업무 자율성</b> 요소는 충분히 개선된 것으로 보여요.
        </>
      ),
    },
  },
  {
    cardValue: {
      subtitle: "확인해보세요⚠️",
      title: "회사의 성장을 확인하세요",
      description: (
        <>
          <b>회사의 성장</b> 요소는 <b>몰입도</b>에 영향을 미치는 가장 중요한
          요소입니다
          <br />
          하지만 다른 요소 대비 만족도가 낮은 편이에요. <b>회사의 성장</b>을
          개선할 수 있는 방안을 탐색해보세요.
        </>
      ),
    },
  },
];

export const DUMMY_DATA3 = [
  {
    cardValue: {
      subtitle: "준비중",
      title: "제언을 준비중이에요.",
      description: <></>,
    },
  },
];

export const values = new Map([
  [1, DUMMY_DATA],
  [2, DUMMY_DATA2],
  [3, DUMMY_DATA3],
]);
