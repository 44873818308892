import Papa, { ParseResult } from "papaparse";

const parseJSON = (jsonStr: string) => {
  const query = `JSON.stringify(${jsonStr
    .replaceAll("'", "`")
    .replaceAll("\n\n", "####")
    .replaceAll("\n", "")})`;

  return JSON.parse(eval(query));
};

const convertCSVToJson = async (
  data: any,
  filterFunc?: (_: any, index: number) => boolean
) =>
  new Promise((resolve, reject) => {
    Papa.parse(data, {
      encoding: "euc-kr",
      complete: (results: ParseResult<string[]>) => {
        resolve(
          results.data
            .filter((_, index) => filterFunc?.(_, index) ?? true)
            .map((row) =>
              row.map((v) => {
                try {
                  return parseJSON(v);
                } catch (err) {
                  return v;
                }
              })
            )
        );
      },
      error: () => {
        reject();
      },
    });
  });

export default convertCSVToJson;
