/* eslint-disable react/display-name */
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import {
  useTable,
  useRowSelect,
  useFilters,
  useSortBy,
  useResizeColumns,
  useBlockLayout,
  usePagination,
  useGlobalFilter,
} from "react-table";
import useShorttextData from "./useShorttextData";

const useShorttextAnswerTable = () => {
  const params = useParams<{ ID: string; projectID: string }>();
  const rawData = useShorttextData(Number(params.ID));

  const columns = useMemo(() => rawData?.columns ?? [], [rawData?.columns]);
  const data = useMemo(() => rawData?.data ?? [], [rawData?.data]) as {
    [key: string]: string;
  }[];

  const tableProps = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 20,
        hiddenColumns: ["직군", "부문", "그룹", "팀"],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useBlockLayout,
    useResizeColumns,
    usePagination
  );

  return tableProps;
};

export default useShorttextAnswerTable;

export type ShorttextAnswerTablePropsType = ReturnType<
  typeof useShorttextAnswerTable
>;
