import { createGlobalStyle } from "styled-components";
import { reset } from "styled-reset";

import { theme } from "./theme";

const GlobalStyle = createGlobalStyle`
  ${reset}

  * {
    box-sizing: border-box;
  }

  body {
    font-family: 'Pretendard';
    letter-spacing: -0.5px;
    cursor: default;
  }
`;

export default GlobalStyle;
