import React, { useRef } from "react";

import {
  Input2,
  Icon,
  Dropdown2,
  CheckBox2,
  Pagination2,
  Button2,
} from "@earlysloth/pocketsurvey-ui-components";
import styled, { css } from "styled-components";

import ShorttextTable from "./ShorttextTable";
import useShorttextAnswerTable from "../hooks/useShorttextAnswerTable";
import { IDropdownContextProps } from "@earlysloth/pocketsurvey-ui-components/dist/types/Dropdown2/Dropdown2";
import text from "../../../font";

const TableContainer = () => {
  const tableProps = useShorttextAnswerTable();
  const dropdownRef = useRef<IDropdownContextProps | null>(null);

  return (
    <>
      <TableContainerHeader>
        <InputWrapper>
          <Input2
            height={32}
            width={283}
            value={tableProps.state.globalFilter ?? ""}
            onChange={(event) => tableProps.setGlobalFilter(event.target.value)}
            placeholder={"응답자 이름을 검색해주세요."}
          >
            <Icon icon="search" width={16} />
          </Input2>

          {(tableProps.state.globalFilter ||
            (tableProps.state.filters ?? []).length > 0) && (
            <FilterResetButton
              preset="tertiary-text"
              size="small"
              isDisabled={false}
              onClick={() => {
                tableProps.setGlobalFilter(undefined);
                tableProps.setAllFilters([]);
              }}
            >
              필터 초기화
            </FilterResetButton>
          )}
        </InputWrapper>

        {/* <Dropdown2
          value={'사용자 정의 필드 표시 설정'}
          extraCSS={customDropdownStyle}
          ref={dropdownRef}
        >
          <Dropdown2.Group>
            {tableProps.columns.map((column) => (
              <SelectionWrapper
                key={column.id}
                onClick={() => {
                  const hiddenColumns = tableProps.columns
                    .filter((item) => item.isVisible === false)
                    .map((item) => item.id);

                  const { id } = column;

                  if (hiddenColumns.includes(id)) {
                    tableProps.setHiddenColumns(
                      hiddenColumns.filter((item) => item !== id),
                    );
                  } else {
                    tableProps.setHiddenColumns(hiddenColumns.concat(id));
                  }
                }}
                isSelected={false}
              >
                <Selection>
                  <CheckBox2 isSelected={column.isVisible === true} />
                  {column.id}
                </Selection>
              </SelectionWrapper>
            ))}
          </Dropdown2.Group>
        </Dropdown2> */}
      </TableContainerHeader>
      <ShorttextTable tableProps={tableProps} />

      <FooterLayout>
        <PageSizeSetting>
          <Dropdown2
            value={tableProps.state.pageSize}
            extraCSS={PageCountDropdownCSS}
          >
            <Dropdown2.Group extraCSS={PageCountGroupCSS}>
              {[20, 50, 100, 200].map((size) => (
                <Dropdown2.Selection
                  onClick={() => {
                    tableProps.setPageSize(size);
                  }}
                  key={size}
                >
                  {size}
                </Dropdown2.Selection>
              ))}
            </Dropdown2.Group>
          </Dropdown2>
          <SizeViewer>개씩 보기</SizeViewer>
        </PageSizeSetting>

        <Pagination2
          count={tableProps.pageCount}
          onChange={(page) => {
            tableProps.gotoPage(page - 1);
          }}
        />
        <div
          style={{
            width: 170,
          }}
        />
      </FooterLayout>
    </>
  );
};

const FooterLayout = styled.div`
  display: flex;
  margin-top: 16px;
  justify-content: space-between;
`;

const PageSizeSetting = styled.div`
  display: flex;
  flex-direction: row;
  gap: 7px;
  align-items: center;
  width: fit-content;
`;

const TableContainerHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Selection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
`;

const customDropdownStyle = css`
  width: 205px;
  height: 32px;
  ${text({
    weight: "regular",
    size: 14,
    color: "gray05",
  })}
`;

const SelectionWrapper = styled.div<{ isSelected: boolean }>`
  font-family: "Spoqa Han Sans Neo Regular";
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 36px;
  &:hover {
    background: #f0f0f0;
  }
  ${(props) =>
    props.isSelected &&
    css`
      background: #f0f0f0;
    `}
`;

const SizeViewer = styled.p`
  ${text({
    weight: "light",
    size: 12,
    color: "gray05",
  })}
  letter-spacing: -0.5px;
  line-height: 18px;
`;

const InputWrapper = styled.div`
  display: flex;
`;

const FilterResetButton = styled(Button2)`
  color: #f2ab28 !important;
  font-size: 14px;
  padding: 9px 16px;
  &:hover {
    background-color: #ffffff;
  }
`;

const PageCountDropdownCSS = css`
  width: 75px;
  min-width: initial;
  height: 32px;
  ${text({
    weight: "regular",
    color: "gray05",
    size: 14,
  })}
  letter-spacing: -0.5px;
`;

const PageCountGroupCSS = css`
  width: 75px;
  min-width: initial;
  ${text({
    weight: "regular",
    color: "gray05",
    size: 14,
  })}
  letter-spacing: -0.5px;
`;

export default TableContainer;
