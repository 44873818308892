import { css } from "styled-components";

const fonts = {
  thin: "Spoqa Han Sans Neo Thin",
  regular: "Spoqa Han Sans Neo Regular",
  medium: "Spoqa Han Sans Neo Medium",
  bold: "Spoqa Han Sans Neo Bold",
  light: "Spoqa Han Sans Neo Light",
};

const colors = {
  background: "#E5E5E5",
  yellow: "#FAC62D",
  boldYellow: "#F2AB28",
  blue: "#59C4DB",
  boldBlue: "#57ADF2",
  lightBeige: "#FBFAF8",
  gray01: "#F0F0F0",
  gray02: "#DFDEDD",
  gray03: "#C9C8C7",
  gray04: "#818282",
  gray05: "#2B2E33",
  beige: "#E9E1D5",
  boldBeige: "#D2CBC0",
  lightYellow: "#FEF4CE",
  lightBlue: "#DEF3F8",
  red: "#F37165",
  green: "#70D473",
  white: "#ffffff",
};

const flexSets = {
  flexCenter: css`
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  flexColumn: css`
    display: flex;
    flex-direction: column;
  `,
  flexVerticalCenter: css`
    display: flex;
    align-items: center;
  `,
};

export const theme = {
  colors,
  fonts,
  flexSets,
};

export type themeTypes = {
  colors: typeof colors;
  fonts: typeof fonts;
  flexSets: typeof flexSets;
};
