import React from "react";
import styled, { css } from "styled-components";
import Tag from "../../Tag";
import { ReportType } from "../Column/Column";

interface IRowsProps {
  rows: string[][];
  type: ReportType;
}

const Rows = ({ rows, type }: IRowsProps) => {
  return (
    <>
      {rows.map((row) => (
        <TableRowWrapper>
          {row.map((item, index) => (
            <TableRow type={type}>
              {type === "vcReportTable" && index === 1 ? (
                <Logo src={item} />
              ) : (
                <>
                  {(type === "personalReport" && index === 0) ||
                  (type === "diagnosisReport" && index === 3) ||
                  (type === "twoColumn" && index === 1) ? (
                    <Tag name={item} />
                  ) : (
                    item
                  )}
                </>
              )}
            </TableRow>
          ))}
        </TableRowWrapper>
      ))}
    </>
  );
};

const Logo = styled.img`
  width: 40px;
`;

const TableRowWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const TableRow = styled.div<{ type: ReportType }>`
  padding-left: 24px;
  height: 48px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  box-sizing: border-box;
  font-size: 12px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray01};

  ${(props) =>
    props.type === "vcReportTable" &&
    css`
      :nth-of-type(1) {
        width: 100px;
      }
      :nth-of-type(2) {
        width: 120px;
      }
      :nth-of-type(3) {
        width: 300px;
      }
      :nth-of-type(4) {
        width: 180px;
      }
    `}

  ${(props) =>
    props.type === "diagnosisReport"
      ? css`
          :nth-of-type(1) {
            min-width: 196px;
            min-width: 196px;
          }

          :nth-of-type(2) {
            min-width: 146px;
            max-width: 146px;
          }
          :nth-of-type(3) {
            min-width: 146px;
            max-width: 146px;
          }
          :nth-of-type(4) {
            min-width: 120px;
            max-width: 120px;
          }
          :nth-of-type(5) {
            width: 100%;
          }
        `
      : css``}

  ${(props) =>
    props.type === "personalReport"
      ? css`
          :nth-of-type(1) {
            min-width: 107px;
            min-width: 107px;
          }

          :nth-of-type(2) {
            min-width: 86px;
            max-width: 86px;
            font-weight: 700;
            font-size: 14px;
            letter-spacing: -0.5px;
            color: #2b2e33;
          }
          :nth-of-type(3) {
            width: 100%;
            font-family: "Spoqa Han Sans Neo";
            font-weight: 400;
            font-size: 14px;
            letter-spacing: -0.5px;
            color: #2b2e33;
          }
        `
      : css``}

${(props) =>
    props.type === "oneLine"
      ? css`
          width: 100%;
        `
      : css``}

${(props) =>
    props.type === "twoColumn"
      ? css`
          :nth-of-type(1) {
            min-width: 500px;
            min-width: 500px;
          }
          :nth-of-type(2) {
            min-width: 100px;
            min-width: 100px;
          }
        `
      : css``}
`;

export default Rows;
