import axios from "axios";
import { useQuery } from "react-query";
import useTableTooltip from "./useTableTooltip";

const useTooltipData = () => {
  const { initRawData } = useTableTooltip();
  const { data } = useQuery(
    ["hr", "table", "tooltip"],
    () => getTableTooltip(),
    {
      suspense: true,
      staleTime: 1000 * 60 * 60 * 24, //한번만 데이터를 받아옴
      onSuccess: (data) => {
        initRawData(data.data);
      },
    }
  );

  return data?.data;
};

export default useTooltipData;

const getTableTooltip = () =>
  axios.get<TableTooltipData>(
    `https://s3.ap-northeast-2.amazonaws.com/pocketsurvey.demo.pulsesurvey-report-viewer/2a97516c/table-tooltip.json`
  );

export type QuestionType =
  | "업무와 일상의 밸런스"
  | "나의 건강"
  | "업무 자율성"
  | "업무 성취감"
  | "동료의 지지"
  | "리더의 지지"
  | "동료와의 친목 교류"
  | "회사의 성장"
  | "나의 성장";

export type TableTooltipData = {
  [key in "1" | "2" | "3"]: {
    [key in "전체" | "팀리더" | "팀원"]: {
      [key in QuestionType]: [number[], string[]];
    };
  };
};
