import { factorTagType } from "../../../types";
import { FACTOR_TAG } from "./constants";

export const cssUtil = {
  getText({ factorTagType }: { factorTagType: factorTagType }) {
    return FACTOR_TAG.fontColor.get(factorTagType);
  },
  getBorderColor({ factorTagType }: { factorTagType: factorTagType }) {
    return FACTOR_TAG.borderColor.get(factorTagType);
  },
  getBackgroundColor({ factorTagType }: { factorTagType: factorTagType }) {
    return FACTOR_TAG.backgroundColor.get(factorTagType);
  },
};
