import { useParams } from "react-router-dom";

import { TrendReportUrlParams } from "../types/types";

const useTrendReportUrlParams = () => {
  const { clientLabel, companyLabel } = useParams<TrendReportUrlParams>();

  return { clientLabel, companyLabel };
};

export default useTrendReportUrlParams;
