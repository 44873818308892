import React from "react";
import ReactECharts from "echarts-for-react";
import { Legend2 } from "./Legend";

const RunwayChart = () => {
  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <Legend2 />
      <ReactECharts
        option={RUNWAY_CHART_OPTIONS}
        style={{ width: "656px", height: "440px" }}
      />
    </div>
  );
};

export default RunwayChart;

const RUNWAY_CHART_OPTIONS = {
  xAxis: [
    {
      type: "category",
      data: ["1월", "2월", "3월", "4월", "5월", "6월"],
    },
  ],
  yAxis: [
    {
      type: "value",

      min: 0,
      max: 70000,
      interval: 10000,
    },
    {
      type: "value",

      min: 0,
      max: 7,
      interval: 1,
      axisLabel: {
        formatter: "{value}개월",
      },
    },
  ],
  series: [
    {
      name: "Cash on hand",
      type: "bar",
      data: [54300, 49300, 44000, 41000, 38100, 24500],
      itemStyle: {
        color: "#EC3719",
      },
    },
    {
      name: "Estimated Runway",
      type: "line",
      yAxisIndex: 1,
      lineStyle: {
        color: "#000",
        width: 4,
        type: "dashed",
      },
      smooth: true,
      data: [5.7, 4.5, 4, 3.7, 3.6, 3.5],
      symbolSize: 0,
    },
  ],
};
