import { Routes, Route } from "react-router-dom";
import styled, { createGlobalStyle } from "styled-components";

import KeywordPositiveNegativeReport from "./components/KeywordPositiveNegativeReport/KeywordPositiveNegativeReport";
import VocTrend from "./components/VocTrend/VocTrend";
import MonthTrend from "./components/MonthTrend/MonthTrend";
import RatingCompare from "./components/RatingCompare/RatingCompare";
import KeywordNetwork from "./components/KeywordNetwork/KeywordNetwork";
import KeywordReview from "./components/KeywordReview/KeywordReview";

const ShillaReport = () => (
  <Container>
    <Routes>
      <Route
        path="keyword-preference"
        element={<KeywordPositiveNegativeReport />}
      />
      <Route path="keyword-network" element={<KeywordNetwork />} />j
      <Route path="keyword-review" element={<KeywordReview />} />j
      <Route path="voc-trend" element={<VocTrend />} />
      <Route path="rate-comparison" element={<RatingCompare />} />
      <Route path="monthly-trend" element={<MonthTrend />} />
    </Routes>
  </Container>
);

export default ShillaReport;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px 0;
  height: 100vh;
`;
