import { IEpisodeReportData, IReportData } from "./types";

export class networkUtil {
  constructor(private readonly data: IEpisodeReportData | null) {}
  getReportData(): IReportData[] {
    if (!this.data) return [];

    return [this.data].reduce((acc: IReportData[], cur) => {
      const engagement = {
        title: "몰입도",
        graph: cur.engagement.graph,
        table: cur.engagement.table,
        IPA: cur.engagement.IPA,
        total: this.getPieChartTotalNum().engagement,
      };

      return acc.concat(engagement);
    }, []);
  }

  private getPieChartTotalNum() {
    if (!this.data) throw Error("data가 없습니다.");
    const engagement = this.sumDataCount("engagement");

    return {
      engagement,
    };
  }

  private sumDataCount(classification: "engagement") {
    if (!this.data) throw Error("data가 없습니다.");
    return this.data[classification].graph.data
      .map((v) => v.count)
      .reduce((acc, cur) => acc + cur, 0);
  }
}
