import ReactECharts from "echarts-for-react";

import { VERTICAL_BAR_LINE_CHART_OPTIONS } from "./VerticalBarLineChart.utils";

const VerticalBarLineChart = () => {
  return (
    <ReactECharts
      option={VERTICAL_BAR_LINE_CHART_OPTIONS}
      style={{ width: "1136px", height: "362px" }}
    />
  );
};

export default VerticalBarLineChart;
