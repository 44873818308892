import { Icon } from "@earlysloth/pocketsurvey-ui-components";
import React from "react";
import styled, { css } from "styled-components";

interface IColumnProps {
  column: string[];
  type: ReportType;
}
export type ReportType =
  | "diagnosisReport"
  | "personalReport"
  | "oneLine"
  | "twoColumn"
  | "vcReportTable";

const Column = ({ column, type }: IColumnProps) => {
  return (
    <TableHeaderWrapper>
      {column.map((value) => (
        <TableHead type={type}>
          {value}
          {value.length !== 0 ? (
            <IconWrapper>
              <Icon
                className="down-arrow"
                color="#818282"
                icon="sort"
                width={14}
                useCursor
              />
              <Icon
                icon="moreDetail"
                width={14}
                color="#818282"
                rotate={90}
                useCursor
              />
            </IconWrapper>
          ) : null}
        </TableHead>
      ))}
    </TableHeaderWrapper>
  );
};

const TableHeaderWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const TableHead = styled.div<{ type: ReportType }>`
  padding-left: 24px;
  height: 48px;
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f8f8f8;
  box-sizing: border-box;
  font-size: 12px;
  font-family: "Spoqa Han Sans Neo Medium";

  ${(props) =>
    props.type === "vcReportTable" &&
    css`
      :nth-of-type(1) {
        width: 100px;
      }
      :nth-of-type(2) {
        width: 120px;
      }
      :nth-of-type(3) {
        width: 300px;
      }
      :nth-of-type(4) {
        width: 180px;
      }
    `}

  ${(props) =>
    props.type === "diagnosisReport"
      ? css`
          :nth-of-type(1) {
            min-width: 196px;
            min-width: 196px;
          }

          :nth-of-type(2) {
            min-width: 146px;
            max-width: 146px;
          }
          :nth-of-type(3) {
            min-width: 146px;
            max-width: 146px;
          }
          :nth-of-type(4) {
            min-width: 107px;
            max-width: 107px;
          }
          :nth-of-type(5) {
            width: 100%;
          }
        `
      : css``}

  ${(props) =>
    props.type === "personalReport"
      ? css`
          :nth-of-type(1) {
            min-width: 107px;
            min-width: 107px;
          }

          :nth-of-type(2) {
            min-width: 86px;
            max-width: 86px;
          }
          :nth-of-type(3) {
            width: 100%;
          }
        `
      : css``}

      ${(props) =>
    props.type === "oneLine"
      ? css`
          width: 100%;
          padding-right: 8px;
        `
      : css``}
`;

const IconWrapper = styled.div``;

export default Column;
