import { cloneDeep } from "lodash";
import {
  ReorderedTrendReportDataItem,
  TrendReportMixedChart,
  TrendReportSuggestion,
  TrendReportSuggestionComponent,
  TrendReportTextTable,
} from "../types/types";

const interleaveSuggestions = (
  data: (TrendReportMixedChart[] | TrendReportTextTable[])[],
  suggestions: TrendReportSuggestion[]
): ReorderedTrendReportDataItem["reorderedData"] => {
  const arrayToInterleave: (null | TrendReportSuggestionComponent)[] = Array(
    data.length + 1
  ).fill(null);

  suggestions
    .sort((a, b) => {
      const aStart = a.position.split(",")[0];
      const bStart = b.position.split(",")[0];

      if (Number(aStart) > Number(bStart)) {
        return 1;
      }

      if (Number(aStart) < Number(bStart)) {
        return -1;
      }

      return 0;
    })
    .forEach(({ label, position, content }) => {
      const start = Number(position.split(",")[0]);

      arrayToInterleave.splice(start, 1, {
        type: "suggestion",
        label,
        data: content,
      });
    });

  return arrayToInterleave.reduce<
    ReorderedTrendReportDataItem["reorderedData"]
  >((_result, suggestion, index) => {
    const result = cloneDeep(_result);

    if (suggestion) {
      result.push([suggestion]);
    }

    if (data[index]) {
      result.push(data[index]);
    }

    return result;
  }, []);
};

export default interleaveSuggestions;
