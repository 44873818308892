import React from "react";
import styled from "styled-components";
import text from "../../font";

import { Title } from "../styles";
import { IPieChart } from "../types";
import PieChart from "./PieChart";

interface ICardGraphProps {
  title: string;
  chartData: IPieChart[];
  score: number;
  total: number;
}

const CardGraph = ({
  title,
  chartData,
  score,
  total,
}: ICardGraphProps): JSX.Element => {
  return (
    <CardGraphWrapper>
      <HeaderWrapper>
        <Title>{title}</Title>
        <TotalN>총 {total}명</TotalN>
      </HeaderWrapper>
      <PieChart chartData={chartData} score={score} title={title} />
    </CardGraphWrapper>
  );
};

const CardGraphWrapper = styled.div`
  width: 380px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  border: 1px solid ${({ theme }) => theme.colors.gray01};

  @media print {
    padding: 12px;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const TotalN = styled.p`
  ${text({
    size: 16,
    weight: "bold",
    color: "gray05",
  })}
  letter-spacing: -0.5px;
`;
export default CardGraph;
