import React from "react";
import ReactECharts from "echarts-for-react";
import { getChartOption, MOCK_DATA } from "./CashflowChart.utils";
import { useGetVCData } from "../../hooks/query/useGetVCData";

const CashflowChart = () => {
  const data = useGetVCData();
  const chartOption = getChartOption(data);

  return (
    <div>
      <div className="text-[18px] leading-[150%] font-[600] mb-[45px]">
        자금 흐름
      </div>
      <div className="mb-[48px]">
        <ReactECharts option={chartOption} />
      </div>
    </div>
  );
};

export default CashflowChart;
