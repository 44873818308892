import axios from "axios";
import { useQuery } from "react-query";
import Papa, { ParseResult } from "papaparse";
import { useState } from "react";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";

export interface IGetVCData {
  company: string;
  revenue: string;
  netmargin: string;
  cashonhand: string;
  good: string;
  bad: string;
  ask: string;
  date: string;
}

export const useGetVCData = () => {
  const param = useParams();

  const [result, setResult] = useState<IGetVCData[][] | null>(null);
  useQuery(["useGetVCData"], () => readGoogleSheet(), {
    onSuccess: (data) => {
      Papa.parse(data.data, {
        encoding: "euc-kr",
        complete: (results: ParseResult<string[]>) => {
          setResult(
            results.data
              .filter((_, index) => index !== 0)
              .map((row) => row.map((v) => parseJSON(v)))
          );
        },
      });
    },
  });

  const parseJSON = (jsonStr: string) => {
    const query = `JSON.stringify(${jsonStr
      .replaceAll("'", "`")
      .replaceAll("\n\n", "####")
      .replaceAll("\n", "")})`;

    return JSON.parse(eval(query));
  };

  return result
    ?.flat()
    .sort((a, b) => Number(a.date) - Number(b.date))
    .map((v) => ({
      ...v,
      company: v.company.trim(),
      date: dayjs(new Date(Number(v.date) * 1000)).format("YYYY년 MM월"),
      ask: v.ask.replaceAll("####", "\n"),
      bad: v.bad.replaceAll("####", "\n"),
      good: v.good.replaceAll("####", "\n"),
    }))
    .filter((v) => v.company === param.company);
};

const readGoogleSheet = () =>
  axios.get(
    "https://docs.google.com/spreadsheets/d/13lNnxjdT2UhHeXy5Pg98I697xCAIUZeL8tuZPP83aS0/export?format=csv"
  );

export type VCDataType = Exclude<ReturnType<typeof useGetVCData>, undefined>;
