import React from "react";
import styled from "styled-components";
import text from "../../../../font";

interface ICalloutLiProps {
  children: React.ReactNode;
}
const CalloutLi = ({ children }: ICalloutLiProps) => (
  <CalloutLiWrapper>
    • <CalloutContent>{children}</CalloutContent>
  </CalloutLiWrapper>
);

export default CalloutLi;

const CalloutLiWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
  padding-left: 3px;
`;

const CalloutContent = styled.li`
  ${text({
    weight: "medium",
    size: 16,
    color: "gray05",
  })}
  line-height: 24px;
  letter-spacing: -0.5px;

  list-style-type: none;
`;
