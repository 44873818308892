import { EChartsOption } from "echarts";
import { mainModule } from "process";
import ChartError, {
  ErrorCode,
} from "../../../EpisodeReport/EpisodeReportChartOptions/ChartError";
import { ILegendData, IQuickPulsedata } from "../../../EpisodeReport/types";
import { ILengendData } from "../../components/Graphs/types";

import ChartUtil from "../ChartUtil/ChartUtil";
import { ColorType, IDataWithColor, IMultiCardChartSeries } from "../types";

export class MultiCardEChartUtil extends ChartUtil {
  constructor(protected readonly graphData: ILegendData[]) {
    super();
  }

  protected getSeriesColors(): ColorType[] {
    return this.getColorList(this.graphData.length);
  }

  protected getLegendData() {
    return this.graphData.map((v, i) => ({
      legendData: {
        ...v,
        color: this.getSeriesColors()[i],
      },
    }));
  }

  protected getSeriesData() {
    return this.graphData.map((v) => v.N);
  }
}

class MultiCardEChart extends MultiCardEChartUtil {
  private readonly options: EChartsOption = {};
  constructor(protected readonly graphData: ILegendData[]) {
    super(graphData);
  }

  getChartOption(): EChartsOption {
    this.options.series = this.getSeries();

    return { ...this.options };
  }

  getChartData(): {
    legendData: ILengendData;
  }[] {
    return super.getLegendData()
  }

  private getSeries(): EChartsOption["series"] {
    try {
      const chartSeries = this.graphData;
      if (chartSeries === undefined || chartSeries.length === 0)
        throw new ChartError(ErrorCode.E1);

      return {
        type: "pie",
        radius: ["55%", "95%"],
        center: ["50%", "50%"],
        label: {
          show: false,
        },
        data: super.getSeriesData(),
        color: super.getSeriesColors(),
      };
    } catch (e) {
      throw new ChartError(ErrorCode.E1);
    }
  }
}

export default MultiCardEChart;
