import React from "react";
import styled from "styled-components";

interface IDescriptionProps {
  descriptions: React.ReactNode;
}

const Description = ({ descriptions }: IDescriptionProps) => {
  return (
    <DescriptionWarpper>
      <DescriptionItem>{descriptions}</DescriptionItem>
    </DescriptionWarpper>
  );
};

const DescriptionWarpper = styled.ul`
  padding: 25px;
  background-color: ${({ theme }) => theme.colors.lightBeige};
`;

const DescriptionItem = styled.li`
  font-size: 14px;
  line-height: 160%;
  letter-spacing: -0.5px;
  color: ${({ theme }) => theme.colors.gray05};
`;

export default Description;
