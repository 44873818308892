import React from "react";
import { shallowEqual } from "react-redux";
import styled, { css } from "styled-components";

import CardGraph from "./CardGraph/CardGraph";
import FactorPreference from "./FactorPreference/FactorPreference";
import Header from "./Header/Header";
import ExplainModal from "./IPA/ExplainModal/ExplainModal";
import IPAReport from "./IPA/IPAReport";

import { networkUtil } from "./util";
import { IEpisodeReportData } from "./types";
import useResultReportStore from "./Module/Zustand/useResultReportStore";
import PotentialWork from "../HRDashboard/PotentialWork/PotentialWork";

interface IResultReport {
  reportData: IEpisodeReportData | null;
}

const ResultReport = ({ reportData }: IResultReport) => {
  const totalData = new networkUtil(reportData).getReportData();
  const { isModalOpen, setIsModalOpen } = useResultReportStore(
    (state) => ({
      isModalOpen: state.isModalOpen,
      setIsModalOpen: state.setIsModalOpen,
    }),
    shallowEqual
  );

  if (!totalData) return <></>;

  return (
    <ResultWrapper>
      {totalData.map((el, index) => (
        <PrintSection key={el.title}>
          <ReportWrapper>
            <Header title={el.title} />
            <TopPostion>
              <CardGraph
                title={el.title}
                chartData={el.graph.data}
                score={el.graph.score}
                total={el.total}
              />
              <FactorPreference tableData={el.table} />
            </TopPostion>
            <IPAReport
              title={"IPA 분석"}
              ipaData={el.IPA}
              setIsModalOpen={setIsModalOpen}
            />
            <PotentialWork />
          </ReportWrapper>
        </PrintSection>
      ))}
      {isModalOpen ? <ExplainModal /> : null}
    </ResultWrapper>
  );
};

export default ResultReport;

const ResultWrapper = styled.div``;

const ReportWrapper = styled.div`
  margin-top: 24px;
`;

const TopPostion = styled.div`
  display: flex;
  gap: 32px;
`;

export const PrintSection = styled.div`
  @media print {
    @page {
      size: A4 portrait;
      break-after: always;
    }
    break-after: always;
  }
`;
