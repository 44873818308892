import { Icon } from "@earlysloth/pocketsurvey-ui-components";
import React from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import text from "../../../font";
import { QuestionIconWrapper } from "../../components/RectTooltip";
import useRectTooltip from "../../hooks/useRectTooltip";
import {
  TitleIcon,
  TitleIconSmall,
} from "../../ShorttextAnswer/ShorttextTable/styles/Title";
import { cssUtil } from "./util";

interface ITableHeaders {
  headerValues: ["평가요소", "평가 영향", "중요도", "만족도", "라벨"];
}

const showIcon = (payload: string) =>
  ["평가 영향", "중요도", "만족도"].includes(payload);

const TableHeaders = ({ headerValues }: ITableHeaders): JSX.Element => {
  const { setPosition, setSelectedLabel } = useRectTooltip((state) => ({
    setPosition: state.setPosition,
    setSelectedLabel: state.setSelectedLabel,
  }));

  const isIframe = useMediaQuery({
    query: "(max-width:1100px)",
  });

  return (
    <TableHeadersWrapper>
      {headerValues.map((el, index) => (
        <HeaderCell index={index} key={el} isIframe={isIframe}>
          <HeaderCellWrapper>
            <HeaderValue>{el}</HeaderValue>

            {showIcon(el) ? (
              <QuestionIconWrapper
                onClick={(event) => {
                  setPosition(event);
                  if (el === "평가 영향") setSelectedLabel("평가 영향이란?");
                  if (el === "중요도") setSelectedLabel("중요도란?");
                  if (el === "만족도") setSelectedLabel("만족도란?");
                }}
              >
                <QuestionWrapper>
                  <TitleIconSmall />
                </QuestionWrapper>
              </QuestionIconWrapper>
            ) : null}
          </HeaderCellWrapper>

          <IconWrapper>
            {index === 0 ? (
              <Icon
                className="down-arrow"
                color="#818282"
                icon="sort"
                width={13}
                useCursor
              />
            ) : null}
            <Icon
              icon="moreDetail"
              width={13}
              color="#818282"
              rotate={90}
              useCursor
            />
          </IconWrapper>
        </HeaderCell>
      ))}
    </TableHeadersWrapper>
  );
};

export default TableHeaders;

const HeaderCellWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
`;

const TableHeadersWrapper = styled.div`
  display: flex;
`;

const IconWrapper = styled.div``;

const HeaderCell = styled.div<{
  index: number;
  isIframe: boolean;
}>`
  display: flex;
  justify-content: space-between;
  width: ${cssUtil.getHeaderWidth};
  padding: 15px 17px;
  background-color: #f8f8f8;
  ${text({
    size: 12,
    color: "gray04",
    weight: "medium",
  })}
  letter-spacing: -0.5px;
`;
const HeaderValue = styled.p``;

const QuestionWrapper = styled.div`
  margin-top: 3px;
`;
