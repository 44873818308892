
import { produce } from "immer";
import create from "zustand";


export type Combine<T, K> = T & Omit<K, keyof T>;

const initState = {
  isModalOpen: false,
  isClickViewReport: false,
};

interface IInitState {
  isModalOpen: boolean;
  isClickViewReport: boolean;
}

interface IResultReportStoreFunction {
  setIsModalOpen: () => void;
  setIsClickViewReport: (payload: boolean) => void;
  scollToReport: (isLoading: boolean, scrollPosition: number) => void;
}

interface IUseResultReportStore
  extends Combine<IInitState, IResultReportStoreFunction> {}

const useResultReportStore = create<IUseResultReportStore>((set, get) => ({
  ...initState,
  setIsModalOpen: () => {
    set(
      produce((state: IInitState) => {
        state.isModalOpen = !state.isModalOpen;
      })
    );
  },
  setIsClickViewReport: (payload: boolean) => {
    set(
      produce((state: IInitState) => {
        state.isClickViewReport = payload;
      })
    );
  },
  scollToReport: (isLoading: boolean, scrollPosition: number) => {
    const { isClickViewReport, setIsClickViewReport } = get();
    if (isLoading === false && isClickViewReport) {
      window.scrollTo({ top: scrollPosition, behavior: "smooth" });
    }
    setIsClickViewReport(false);
  },
}));

export default useResultReportStore;
