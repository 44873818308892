import { css } from "styled-components";
import { LENGEND_COLOR } from "./constants";

export const cssUtil = {
  getLegendColor({ legendData }: { legendData: string }) {
    return LENGEND_COLOR.get(legendData);
  },
  getYAxisLine({ isIframe }: { isIframe: boolean }) {
    return isIframe
      ? css`
          height: 1px;
          width: 880px;
          position: absolute;
          background: #c9c8c7;
          bottom: 69px;
          left: 13px;
        `
      : css`
          height: 1px;
          width: 1056px;
          position: absolute;
          background: #c9c8c7;
          bottom: 69px;
          left: 44px;
        `;
  },
  getXAxisLine({ isIframe }: { isIframe: boolean }) {
    return isIframe
      ? css`
          height: 391px;
          width: 1px;
          position: absolute;
          background: #c9c8c7;
          top: 40px;
          left: 13px;
        `
      : css`
          height: 391px;
          width: 1px;
          position: absolute;
          background: ${({ theme }) => theme.colors.gray03};
          top: 40px;
          left: 44px;
        `;
  },
  getYAxisName({ isIframe }: { isIframe: boolean }) {
    return isIframe ? "-21px" : "5px";
  },
  getSatisfactionHeightLeft({ isIframe }: { isIframe: boolean }) {
    return isIframe ? "-16px" : "12px";
  },
  getLegendWrapperLeft({ isIframe }: { isIframe: boolean }) {
    return isIframe ? "263px" : "425px;";
  },
};
