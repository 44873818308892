export const option = {
  series: [
    {
      name: "스시드래곤",
      data: [71, 82, 76, 90, 88, 86, 86, 68, 90, 88, 80, 74],
      type: "line",
      itemStyle: {
        color: "#41b0a3",
      },
    },
    {
      name: "타로야",
      data: [69, 72, 86, 82, 80, 82, 84, 60, 88, 78, 72, 58],
      type: "line",
      itemStyle: {
        color: "#494fe2",
      },
    },
    {
      name: "나시미요",
      data: [51, 88, 80, 74, 74, 78, 76, 58, 78, 72, 82, 70],
      type: "line",
      itemStyle: {
        color: "#ffab2e",
      },
    },
  ],
  xAxis: {
    type: "category",
    data: Array(12)
      .fill(null)
      .map((_, i) => `2021년 ${i + 1}일`),
    axisLine: {
      lineStyle: {
        color: "#818282",
      },
    },
    axisTick: {
      show: false,
    },
  },
  yAxis: {
    type: "value",
    min: 0,
    max: 100,
    axisLabel: {
      formatter: "{value}%",
    },
    axisLine: {
      lineStyle: {
        color: "#C9C8C7",
      },
    },
  },
  grid: {
    top: "5%",
    width: 1090,
    left: "4%",
    height: 240,
  },
  legend: {
    itemWidth: 9,
    itemHeight: 9,
    bottom: 0,
    icon: "path:// M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0",
  },
  tooltip: {
    trigger: "axis",
  },
};

export const SUBTITLE_INFO = new Map<string, number>(
  option.series.map((item) => {
    return [
      item.name,
      Number(
        (
          item.data.reduce((acc, cur) => {
            acc += cur;
            return acc;
          }, 0) / item.data.length
        ).toFixed(1)
      ),
    ];
  })
);
