import React, { useState } from "react";
import create from "zustand";
import { combine } from "zustand/middleware";
import { RawEpisodeReportType, EpisodeReportType, UnArray } from "./useRawData";

interface IEpisodeReportData {
  segRawData: RawEpisodeReportType | null;
  totalRawData: EpisodeReportType | null;
  filterInfo: {
    parent: string;
    child: string;
  } | null;
}

const initState: IEpisodeReportData = {
  segRawData: null,
  totalRawData: null,
  filterInfo: null,
};

const useEpisodeReportData = create(
  combine(initState, (set, get) => ({
    setRawData: (payload: IEpisodeReportData["segRawData"]) => {
      set((state) => {
        state.segRawData = payload;
      });
    },
    setTotalRawData: (payload: IEpisodeReportData["totalRawData"]) => {
      set((state) => {
        state.totalRawData = payload;
      });
    },
    setFilterInfo: (payload: IEpisodeReportData["filterInfo"]) => {
      set((state) => {
        state.filterInfo = payload;
      });
    },
  }))
);

export const useReportData = () => {
  const {
    segRawData: _segRawData,
    totalRawData,
    filterInfo,
  } = useEpisodeReportData();
  const segRawData = _segRawData as UnArray<RawEpisodeReportType>;

  if (!filterInfo)
    return {
      data: totalRawData ?? null,
      filterInfo,
    };

  if (!segRawData) {
    return {
      data: totalRawData,
      filterInfo,
    };
  }
  try {
    return {
      //@ts-ignore
      data: segRawData[filterInfo.parent][filterInfo.child],
      filterInfo,
    };
  } catch (err) {
    return {
      data: null,
      filterInfo,
    };
  }
};

export default useEpisodeReportData;
