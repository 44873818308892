import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { TitleIcon } from "../ShorttextAnswer/ShorttextTable/styles/Title";

import { Title } from "../styles";
import { IIPAData } from "../types";
import QuadrantChart from "./Chart/QuadrantChart";
import ExplainModal from "./ExplainModal/ExplainModal";
import Table from "./Table/Table";
import TableTooltip from "./Table/Tooltip/TableTooltip";
import { IPADataUtil } from "./util";

interface IIPAReportProps {
  title: string;
  ipaData: IIPAData[];
  setIsModalOpen: () => void;
}

const IPAReport = ({
  title,
  ipaData,
  setIsModalOpen,
}: IIPAReportProps): JSX.Element => {
  const util = new IPADataUtil(ipaData);
  const chartData = util.getGraphData();
  const tableData = util.getTableData();

  const isIframe = useMediaQuery({
    query: "(max-width:1100px)",
  });

  return (
    <>
      <IPAReportWrapper isIframe={isIframe}>
        <HeaderWrapper>
          <Title>{title}</Title>
          {title === "IPA 분석" ? (
            <IconWrapper onClick={setIsModalOpen}>
              <TitleIcon />
            </IconWrapper>
          ) : null}
        </HeaderWrapper>
        <QuadrantChart chartData={chartData} />
        <Table tableData={tableData} />
      </IPAReportWrapper>
    </>
  );
};

export default IPAReport;

const IPAReportWrapper = styled.div<{
  isIframe: boolean;
}>`
  width: ${(props) => (props.isIframe ? "937px" : "1150px")};
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  border: 1px solid ${({ theme }) => theme.colors.gray01};
  margin-top: 32px;

  @media print {
    padding: 12px;
  }
`;
const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const HeaderWrapper = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
`;
