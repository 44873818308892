import React, { useEffect, useState } from "react";
import styled from "styled-components";
import text from "../../../../font";
import Ellipsis from "react-ellipsis-component";

const AnswerTD = ({
  className,
  children,
  ...props
}: React.ComponentPropsWithoutRef<"td">) => {
  const [isEllipsis, setIsEllipsis] = useState<boolean>(false);

  useEffect(() => {
    setIsEllipsis(true);
  }, [children]);

  if (isReactNodeCell(children)) {
    return (
      <TDElement
        className={`text-[14px] text-[#2B2E33] py-[13px] px-[16px] ${
          className ?? "leading-[150%]"
        }`}
        {...props}
      >
        <Ellipsis
          text={children}
          ellipsis={isEllipsis}
          maxLine={isEllipsis ? 4 : 9999}
          ellipsisNode={
            <MoreText
              onClick={() => {
                setIsEllipsis((v) => !v);
              }}
            >
              ...더보기
            </MoreText>
          }
        />
        {isEllipsis === false && (
          <MoreText
            onClick={() => {
              setIsEllipsis((v) => !v);
            }}
          >
            접기
          </MoreText>
        )}
      </TDElement>
    );
  }

  return <>error</>;
};

const TDElement = styled.td`
  ${text({
    weight: "regular",
  })}

  .__react-ellipsis {
    display: inline;
  }
  .__react-ellipsis-js {
    display: inline;
  }
`;

export default AnswerTD;

const MoreText = styled.span`
  color: #f2ab28;
  display: inline;
  cursor: pointer;
  margin-left: 3px;
`;

// export default CellText;

const isReactNodeCell = (v: React.ReactNode): v is string => {
  try {
    //@ts-ignore
    return typeof v === "string";
  } catch (err) {
    return false;
  }
};
