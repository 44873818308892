import React, { useState } from "react";
import styled from "styled-components";

import Table from "./Table/Table";

import { Icon } from "@earlysloth/pocketsurvey-ui-components";
import { IFactorPreference } from "../types";
import useRectTooltip from "../hooks/useRectTooltip";
import { QuestionIconWrapper } from "../components/RectTooltip";
import Title, {
  TitleIcon,
} from "../ShorttextAnswer/ShorttextTable/styles/Title";
import { useMediaQuery } from "react-responsive";
import useResponsive from '../../../pages/useReponsive'

interface IFactorPreferenceProps {
  tableData: IFactorPreference[];
}

const FactorPreference = ({
  tableData,
}: IFactorPreferenceProps): JSX.Element => {
  const { setPosition, setSelectedLabel } = useRectTooltip((state) => ({
    setPosition: state.setPosition,
    setSelectedLabel: state.setSelectedLabel,
  }));
  const isIframe = useResponsive()
  return (
    <FactorPreferenceWrapper isIframe={isIframe}>
      <ColumnWrapper>
        <Title>요소 선호도</Title>
        <QuestionIconWrapper
          onClick={(event) => {
            setPosition(event);
            setSelectedLabel("요소 선호도란?");
          }}
        >
          <TitleIconWrapper>
            <TitleIcon />
          </TitleIconWrapper>
        </QuestionIconWrapper>
      </ColumnWrapper>

      <Table tableData={tableData} />
    </FactorPreferenceWrapper>
  );
};

export default FactorPreference;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  z-index: 200;
`;

const FactorPreferenceWrapper = styled.div<{
  isIframe: boolean;
}>`
  width: ${(props) => (props.isIframe ? "none" : "724px")};
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  border: 1px solid ${({ theme }) => theme.colors.gray01};

  @media print {
    padding: 12px;
  }
`;

const TitleIconWrapper = styled.div`
  cursor: pointer;
`;
