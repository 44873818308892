import React from 'react';
import styled, { css } from 'styled-components';

interface IComponentLoadingProps {
  width: number | string;
  height: number | string;
  backgroundColor: string;
  isBorder?: boolean;
}

const ComponentLoading = ({
  width,
  height,
  backgroundColor,
  isBorder,
}: IComponentLoadingProps): JSX.Element => {
  return (
    <ComponentLoadingWrapper
      width={width}
      height={height}
      backgroundColor={backgroundColor}
      isBorder={isBorder}
    >
      <LoadingContainer>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </LoadingContainer>
    </ComponentLoadingWrapper>
  );
};

export default ComponentLoading;

const ComponentLoadingWrapper = styled.div<{
  width: number | string;
  height: number | string;
  backgroundColor: string;
  isBorder?: boolean;
}>`
  width: ${({ width }) => {
    if (typeof width === 'number') return `${width}px`;
    if (typeof width === 'string') return width;
  }};
  height: ${({ height }) => {
    if (typeof height === 'number') return `${height}px`;
    if (typeof height === 'string') return height;
  }};
  background-color: ${(props) => props.backgroundColor};
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) =>
    props.isBorder
      ? css`
          border: 1px solid ${({ theme }) => theme.colors.gray01};
        `
      : css``}
`;

const LoadingContainer = styled.div`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;

  div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #fac62d;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
`;
