import React from "react";
import styled from "styled-components";
import BarChart from "../../../Components/BarChart";
import Header from "../../../Components/Header";

const Participation = () => {
  return (
    <ParticipationWrapper>
      <Header title="얼리슬로스의 설문 참여도 82%" />
      <BarChart/>
    </ParticipationWrapper>
  );
};

const ParticipationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px 32px 64px 32px;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.gray01};
  border-radius: 3px;
`;

export default Participation;
