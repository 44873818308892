import { EChartsOption } from "echarts";
import ChartUtil from "../ChartUtil/ChartUtil";
import { ILineSmoth } from "../types";

import { IFormatterParams, ITooltipData } from "./types";

export class TrendIndexChartUtil extends ChartUtil {
  private firstAxixIndex = 0;
  private xAxisPosition = 0;

  constructor(protected readonly graphData: ILineSmoth) {
    super();
  }
  protected getSeriesData(graphData: ILineSmoth): EChartsOption["series"] {
    const colorList = super.getColorList(graphData.series.length);
    return graphData.series.map((item, index) => ({
      name: item.name,
      data: item.values,
      type: "line",
      itemStyle: {
        color: colorList[index],
      },
    }));
  }

  protected getTooltipPosition(
    point: [number, number],
    _params: any
  ): number[] {
    const params: IFormatterParams[] = _params;

    this.setTooltipPositionX(point[0], params[0].dataIndex);
    return [this.xAxisPosition - 63, 10];
  }

  protected getTooltipFormatter(_params: any): string {
    const params: IFormatterParams[] = _params;
    const dataIndex = params[0].dataIndex;

    return this.getTooltipStyle(
      this.getTooltipDate(dataIndex),
      this.getTooltipData(dataIndex)
    );
  }

  private setTooltipPositionX(XPoint: number, dataIndex: number) {
    this.xAxisPosition = XPoint;
    this.firstAxixIndex = dataIndex;
    return;
  }

  private getTooltipDate(dataIndex: number): string {
    return this.graphData.xAxis[dataIndex];
  }

  private getTooltipData(dataIndex: number): ITooltipData[] {
    const teamsNum = this.graphData.series.length;
    const colors = this.getColorList(teamsNum);
    return this.graphData.series.map((v, i) => ({
      color: colors[i],
      team: v.name,
      percent: `${v.values[dataIndex]}%`,
    }));
  }

  private getTooltipStyle(date: string, tooltipData: ITooltipData[]): string {
    return ` <div style="display: flex; flex-direction: column; align-items: center">
    <div
      style="
        display: flex;
        flex-direction: column;

        min-width: 102px;
        box-sizing: border-box;
        background-color: #2b2e33;
        border-radius: 3px;
        padding: 10px;
        opacity: 0.8;
      "
    >
      <p
        style="
          margin: 0;
          font-size: 10px;
          letter-spacing: -0.5px;
          color: #ffffff;
          font-family:"Spoqa Han Sans Neo Medium"
        "
      >
        ${date}
      </p>
      ${tooltipData
        .map(
          (v) => `
      <div
      style="
        display: flex;
        align-items: center;
        font-size: 12px;
        letter-spacing: -0.5px;
        height:16px
        font-family:"Spoqa Han Sans Neo Bold"
      "
    >
      <div style="display: flex; width: 14px; align-items: center; height:16px; ">
        <div
          style="
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: ${v.color};
            margin-top:2px;
          "
        ></div>
      </div>
      <div style="min-width: 45px; padding: 0 10px 0 2px; flex: 1; height:16px; ">
        <p style="margin: 0; color: #ffffff; height:16px;">${v.team}</p>
      </div>
      <div style="padding: 0 2px; height:16px;">
        <p style="margin: 0; color: ${v.color}; height:16px;">${v.percent}</p>
      </div>
    </div>
      `
        )
        .join("")}
   
      <div
        style="
          display: flex;
          align-items: center;
          font-size: 12px;
          letter-spacing: -0.5px;
        "
      >
   
      </div>
    </div>

    <div style="display: flex">
      <div
        style="
          width: 0px;
          height: 0px;
          border-bottom: 6px solid #ffffff;
          border-right: 6px solid #2b2e33;
          opacity: 0.8;
        "
      ></div>
      <div
        style="
          width: 0px;
          height: 0px;
          border-bottom: 6px solid #ffffff;
          border-left: 6px solid #2b2e33;
          opacity: 0.8;
        "
      ></div>
    </div>
  </div>`;
  }
}
