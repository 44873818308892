import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { IQuickPulsedata } from "../../../../EpisodeReport/types";
import MultiCardEChart from "../../../DashboardGraphs/MultiCardEChart/MultiCardEChart";

import ChartTitle from "./components/ChartTitle";
import Legend from "./components/Legend";
import PieGraph from "./components/PieGraph";

interface IMultiPieChartProps {
  chartData: IQuickPulsedata;
}

const MultiCardChart = ({ chartData }: IMultiPieChartProps): JSX.Element => {
  const multiCardChart = new MultiCardEChart(chartData.legendData);
  const option = multiCardChart.getChartOption();
  const data = multiCardChart.getChartData();
  const isIFrame = useMediaQuery({ query: "(max-width:1100px)" });
  return (
    <MultiCardChartWrapper isIFrame={isIFrame}>
      <ChartTitle title={chartData.title} />
      <PieGraph option={option} averageScore={chartData.averageScore} />
      <LengendWrapper>
        {data.map((v, i) => (
          <Legend key={i + 1} legendData={v.legendData} />
        ))}
      </LengendWrapper>
    </MultiCardChartWrapper>
  );
};

const MultiCardChartWrapper = styled.div<{
  isIFrame: boolean;
}>`
  width: ${(props) => (props.isIFrame ? "none" : "352px")};
  height: 447px;
  border: 1px solid ${({ theme }) => theme.colors.gray01};
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  box-sizing: border-box;
`;

const LengendWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export default MultiCardChart;
