
import {
  LABEL_TAG_COLOR,
  LABEL_TAG_BORDER_COLOR,
  LABEL_TAG_BACKGROUNDCOLOR,
} from '../../../constants';
import { LabelType } from '../../../types';

export const cssUtil = {
  getTagColor({
    label,
  }: {
    label: LabelType;
  }): '#70c33c' | '#f2ab28' | '#f37165' | '#818282' | undefined {
    return LABEL_TAG_COLOR.get(label);
  },

  getTagWidth({ label }: { label: LabelType }): '100px' | '90px' {
    return label === LabelType['점진적 개선 영역'] ? '100px' : '90px';
  },
  getBorderColor({
    label,
  }: {
    label: LabelType;
  }): '#f6e4df' | '#e1e1e1' | '#c6eb9b' | '#ffdca7' | undefined {
    return LABEL_TAG_BORDER_COLOR.get(label);
  },

  getBackgroundColor({
    label,
  }: {
    label: LabelType;
  }): '#fcf6f4' | '#f4f4f4' | '#f7fced' | '#fff9f0' | undefined {
    return LABEL_TAG_BACKGROUNDCOLOR.get(label);
  },
};
