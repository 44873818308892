import { useMediaQuery } from "react-responsive";

import React from "react";

const useReponsive = () => {
  const isIframe = useMediaQuery({
    query: "(max-width:1100px)",
  });
  return isIframe;
};

export default useReponsive;
