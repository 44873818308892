import React from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { IFactorPreference } from "../../../types";
import { cssUtil } from "./util";

interface IRowsProps {
  rowsData: IFactorPreference[];
}

const Rows = ({ rowsData }: IRowsProps): JSX.Element => {
  const isIframe = useMediaQuery({
    query: "(max-width:1100px)",
  });
  return (
    <RowsWrapper>
      {rowsData.map((cell, index) => (
        <Row index={index} key={index + 1}>
          <Cell index={index} rank={cell.rank} isIframe={isIframe}>
            {cell.rank}
          </Cell>
          <Cell index={index} label={cell.label} isIframe={isIframe}>
            {cell.label}
          </Cell>
          <Cell index={index} preference={cell.preference} isIframe={isIframe}>
            {cell.preference}%
          </Cell>
        </Row>
      ))}
    </RowsWrapper>
  );
};

export default Rows;

const RowsWrapper = styled.div``;

const Row = styled.div<{ index: number }>`
  display: flex;
  ${cssUtil.getRowBorderBottom}
`;

const Cell = styled.div<{
  rank?: number;
  label?: string;
  preference?: number;
  index: number;
  isIframe: boolean;
}>`
  display: flex;
  background-color: ${cssUtil.getRowBackground};
  ${cssUtil.getCellText}
  letter-spacing: -0.5px;
  height: 46px;
  width: ${cssUtil.getCellWidth};
  box-sizing: border-box;
  padding: 16px;
`;
