import axios from "axios";
import { useQuery } from "react-query";

const useShorttextData = (id: number) => {
  const { data } = useQuery(
    ["hr", "shorttext", id],
    () => getShorttextData(id),
    {
      suspense: true,
      staleTime: 1000 * 60 * 60 * 24,
    }
  );

  return data;
};

export default useShorttextData;

const getShorttextData = async (id: number) => {
  const res = await axios.get(
    `https://s3.ap-northeast-2.amazonaws.com/pocketsurvey.demo.pulsesurvey-report-viewer/2a97516c/shorttext.json`
  );

  return {
    columns: res.data.columns,
    data: res.data[id],
  };
};
