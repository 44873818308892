import React from "react";
import styled from "styled-components";
import text from "../../font";

import { SUMMARY } from "./constants";
import Summary from "./Summary";

const ReportSummary = () => {
  return (
    <ReportSummaryWrapper>
      <TitleWrapper>
        <Title>보고서 요약</Title>
        <SubTitle>
          최근 3회 펄스 트렌드를 통해서 확인한 조직의 진단 결과를 확인하세요.
        </SubTitle>
      </TitleWrapper>
      <SummaryWrapper>
        {SUMMARY.map((v, i) => (
          <Summary title={v.title} value={v.value} key={i + 1} />
        ))}
      </SummaryWrapper>
    </ReportSummaryWrapper>
  );
};

export default ReportSummary;

const ReportSummaryWrapper = styled.div`
  border: 1px solid #f0f0f0;
  padding: 30px;
  background-color: rgb(255, 255, 255);
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 31px;
`;

const SummaryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 53px;
  b {
    ${text({
      weight: "bold",
    })}
  }

  li {
    list-style-type: disc;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-left: 24px;
  }
`;

const Title = styled.p`
  ${text({
    size: 20,
    color: "gray05",
    weight: "bold",
  })}
  letter-spacing: -0.5px;
`;

const SubTitle = styled.p`
  ${text({
    size: 14,
    color: "gray04",
    weight: "regular",
  })}
  letter-spacing: -0.5px;
`;
