import React, { useCallback, useContext } from "react";
import { useMediaQuery } from "react-responsive";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import useReponsive from "../../../useReponsive";
import useEpisodeReportData from "../../hooks/useEpisodeReportData";
import { factorTagType, LabelType } from "../../types";
import useTableTooltip from "../hooks/useTableTooltip";

import FactorTag from "./FactorTag/FactorTag";
import LabelTag from "./LabelTag/LabelTag";
import { cssUtil, isFactorTagType, isLabelType } from "./util";

interface ITableRows {
  rowValues: [string, factorTagType, number, number, LabelType][];
}

const TableRows = ({ rowValues }: ITableRows): JSX.Element => {
  const params = useParams<{ ID: string; projectID: string }>();
  const { handleHoverTableCell } = useTableTooltip();

  const isIframe = useReponsive();

  const handleHover = useCallback(
    (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>,
      index: number,
      row: string,
      colIndex: number
    ) => {
      if (colIndex !== 3) return;

      const rect = event.currentTarget.getBoundingClientRect();

      const TOOLTIP_WIDTH = 463;

      // iframe 환경에서는 툴팁 모달이 왼쪽으로 뜨도록 width값을 뺀다.
      const x = rect.x - (isIframe ? TOOLTIP_WIDTH : 0);
      const y = rect.y + window.pageYOffset;

      const position = {
        x,
        y,
      };
      const filterInfo = useEpisodeReportData.getState().filterInfo;

      handleHoverTableCell(
        position,
        index,
        rowValues[index][0],
        filterInfo,
        params.ID
      );
    },
    [handleHoverTableCell, isIframe, params.ID, rowValues]
  );

  return (
    <TableRowsWrapper>
      {rowValues.map((col, index) => (
        <Rows index={index} key={index + 1}>
          {col.map((row: string | number | LabelType, colIndex) => {
            if (colIndex === 0) {
              return (
                <Row index={colIndex} key={colIndex + 1} isIframe={isIframe}>
                  {row}
                </Row>
              );
            }
            if (isLabelType(row, colIndex)) {
              return (
                <Row index={colIndex} key={colIndex + 1} isIframe={isIframe}>
                  <LabelTag label={row} />
                </Row>
              );
            }
            if (isFactorTagType(row, colIndex)) {
              return (
                <Row index={colIndex} key={colIndex + 1} isIframe={isIframe}>
                  <FactorTag factorTagType={row} />
                </Row>
              );
            }
            return (
              <Row index={colIndex} key={colIndex + 1} isIframe={isIframe}>
                <p
                  onMouseEnter={(event) => {
                    handleHover(event, index, `${row}`, colIndex);
                  }}
                >
                  {Number(row).toFixed(1)}%
                </p>
              </Row>
            );
          })}
        </Rows>
      ))}
    </TableRowsWrapper>
  );
};

export default TableRows;

const TableRowsWrapper = styled.div``;
const Rows = styled.div<{
  index: number;
}>`
  display: flex;
  align-items: center;
  ${cssUtil.getRowBackgroundColor}
  height: 46px;
`;
const Row = styled.div<{
  index: number;
  isIframe: boolean;
}>`
  display: flex;
  width: ${cssUtil.getHeaderWidth};
  ${cssUtil.getRowText}
  padding: 16px;
  letter-spacing: -0.5px;
`;
