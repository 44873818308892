import React from "react";
import ReactECharts from "echarts-for-react";
import styled from "styled-components";

const BarChart = () => {
  return (
    <Container>
      <ReactECharts
        option={BAR_CHART_OPTIONS}
        style={{ width: "830px", height: "440px" }}
      />
    </Container>
  );
};

export default BarChart;

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const BoldText = styled.div`
  position: relative;
  top: 120px;
  font-size: 100px;
  font-family: ${({ theme }) => theme.fonts.medium};
`;

const BAR_CHART_OPTIONS = {
  dataset: {
    source: [
      [89.3, "조직 전체"],
      [57.1, "운영팀"],
      [74.4, "개발팀"],
      [50.1, "디자인팀"],
      [89.7, "홍보팀"],
    ],
  },
  grid: { right: 0 },
  xAxis: { name: "amount", show: false },
  yAxis: {
    type: "category",
    axisTick: {
      length: 0,
    },
  },
  tooltip: {
    trigger: "axis",
  },
  series: [
    {
      type: "bar",
      encode: {
        // Map the "amount" column to X axis.
        x: "amount",
        // Map the "product" column to Y axis
        y: "product",
      },
      barWidth: 25,
      color: "#fac62d",
    },
  ],
};
