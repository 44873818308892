import styled, { createGlobalStyle } from "styled-components";

import BarChartWithNegativeValue from "../Charts/BarChartWithNegativeValue/BarChartWithNegativeValue";

const KeywordPositiveNegativeReport = () => {
  return (
    <Container>
      <Title>키워드별 감정 범위</Title>
      <TitleCaption>
        확인된 주요 키워드별 감정 범위를 표시합니다.
        <br />
        감정 범위는 -100부터 +100까지 표현됩니다.
      </TitleCaption>
      <ChartContainer>
        <BarChartWithNegativeValue />
      </ChartContainer>
    </Container>
  );
};

export default KeywordPositiveNegativeReport;

const Container = styled.div`
  width: 1200px;
  height: 690px;
  padding: 32px;
  border: 1px solid #f0f0f0;
  border-radius: 3px;
  background-color: #ffffff;
`;

const Title = styled.div`
  margin-bottom: 4px;
  font-size: 18px;
  font-weight: 600;
  line-height: 150%;
  color: #2b2e33;
  letter-spacing: -0.5px;
`;

const TitleCaption = styled.div`
  margin-bottom: 59px;
  font-size: 14px;
  line-height: 120%;
  color: #818282;
  letter-spacing: -0.5px;
`;

const ChartContainer = styled.div`
  border: 1px solid #f0f0f0;
  border-radius: 3px;
`;
