
import { LABEL_TAG_BACKGROUNDCOLOR, LABEL_TAG_COLOR } from '../../constants';
import { LabelType } from '../../types';

export const DESCRIPTION_AREA = [
  {
    area: '중점 개선 영역',
    describe: '중요도 높음 / 만족도 낮음',
    style: {
      backgroundColor: LABEL_TAG_BACKGROUNDCOLOR.get(
        LabelType['중점 개선 영역'],
      ),
      color: LABEL_TAG_COLOR.get(LabelType['중점 개선 영역']),
    },
  },
  {
    area: '유지 강화 영역',
    describe: '중요도 높음 / 만족도 높음',

    style: {
      backgroundColor: LABEL_TAG_BACKGROUNDCOLOR.get(
        LabelType['유지 강화 영역'],
      ),
      color: LABEL_TAG_COLOR.get(LabelType['유지 강화 영역']),
    },
  },
  {
    area: '점진적 개선 영역',
    describe: '중요도 낮음 / 만족도 낮음',
    style: {
      backgroundColor: LABEL_TAG_BACKGROUNDCOLOR.get(
        LabelType['점진적 개선 영역'],
      ),
      color: LABEL_TAG_COLOR.get(LabelType['점진적 개선 영역']),
    },
  },
  {
    area: '현상 유지 영역',
    describe: '중요도 낮음 / 만족도 높음',
    style: {
      backgroundColor: LABEL_TAG_BACKGROUNDCOLOR.get(
        LabelType['현상 유지 영역'],
      ),
      color: LABEL_TAG_COLOR.get(LabelType['현상 유지 영역']),
    },
  },
];
