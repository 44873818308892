import styled from "styled-components";
import TableStyle from "./TableStyle";

interface IReviewTableProps {}

const ReviewTable = ({}: IReviewTableProps) => {
  const headerList = [
    {
      width: 103,
      values: "리뷰 소스",
      style: TableStyle.alignStart,
    },
    {
      width: 912,
      values: "리뷰 원문",
      style: TableStyle.alignStart,
    },
    {
      width: 121,
      values: "감정 수치",
    },
  ];

  const source = [
    [
      "호텔 레스토랑 중 가장 넓고 좌석도 많으며 예약도 상대적으로 쉬운 편.",
      "+90",
      "https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/800px-Google_%22G%22_Logo.svg.png",
    ],
    [
      "예약만 되면 스시는 여기서 먹고 싶어요.",
      "+40",
      "https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/800px-Google_%22G%22_Logo.svg.png",
    ],
    [
      "매달 1일 차월의 예약을 받고, 오마카세 예약이 굉장히 치열합니다.",
      "+60",
      "https://mblogthumb-phinf.pstatic.net/MjAyMDA5MjRfMTU5/MDAxNjAwOTI4MDMyMjEw.DjPtW_SeEom1lwP1lYnH63Z2RHOzcpBM3QpeDzB3U-Ag.c67GygQljSm_oMFcLVzrcd-PhqwSWLM8op_iTSs6NJ4g.PNG.thdnjs4484/%EB%B8%94%EB%A1%9C%EA%B7%B82.png?type=w800",
    ],
    [
      "안내부터 왜 호텔에 있는 식당인가 느껴지는 워낙 기본은 하는 곳인데, 예약하기가 어렵고 기대치가 높았는지 저는 그냥 그냥 그랬어요.",
      "-50",
      "https://play-lh.googleusercontent.com/Zt1Ac3OOTTTaSrro-Ji6ttmVHx3qrzUklYeCC3RxTScjWGEyZjlJkqJ8t58z0zWENJb_",
    ],
    [
      "예약하기는 정말 힘든 곳이었지만 가서 먹고 힘들만하다고 느낀 곳!",
      "+80",
      "https://play-lh.googleusercontent.com/DYeQ02AyIghsirp4ea3oEnyxT3X0GgTZrXYR8G7AN6tRr-9mFcQIJdCUub5VHiWKHA",
    ],
  ];

  return (
    <>
      <TableStyle.TableWrapper>
        <TableStyle.Header>
          {headerList.map((header) => (
            <th style={header.style}>{header.values}</th>
          ))}
        </TableStyle.Header>

        {source.map((item) => (
          <TableStyle.TableBody>
            <td style={TableStyle.alignStart}>
              <TitleWrapper>
                <Icon src={item[2]} />
              </TitleWrapper>
            </td>
            <td style={TableStyle.alignStart}>{item[0]}</td>
            <TableStyle.NumberCell>{item[1]}</TableStyle.NumberCell>
          </TableStyle.TableBody>
        ))}
      </TableStyle.TableWrapper>
    </>
  );
};

export default ReviewTable;

const TitleWrapper = styled.div`
  gap: 8px;
  display: flex;
  align-items: center;
`;

const Icon = styled.img`
  width: 20px;
  height: auto;
  border-radius: 2px;
`;
