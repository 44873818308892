import { IQuickPulseFetchData, QuickPulseDataType } from "../types";

export const util = {
  getViewData(data?: QuickPulseDataType): IQuickPulseFetchData | undefined {
    if (!data) return;
    const result = [];
    const labelName = data.selections.map((v) => ({
      name: `${v[0]} (${v[1]})`,
    }));
    for (const [key, value] of Object.entries(data.graphs)) {
      result.push({
        title: key.split(" ")[0],
        averageScore: Number(value.avg.toFixed(1)) * 20,
        legendData: labelName.map((v, i) => ({
          ...v,
          percent: Math.ceil(
            (value.count[i] / value.count.reduce((acc, cur) => acc + cur, 0)) *
              100
          ),
          N: value.count[i],
        })),
      });
    }

    return {
      question: data.question,
      data: result.reverse(),
    };
  },
};
